import axios from 'axios';
import {CumulatedData, MaterialData, OrderCodeData} from './ApiData';
import {Translator} from '@lib/Translation';

export interface CumulatedDataEntry {
  orderCode: string;
  productionDate?: string;
  tag?: string;
}

export type CumulatedDataType = CumulatedDataEntry[];

declare const API_URL: string;

export class ApiClient {
  constructor(private readonly translator: Translator) {}

  async findMainProduct(materialNumber: string, current: boolean = true) {
    const {data} = await axios.get<MaterialData | undefined>(`${API_URL}/mainproduct/${encodeURIComponent(materialNumber)}`, {
      params: {
        current,
      },
      headers: {
        'Accept-Language': this.translator.activeLocale,
      },
    });

    return data;
  }

  async findOrderCode(orderCode: string, productionDate?: string, current: boolean = true, internal?: boolean) {
    const {data} = await axios.get<OrderCodeData | undefined>(`${API_URL}/ordercode/${encodeURIComponent(orderCode)}`, {
      params: {
        productionDate,
        current,
        internal,
      },
      headers: {
        'Accept-Language': this.translator.activeLocale,
      },
    });

    return data;
  }

  async findCumulated(searchData: CumulatedDataType, current: boolean = true, internal?: boolean) {
    const {data} = await axios.post<CumulatedData | undefined>(`${API_URL}/ordercode`, searchData, {
      params: {
        current,
        internal,
      },
      headers: {
        'Accept-Language': this.translator.activeLocale,
      },
    });

    return data;
  }
}

import * as React from 'react';
import {observer} from 'mobx-react';
import {DatePickerInputProps, InputMonth} from '.';

interface InputDayProps extends DatePickerInputProps {
  day: string;
  month: string;
  year: string;
  setDay: (value: string) => void;
  decrementDay: () => void;
  inputMonthRef: React.RefObject<InputMonth>;
  onPaste: (e: React.ClipboardEvent) => void;
}

@observer
export class InputDay extends React.Component<InputDayProps, {}> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  onChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {setDay, inputMonthRef, datePickerService} = this.props;
    setDay(e.currentTarget.value);
    const day = parseInt(datePickerService.day.replace(/[^0-9]/g, ''), 10);
    if (inputMonthRef.current && inputMonthRef.current.inputRef.current && day > 3) {
      inputMonthRef.current.inputRef.current.focus();
    }
  };

  onKeyDownDay = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {inputMonthRef, datePickerService} = this.props;
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        datePickerService.incrementDay();
        break;
      case 'ArrowDown':
        e.preventDefault();
        datePickerService.decrementDay();
        break;
      case 'ArrowRight':
        if (this.inputRef.current && inputMonthRef.current && inputMonthRef.current.inputRef.current) {
          if (this.inputRef.current.selectionStart === 0) {
            inputMonthRef.current.inputRef.current.focus();
          }
        }
        break;
      case 'ArrowLeft':
        break;
      default:
    }
  };

  onFocus = () => {
    const {onFocus} = this.props;
    if (this.inputRef.current) {
      this.inputRef.current.select();
    }
    onFocus();
  };

  render() {
    const {readOnly, onBlur, day, onPaste} = this.props;

    return (
      <input
        ref={this.inputRef}
        className='bfdp-input-short'
        value={day}
        onChange={this.onChangeDay}
        onFocus={this.onFocus}
        onBlur={onBlur}
        disabled={readOnly}
        onKeyDown={this.onKeyDownDay}
        onPaste={onPaste}
      />
    );
  }
}

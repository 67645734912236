import * as React from 'react';
import {observer} from 'mobx-react';
import styled, {createGlobalStyle} from 'styled-components';
import {Loader, GenericTooltipStyles, GenericScrollbarStyles, GenericFontsStyles, TextSize, TextWeight, Text} from '@components/UI';
import {Modal} from '@components/Modal';
import {GenericSearchProps} from './shared';
import {Search} from './Search';
import {Results} from './Results';
import {DefaultGenericStyles} from '@components/UI/Styles';
import {GenericPopoverStyles} from '@components/UI/Popover';

interface SftSearchProps extends GenericSearchProps {}

@observer
export class SftSearch extends React.Component<SftSearchProps, {}> {
  render() {
    const {
      translator,
      searchService: {addToCartFormRef, initializing, error, showTitle, inline, hookUrl, hookUrlParams, target, xmlBasket, loading, results},
      modalService,
    } = this.props;

    if (initializing) {
      return <Loader />;
    }

    return (
      <>
        <GlobalStyles />
        <OuterContainer>
          {!error && (
            <Container>
              {showTitle && (
                <Text size={TextSize.HUGE} weight={TextWeight.BOLD} paragraph>
                  {translator.translate('Spare parts search')}
                </Text>
              )}
              {!inline && <Search {...this.props} />}
              <Results {...this.props} loading={loading} results={results} />
              <AddToCartForm ref={addToCartFormRef} method='post' action={hookUrl} target={target}>
                <input type='hidden' name='xmlbasket' value={xmlBasket} required />
                <input type='hidden' name='country' value={hookUrlParams?.country} required />
                <input type='hidden' name='appcall' value={hookUrlParams?.appcall} required />
                <input type='hidden' name='nebp.hookTarget' value={hookUrlParams?.['nebp.hookTarget']} required />
                <input type='hidden' name='nebp.cartId' value={hookUrlParams?.['nebp.cartId']} required />
              </AddToCartForm>
            </Container>
          )}
          <Modal
            show={modalService.modalVisibility}
            onClose={modalService.hide}
            config={modalService.config}
            customBody={modalService.config.overrideBody ? modalService.content : undefined}
          >
            {!modalService.config.overrideBody ? modalService.content : null}
          </Modal>
        </OuterContainer>
      </>
    );
  }
}

const GlobalStyles = createGlobalStyle`
  ${GenericPopoverStyles};
`;

const OuterContainer = styled.div`
  ${GenericFontsStyles};
  ${DefaultGenericStyles};
  ${GenericTooltipStyles};
  ${GenericScrollbarStyles};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  background-color: #ffffff;
  padding: 12px;
  min-height: 100vh;
  box-sizing: border-box;
`;

const AddToCartForm = styled.form`
  position: absolute;
  left: -9999px;
  top: 0;
  z-index: -9999;
`;

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const DownloadIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 30 30'
    body={
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 16.175L19.243 12.933L20.657 14.347L15 20.004L9.343 14.347L10.757 12.933L14 16.175V5H16V16.175ZM7 19H9V23H21V19H23V23C23 24.1 22.1 25 21 25H9C7.9 25 7 24.037 7 23V19Z'
      />
    }
    {...props}
  />
);

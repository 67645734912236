import {LocalizedDictionary} from '../Translator';

export default {
  'Spare parts search': 'Find Spare Parts',
  'Search spare parts': 'Search spare parts',
  'Search products': 'Search products',
  Functions: 'Functions',
  'Recent search list': 'Recent search list',
  'Cumulative spare parts list': 'Cumulative spare parts list',
  Search: 'Search',
  'Spare part number': 'Spare part number',
  'Spare part list': 'Spare part list',
  'Order code / product root / serial number': 'Order code / product root / serial number',
  'Production date': 'Production date',
  DD: 'DD',
  MM: 'MM',
  YYYY: 'YYYY',
  'Which spare parts are available for my product? Filling in the order code (e.g. FTC260-AA2A1 or PMC51-BA21JB1CGBTDJB) or the product id (e.g. FTC260 or 83F50) shows the relevant spare parts.':
    'Which spare parts are available for my product? \nFilling in the order code (e.g. FTC260-AA2A1 or PMC51-BA21JB1CGBTDJB) or the product id (e.g. FTC260 or 83F50) shows the relevant spare parts.',
  'For which product(s) can my spare part be used? Filling in the spare part order code (e.g. 71002865 or XPD0024) shows the relevant devices this spare part can be used in.':
    'For which product(s) can my spare part be used? \nFilling in the spare part order code (e.g. 71002865 or XPD0024) shows the relevant devices this spare part can be used in.',
  'Products for': 'Products for',
  'Order code': 'Order code',
  'Spare parts for': 'Spare parts for',
  'Product status': 'Product status',
  Available: 'Available',
  'Phase out': 'Phase out',
  Discontinued: 'Discontinued',
  'Regarding quality- and security-aspects Endress+Hauser strongly recommends the exclusive use of genuine Endress+Hauser spare parts. The instrument may only be maintained and repaired by qualified personnel. The device documentation, applicable standards and legal requirements as well as any certificates have to be observed!':
    'Regarding quality- and security-aspects Endress+Hauser strongly recommends the exclusive use of genuine Endress+Hauser spare parts. \nThe instrument may only be maintained and repaired by qualified personnel. \nThe device documentation, applicable standards and legal requirements as well as any certificates have to be observed!',
  Description: 'Description',
  Recommended: 'Recommended',
  'Category code': 'Category code',
  'Drawing Position Number': 'Drawing position number',
  Group: 'Group',
  Valid: 'Valid',
  'Compatibility advices': 'Compatibility advices',
  'Mounting advices': 'Mounting advices',
  'Phase out date': 'Phase out date',
  'Spare sensor until': 'Spare sensor until',
  'Spare parts until': 'Spare parts until',
  'New alternative': 'New alternative',
  'Maintenance advice': 'Maintenance advice',
  'Order stop': 'Order stop',
  'Calibration until': 'Calibration until',
  'Repair until': 'Repair until',
  Open: 'Open',
  Characteristic: 'Characteristic',
  Value: 'Value',
  Type: 'Type',
  'Search date': 'Search date',
  Filter: 'Filter',
  'Set filter': 'Set filter',
  'Check all': 'Check all',
  'Recommended parts only': 'Recommended parts only',
  Rec: 'Rec',
  Tk: 'Tk',
  Pos: 'Pos',
  'Co.': 'Co.',
  'Mo.': 'Mo.',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  Clear: 'Clear',
  'Add selection to cart': 'Add selection to cart',
  'There are no items selected. Please use the checkboxes to make your selection':
    'There are no items selected. Please use the checkboxes to make your selection',
  Warning: 'Warning',
  Error: 'Error',
  'Please enter an order code': 'Please enter an order code',
  Ok: 'Ok',
  'not applicable': 'open',
  'No data available for the entered search parameters': 'No data available for the entered search parameters',
  Export: 'Export',
  'Download Excel': 'Download Excel',
  'No results found': 'No results found',
  'Could not load cumulated data': 'Could not load cumulated data',
  Tag: 'Tag',
  Seg: 'Seg',
  'Service segmentation': 'Service segmentation',
  'Ordercode too short': 'Ordercode too short',
  'Ordercode too long': 'Ordercode too long',
  'No data': 'No data available for the entered search parameters',
  accessories: 'accessories',
  'commissioning part': 'commissioning part',
  'wear part': 'wear part',
  'spare part': 'spare part',
  tool: 'tool',
  drawing: 'drawing',
  'no value': 'no value',
  'service segmentation 1': 'Parts that require basic knowledge.',
  'service segmentation 2': 'Parts that require basic knowledge. Successful participation in E+H basic training is a prerequisite.',
  'service segmentation 3': 'Parts that require specialised knowledge. Successful participation in E+H advanced training is a prerequisite.',
  'service segmentation 4': 'Parts for repair in the factory. Expert knowledge is mandatory.',
  none: 'None',
  'The entered order code is invalid': 'The entered order code is invalid',
  'Production numbers': 'Production numbers',
} as LocalizedDictionary;

import * as React from 'react';
import ReactTooltip, {TooltipProps} from 'react-tooltip';

export type TooltipPropsI = TooltipProps;

export class Tooltip extends React.Component<
  TooltipPropsI,
  {
    visible: boolean;
  }
> {
  constructor(props: TooltipPropsI) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    if (this.handler) {
      this.handler.addEventListener('mouseenter', this.handleMouseEnter);
      this.handler.addEventListener('mouseleave', this.handleMouseLeave);
    }
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler.removeEventListener('mouseenter', this.handleMouseEnter);
      this.handler.removeEventListener('mouseleave', this.handleMouseLeave);
    }
  }

  private handleMouseEnter = () => {
    this.setState({visible: true});
  };

  private handleMouseLeave = () => {
    this.setState({visible: false});
  };

  private get handler(): HTMLElement | undefined {
    const {id} = this.props;

    return (document.querySelector(`[data-for="${id}"]`) as HTMLElement | undefined) || undefined;
  }

  render() {
    const {place = 'top'} = this.props;

    if (!this.state.visible) {
      return null;
    }

    return <ReactTooltip {...this.props} place={place} />;
  }
}

export const GenericTooltipStyles = `
  .__react_component_tooltip {
    &.__react_component_tooltip {
      z-index: 9999;
      font-size: 12px;
      line-height: 16px;
      color: #333333;
      font-weight: 600;
      padding: 8px 14px;
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 0 5px 9px rgba(0, 0, 0, 0.2);
      opacity: 1 !important;
      max-width: 250px;
      text-transform: none;
      overflow: hidden;
      text-overflow: ellipsis;
      &:before {
        display: none;
      }
      &:after {
        background-color: inherit !important;
      }
  
      &.place-left {
        &:after {
          border-left-color: #ffffff !important;
        }
      }
  
      &.place-right {
        &:after {
          border-right-color: #ffffff !important;
        }
      }
  
      &.place-bottom {
        &:after {
          border-bottom-color: #ffffff !important;
        }
      }
  
      &.place-top {
        &:after {
          border-top-color: #ffffff !important;
        }
      }
    }
  }
`;

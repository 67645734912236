import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const ClearIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 10.5 10.5'
    body={
      <path
        d='M6.3,5.2l4-4c0.3-0.3,0.3-0.8,0-1.1s-0.8-0.3-1.1,0l-4,4l-4-4C1-0.1,0.5-0.1,0.2,0.2s-0.3,0.8,0,1.1l4,4l-4,4
          c-0.3,0.3-0.3,0.8,0,1.1c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l4-4l4,4c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2
          c0.3-0.3,0.3-0.8,0-1.1L6.3,5.2z'
      />
    }
    {...props}
  />
);

import * as React from 'react';
import {observer} from 'mobx-react';
import {Translator} from '@lib/Translation';

interface PickerFooterProps {
  onClose: () => void;
  onSave: () => void;
  onClear?: () => void;
  translator: Translator;
}

@observer
export class PickerFooter extends React.Component<PickerFooterProps, {}> {
  render() {
    const {onClose, onSave, onClear, translator} = this.props;

    return (
      <div className='bfdp-popup-footer'>
        <button type='button' className='bfdp-popup-footer-btn bfdp-popup-footer-cancel' onClick={onClose}>
          {translator.translate('Cancel')}
        </button>
        {onClear && (
          <button type='button' className='bfdp-popup-footer-btn bfdp-popup-footer-clear' onClick={onClear}>
            {translator.translate('Clear')}
          </button>
        )}
        <button type='button' className='bfdp-popup-footer-btn bfdp-popup-footer-ok' onClick={onSave}>
          {translator.translate('Confirm')}
        </button>
      </div>
    );
  }
}

import * as React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {ResultsData, ResultsType, SearchHistory} from '@lib/Search';
import {CumulatedData, MaterialData, OrderCodeData} from '@lib/Api';
import {Loader, Text, TextSize, TextWeight} from '@components/UI';
import {NotFoundIcon} from '@components/Icons';
import {GenericSearchProps} from '../shared';
import {CumulatedResults, OrderCodeResults} from './OrderCodeResults';
import {PartNumberResults} from './PartNumberResults';
import {SearchListResults} from './SearchListResults';

interface ResultsProps extends GenericSearchProps {
  loading?: boolean;
  results?: ResultsData;
  className?: string;
}

@observer
export class Results extends React.Component<ResultsProps, {}> {
  render() {
    const {translator, loading, results, className} = this.props;

    if (!results) {
      return null;
    }

    if (loading) {
      return <Loader />;
    }

    const {type, data} = results;

    if (!data) {
      return (
        <NoResultsContainer>
          <NotFoundIcon size={6} />
          <Text weight={TextWeight.BOLD} size={TextSize.BIGGER} paragraph>
            {translator.translate('No results found')}
          </Text>
          <Text color='#999999' size={TextSize.BIGGER} paragraph>
            {translator.translate('No data available for the entered search parameters')}
          </Text>
        </NoResultsContainer>
      );
    }

    return (
      <ResultsContainer className={className}>
        {(type === ResultsType.ORDER_CODE && <OrderCodeResults {...this.props} data={data as OrderCodeData} rows={(data as OrderCodeData).rows} />) ||
          (type === ResultsType.CUMULATED && (
            <CumulatedResults {...this.props} data={(data as CumulatedData).orderCodes} rows={(data as CumulatedData).rows} />
          )) ||
          (type === ResultsType.PART_NUMBER && <PartNumberResults {...this.props} data={data as MaterialData} />) ||
          (type === ResultsType.SEARCH_LIST && <SearchListResults {...this.props} data={data as SearchHistory} />) ||
          null}
        {[ResultsType.ORDER_CODE, ResultsType.PART_NUMBER].indexOf(type) > -1 && (
          <StyledText size={TextSize.BIGGER} weight={TextWeight.LIGHT} paragraph>
            {translator.translate(
              'Regarding quality- and security-aspects Endress+Hauser strongly recommends the exclusive use of genuine Endress+Hauser spare parts. The instrument may only be maintained and repaired by qualified personnel. The device documentation, applicable standards and legal requirements as well as any certificates have to be observed!',
            )}
          </StyledText>
        )}
      </ResultsContainer>
    );
  }
}

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
`;

const NoResultsContainer = styled(ResultsContainer)`
  && {
    align-items: center;
    justify-content: center;
    margin: auto 0;
    grid-gap: 0;
    > .icon {
      margin: 0 0 12px 0;
    }
  }
`;

const StyledText = styled(Text)`
  && {
    white-space: break-spaces;
  }
`;

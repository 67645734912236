import * as React from 'react';
import {observer} from 'mobx-react';
import {PickerBodyProps} from '..';

type WeekDaysHeaderProps = PickerBodyProps;

@observer
export class WeekDaysHeader extends React.Component<WeekDaysHeaderProps, {}> {
  render() {
    const {translator} = this.props;

    return [1, 2, 3, 4, 5, 6, 7].map((i, index) => {
      const weekDay = new Intl.DateTimeFormat(translator.activeLocale, {weekday: 'short'}).format(new Date(2019, 6, i));
      return (
        <div key={i} className={index > 4 ? 'bfdp-popup-body-days-weekend' : ''}>
          <button type='button' onClick={() => {}}>
            {weekDay}
          </button>
        </div>
      );
    });
  }
}

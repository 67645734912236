import * as React from 'react';
import {observer} from 'mobx-react';
import {PickerBodyProps} from '..';

type PickerMonthsProps = PickerBodyProps;

@observer
export class PickerMonths extends React.Component<PickerMonthsProps, {}> {
  render() {
    const {newDatePickerService, translator, togglePickerMode} = this.props;

    return (
      <div className='bfdp-popup-body-selection'>
        <div className='bfdp-popup-body-selection-inner'>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => {
            const month = new Intl.DateTimeFormat(translator.activeLocale, {month: 'short'}).format(new Date(2018, i, 1));
            const selected =
              `${newDatePickerService.newDate.getFullYear()},${newDatePickerService.newDate.getMonth()}` ===
              `${newDatePickerService.newDateYearAndMonth.getFullYear()},${i}`;
            const today =
              `${newDatePickerService.today.getFullYear()},${newDatePickerService.today.getMonth()}` ===
              `${newDatePickerService.newDateYearAndMonth.getFullYear()},${i}`;
            return (
              <div key={i} className={`${selected ? 'bfdp-popup-body-selection-active' : ''}${today ? ' bfdp-popup-body-selection-today' : ''}`}>
                <button
                  type='button'
                  onClick={() => {
                    newDatePickerService.changeCalendarMonth(i);
                    togglePickerMode();
                  }}
                >
                  {month}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {ClearInputButton, FormGroup, FormGroupOuter, INVALID_COLOR, Label, LengthInfo} from './shared';

interface InputFieldProps {
  label?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  value: string;
  onChange: (value?: string) => void;
  invalid?: boolean;
  maxLength?: number;
  onBlur?: () => void;
  onFocus?: () => void;
  className?: string;
  readOnly?: boolean;
  numberOnly?: boolean;
  allowClear?: boolean;
  inputProps?: React.InputHTMLAttributes<{}>;
  children?: React.ReactNode;
}

@observer
export class InputField extends React.Component<InputFieldProps, {}> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  focus() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  private onKeyPress = (e: React.KeyboardEvent) => {
    const {numberOnly} = this.props;

    if (numberOnly) {
      if ([',', '.'].indexOf(e.key) > -1) {
        e.preventDefault();
      }
    }
  };

  private onReset = () => {
    const {onChange} = this.props;

    onChange();
    this.focus();
  };

  render() {
    const {
      label,
      placeholder,
      type = 'text',
      name,
      value,
      onChange,
      invalid = false,
      maxLength,
      onBlur,
      onFocus,
      className,
      readOnly = false,
      allowClear = false,
      inputProps,
      children,
    } = this.props;

    return (
      <FormGroupOuter className={className}>
        <FormGroup>
          {label && <Label>{label}</Label>}
          {children}
          <FormInnerContainer>
            <StyledInput
              ref={this.inputRef}
              {...inputProps}
              type={type}
              placeholder={placeholder}
              name={name}
              value={value}
              $invalid={invalid}
              $allowClear={allowClear}
              onKeyPress={this.onKeyPress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={readOnly}
              tabIndex={0}
            />
            {!readOnly && allowClear && <ClearInputButton onClick={this.onReset} visible={!!value} />}
          </FormInnerContainer>
        </FormGroup>
        {maxLength && (
          <LengthInfo $invalid={invalid}>
            {value ? value.length : 0}/{maxLength}
          </LengthInfo>
        )}
      </FormGroupOuter>
    );
  }
}

interface IStyledInput {
  $invalid: boolean;
  $allowClear: boolean;
}

const StyledInput = styled.input.attrs<IStyledInput>({
  className: 'form-control',
})`
  width: 100% !important;
  padding: ${({$allowClear}: IStyledInput) => ($allowClear ? '0 40px 0 12px' : '0 12px')};
  height: 34px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #474747;
  border-radius: 0;
  border: 1px solid ${({$invalid}: IStyledInput) => (!$invalid ? '#c3ced5' : `${INVALID_COLOR} !important`)};
  outline: 1px solid ${({$invalid}: IStyledInput) => (!$invalid ? 'transparent' : `${INVALID_COLOR} !important`)};
  transition:
    border-color 0.2s ease-in,
    outline-color 0.2s ease-in;
  &::placeholder {
    color: #666666;
  }
  &:focus {
    border-color: #273237;
    outline-color: #273237;
  }
  &[disabled] {
    background-color: #f7f6f5;
    border-color: #dedede;
  }
`;

const FormInnerContainer = styled.div`
  position: relative;
  width: 100%;
`;

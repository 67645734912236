export enum Language {
  EN = 'en',
  DE = 'de',
}

export const detectBrowserLanguage = (): Language => {
  let locale = Language.EN;

  Object.values(Language).forEach((v) => {
    if (navigator.language.match(new RegExp(v, 'i'))) {
      locale = v;
    }
  });

  return locale;
};

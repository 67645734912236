import {Translator} from '@lib/Translation';
import {IS_DEV_MODE} from '@lib/Utils/Variables';

export type TrackingData = {[key: string]: string | string[] | undefined};

export const generateTrackingViewBaseData = (
  translator: Translator,
  title: string,
  internal: boolean,
  pageName: string,
  appCall?: string,
): TrackingData => {
  return {
    app_name: 'sft',
    site_language: translator.activeLocale,
    site_h1: title,
    ...(appCall ? {"application_entrance": appCall} : {}),
    'application_entry_page': document.referrer,
    "mode": internal ? 'internal' : 'external',
    useragent: navigator.userAgent,
    page_name: pageName,
  };
};

interface TrackingWindow extends Window {
  utag?: any;
  utag_data?: TrackingData | undefined;
}

export const trackView = (data: TrackingData) => {
  const w = window as TrackingWindow;

  if (w.utag && typeof w.utag.view === 'function') {
    //if (IS_DEV_MODE) {
    console.log('trackView', {...(w?.utag_data ?? {}), ...data});
    //}
    w.utag.view({...(w?.utag_data ?? {}), ...data});
  }

  return;
};

export const trackEvent = (data: TrackingData) => {
  const w = window as TrackingWindow;

  if (w.utag && typeof w.utag.link === 'function') {
    //if (IS_DEV_MODE) {
    console.log('trackEvent', {...(w?.utag_data ?? {}), ...data});
    //}
    w.utag.link({...(w?.utag_data ?? {}), ...data});
  }

  return;
};

import React from 'react';
import {observer} from 'mobx-react';
import {MaterialData, MaterialDataMode} from '@lib/Api';
import {TextWeight} from '@components/UI';
import {GenericSearchProps} from '../../shared';
import {SummaryContainer, SummaryInfo, SummaryProps, SummaryTitle, SummaryValue} from '../shared';
import {PartNumberProductsResults} from './PartNumberProductsResults';
import {PartNumberMaterialNumbersResults} from './PartNumberMaterialNumbersResults';

interface PartNumberResultsProps extends GenericSearchProps {
  data: MaterialData;
}

@observer
export class PartNumberResults extends React.Component<PartNumberResultsProps, {}> {
  render() {
    const {
      translator,
      data: {label, materialNumber, mode, products, materialNumbers},
    } = this.props;

    return (
      <>
        <SummaryContainer>
          <SummaryInfo>
            <SummaryTitle {...SummaryProps}>{translator.translate('Products for')}</SummaryTitle>
            <SummaryValue {...SummaryProps}>{label ?? '-'}</SummaryValue>
          </SummaryInfo>
          <SummaryInfo>
            <SummaryTitle {...SummaryProps}>{translator.translate('Order code')}</SummaryTitle>
            <SummaryValue {...SummaryProps} weight={TextWeight.NORMAL}>
              {materialNumber}
            </SummaryValue>
          </SummaryInfo>
        </SummaryContainer>
        {(mode === MaterialDataMode.MAIN_PRODUCT && <PartNumberProductsResults {...this.props} data={products} />) ||
          (mode === MaterialDataMode.PRODUCTION_NUMBERS && <PartNumberMaterialNumbersResults {...this.props} data={materialNumbers} />)}
      </>
    );
  }
}

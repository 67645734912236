import * as React from 'react';
import {observer} from 'mobx-react';
import {InputDay, InputHour, InputMinute, InputMonth, InputYear} from '.';
import {DatePickerService} from '../lib';
import {datePickerPlaceholderDate} from '@lib/Utils/Date';

export interface DatePickerInputProps {
  readOnly?: boolean;
  placeholder?: string;
  dateSeparator?: string;
  onFocus: () => void;
  onBlur: () => void;
  value?: Date;
  isFocus?: boolean;
  onChange?: (date: Date) => void;
  onPasteFnc?: (v: string) => Date | undefined;
  datePickerService: DatePickerService;
  showTime?: boolean;
}

@observer
export class DatePickerInput extends React.Component<DatePickerInputProps, {}> {
  private handlePaste = (e: React.ClipboardEvent) => {
    const {onChange, onPasteFnc} = this.props;

    e.stopPropagation();
    e.preventDefault();

    const value = e.clipboardData.getData('text');
    if (value) {
      const d = onPasteFnc && onPasteFnc(value);
      if (d && d.toString() !== 'Invalid Date') {
        onChange && onChange(d);
        return;
      }
      const date = new Date(value);
      if (date.toString() !== 'Invalid Date') {
        onChange && onChange(date);
      }
    }
  };

  render() {
    const {datePickerService, isFocus, value, placeholder, dateSeparator = '-', showTime} = this.props;
    const anyDate = !datePickerService.year && !datePickerService.month && !datePickerService.day && !isFocus;

    return (
      <>
        {anyDate && <input className='bfdp-input-placeholder' value={datePickerPlaceholderDate(value, placeholder)} disabled />}
        <div className='bfdp-input-container'>
          <InputDay
            {...this.props}
            ref={datePickerService.inputDayRef}
            inputMonthRef={datePickerService.inputMonthRef}
            decrementDay={datePickerService.decrementDay}
            year={datePickerService.year}
            month={datePickerService.month}
            day={datePickerService.day}
            setDay={datePickerService.setDay}
            onPaste={this.handlePaste}
          />
          <input className='bfdp-input-separator' value={!anyDate ? dateSeparator : ''} disabled />
          <InputMonth
            {...this.props}
            ref={datePickerService.inputMonthRef}
            inputYearRef={datePickerService.inputYearRef}
            inputDayRef={datePickerService.inputDayRef}
            month={datePickerService.month}
            setMonth={datePickerService.setMonth}
            onPaste={this.handlePaste}
          />
          <input className='bfdp-input-separator' value={!anyDate ? dateSeparator : ''} disabled />
          <InputYear
            ref={datePickerService.inputYearRef}
            {...this.props}
            year={datePickerService.year}
            setYear={datePickerService.setYear}
            inputHourRef={datePickerService.inputHourRef}
            inputMonthRef={datePickerService.inputMonthRef}
            onPaste={this.handlePaste}
          />
          {showTime && (
            <>
              <input className='bfdp-input-separator' value=' ' disabled />
              <InputHour
                {...this.props}
                ref={datePickerService.inputHourRef}
                inputDayRef={datePickerService.inputDayRef}
                inputMinuteRef={datePickerService.inputMinuteRef}
                hour={datePickerService.hour}
                setHour={datePickerService.setHour}
              />
              <input className='bfdp-input-separator' value={!anyDate ? ':' : ''} disabled />
              <InputMinute
                {...this.props}
                ref={datePickerService.inputMinuteRef}
                inputHourRef={datePickerService.inputHourRef}
                minute={datePickerService.minute}
                setMinute={datePickerService.setMinute}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

import * as React from 'react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Row_} from 'write-excel-file';
import {OrderCodeData, OrderCodeStatusType, orderCodeStatusTypeToLabel} from '@lib/Api';
import {MD_SIZE, PRIMARY_COLOR, SECONDARY_COLOR, slugify, trackEvent} from '@lib/Utils';
import {ExcelSheet} from '@lib/Excel';
import {Text, TextWeight} from '@components/UI';
import {SummaryContainer, SummaryInfo, SummaryProps, SummaryTitle, SummaryValue} from '../shared';
import {Row, StatusDot} from './shared';
import {OrderCodeDocumentsPreview} from './OrderCodeDocumentsPreview';
import {OrderCodeResultsRenderer, OrderCodeResultsRendererProps} from './OrderCodeResultsRenderer';
import {OrderCodeStatusModal, StatusCol, StatusColType, StatusRow, statusTitleTextProps, statusValueTextProps} from './OrderCodeStatusModal';

interface OrderCodeResultsProps extends OrderCodeResultsRendererProps {
  data: OrderCodeData;
}

@observer
export class OrderCodeResults extends OrderCodeResultsRenderer<OrderCodeResultsProps> {
  constructor(props: OrderCodeResultsProps) {
    super(props);

    makeObservable(this);
  }

  protected async onExport(): Promise<void> {
    await super.onExport();

    const {
      data: {orderCode,productRoot},
    } = this.props;

    trackEvent({
      event_name: 'download',
      event_linkname: 'Export to Excel',
      event_sft_for: productRoot,
      event_id: 'sft.en.search result.successful.download.Export to Excel',
    });
  }

  private onShowStatusSummary = () => {
    const {
      data: {orderCode, status, productRoot},
      translator,
      modalService,
    } = this.props;

    const event_linkname = status.status === OrderCodeStatusType.AVAILABLE ? 'Available' : 'Order stop';
    trackEvent({
      event_name: 'product_status',
      event_linkname,
      event_sft_for: productRoot,
      event_id: `sft.de.search result.successful.product_status.${event_linkname}`,
    });

    modalService.show(<OrderCodeStatusModal data={status} translator={translator} />, {
      title: (
        <>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('Product status')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <StatusDot $status={status.status} />
              <Text {...statusTitleTextProps}>{orderCodeStatusTypeToLabel(translator, status.status)}</Text>
            </StatusCol>
          </StatusRow>
          <Row />
        </>
      ),
    });
  };

  protected onExportPreRowsDecorator(sheets: ExcelSheet[]) {
    const {
      data: {orderCode, label, configuration},
    } = this.props;

    if (configuration?.length) {
      sheets.push(this.createConfigurationExcelSheet(orderCode || slugify(label), configuration));
    }
  }

  @computed
  protected get renderHeader(): React.ReactNode {
    const {
      translator,
      data: {
        label,
        orderCode,
        productRoot,
        configuration,
        status: {status},
        documents,
      },
    } = this.props;

    return (
      <SummaryContainerOuter>
        <SummaryContainer>
          <SummaryInfo>
            <SummaryTitle {...SummaryProps}>{translator.translate('Spare parts for')}</SummaryTitle>
            <SummaryValue {...SummaryProps}>{label}</SummaryValue>
          </SummaryInfo>
          <SummaryInfo>
            <SummaryTitle {...SummaryProps}>{translator.translate('Order code')}</SummaryTitle>
            {configuration?.length > 0 ? (
              <SummaryValueClickable {...SummaryProps} color={PRIMARY_COLOR} onClick={() => this.onShowConfiguration(orderCode, configuration)}>
                {orderCode}
              </SummaryValueClickable>
            ) : (
              <SummaryValue {...SummaryProps} weight={TextWeight.NORMAL}>
                {orderCode}
              </SummaryValue>
            )}
          </SummaryInfo>
          <SummaryInfo>
            <SummaryTitle {...SummaryProps}>{translator.translate('Product status')}</SummaryTitle>
            <SummaryValueClickable {...SummaryProps} color={PRIMARY_COLOR} onClick={this.onShowStatusSummary}>
              <StatusDot $status={status} />
              {orderCodeStatusTypeToLabel(translator, status)}
            </SummaryValueClickable>
          </SummaryInfo>
        </SummaryContainer>
        <OrderCodeDocumentsPreview orderCode={orderCode} productRoot={productRoot} documents={documents} />
      </SummaryContainerOuter>
    );
  }

  protected get resultsDatatableName() {
    return 'orderCode';
  }

  protected get exportFileName() {
    const {
      data: {orderCode, label},
    } = this.props;

    return orderCode ? `${orderCode}.xlsx` : `${slugify(label)}.xlsx`;
  }

  protected get exportTitleRows(): Row_<string>[] {
    const {
      data: {orderCode},
    } = this.props;

    return [
      [
        {
          value: orderCode,
          fontWeight: 'bold',
        },
      ],
    ];
  }
}

const SummaryContainerOuter = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 12px;
  @media all and (min-width: ${MD_SIZE}px) {
    flex-direction: row;
  }
`;

const SummaryValueClickable = styled(SummaryValue)`
  display: flex;
  align-items: center;
  grid-gap: 6px;
  cursor: pointer;
  transition: color 0.2s ease-in;
  &:hover {
    color: ${SECONDARY_COLOR};
    text-decoration: underline;
  }
`;

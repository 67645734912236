import * as React from 'react';
import {observer} from 'mobx-react';
import {PickerMode} from '..';
import {PickerDays, PickerMonths, PickerTime, PickerYears} from '.';
import {DatePickerService} from '../../lib';
import {Translator} from '@lib/Translation';

export interface PickerBodyProps {
  translator: Translator;
  newDatePickerService: DatePickerService;
  bindValue?: Date;
  pickerMode: PickerMode;
  togglePickerMode: () => void;
  save: () => void;
  gmtText?: string;
  showTime?: boolean;
}

@observer
export class PickerBody extends React.Component<PickerBodyProps, {}> {
  render() {
    const {pickerMode, gmtText, showTime} = this.props;

    return (
      <>
        <div style={{display: pickerMode === PickerMode.DAY ? 'block' : 'none'}}>
          <PickerDays {...this.props} />
          {gmtText && <div className='gmt-text'>{gmtText}</div>}
          {showTime && <PickerTime {...this.props} />}
        </div>
        <div style={{display: pickerMode === PickerMode.MONTH ? 'block' : 'none'}}>
          <PickerMonths {...this.props} />
        </div>
        <div style={{display: pickerMode === PickerMode.YEARS ? 'block' : 'none'}}>
          <PickerYears {...this.props} />
        </div>
      </>
    );
  }
}

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const ArrowIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 11.1 6.5'
    body={
      <path
        d='M5.5,6.5L0.2,1.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l4.2,4l4.2-4c0.3-0.3,0.8-0.3,1.1,0
	        c0.3,0.3,0.3,0.8,0,1.1L5.5,6.5z'
      />
    }
    {...props}
  />
);

import * as React from 'react';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {OrderCodeDocumentData} from '@lib/Api';
import {GRAY_COLOR, MD_SIZE, PRIMARY_COLOR, SECONDARY_COLOR, trackEvent} from '@lib/Utils';
import {ComponentWithTooltip, IconButton} from '@components/UI';
import {AngleLeftIcon, AngleRightIcon} from '@components/Icons';

interface OrderCodeDocumentsPreviewProps {
  orderCode: string;
  productRoot: string;
  documents: OrderCodeDocumentData[];
}

@observer
export class OrderCodeDocumentsPreview extends React.Component<OrderCodeDocumentsPreviewProps, {}> {
  @observable private activeDocumentIndex: number = 0;
  @observable private readonly documents: OrderCodeDocumentData[] = [];

  constructor(props: OrderCodeDocumentsPreviewProps) {
    super(props);

    this.documents = [...props.documents];

    makeObservable(this);
  }

  componentWillUnmount() {
    runInAction(() => {
      this.activeDocumentIndex = 0;
      this.documents.length = 0;
    });
  }

  private onNavigatePrevious = () => {
    const {orderCode, productRoot} = this.props;

    if (this.activeDocumentIndex > 0) {
      this.onNavigate(this.activeDocumentIndex - 1);
    } else {
      this.onNavigate(this.documents.length - 1);
    }

    trackEvent({
      event_name: 'drawing',
      event_linkname: 'previous',
      event_sft_for: productRoot,
      event_id: 'sft.en.search result.successful.drawing.previous',
    });
  };

  private onNavigateNext = () => {
    const {orderCode, productRoot} = this.props;

    if (this.activeDocumentIndex < this.documents.length - 1) {
      this.onNavigate(this.activeDocumentIndex + 1);
    } else {
      this.onNavigate(0);
    }

    trackEvent({
      event_name: 'drawing',
      event_linkname: 'next',
      event_sft_for: productRoot,
      event_id: 'sft.en.search result.successful.drawing.next',
    });
  };

  @action
  private onNavigatePager = (index: number) => {
    const {orderCode,productRoot} = this.props;

    this.onNavigate(index);

    trackEvent({
      event_name: 'drawing',
      event_linkname: `${index + 1}`,
      event_sft_for: productRoot,
      event_id: `sft.en.search result.successful.drawing.${index + 1}`,
    });
  };

  @action
  private onNavigate = (index: number) => {
    this.activeDocumentIndex = index;
  };

  @computed private get activeDocument() {
    if (!this.documents.length) {
      return;
    }

    return this.documents[this.activeDocumentIndex];
  }

  render() {
    const {orderCode,productRoot} = this.props;

    if (!this.documents.length) {
      return null;
    }

    return (
      <Container>
        {this.activeDocument && (
          <ComponentWithTooltip tooltip={this.activeDocument.name}>
            <ItemContainer>
              <Link
                href={this.activeDocument.master}
                target='_blank'
                onClick={() =>
                  trackEvent({
                    event_name: 'drawing',
                    event_linkname: 'picture',
                    event_sft_for: productRoot,
                    event_id: 'sft.en.search result.successful.drawing.picture',
                  })
                }
              >
                <Preview src={this.activeDocument.thumbnail} />
              </Link>
            </ItemContainer>
          </ComponentWithTooltip>
        )}
        {this.documents.length > 1 && (
          <NavigationContainer>
            <IconNavigationButton onClick={this.onNavigatePrevious}>
              <AngleLeftIcon size={0.75} color={PRIMARY_COLOR} />
            </IconNavigationButton>
            {this.documents.map((document, index) => (
              <IconNavigationPager key={index} $active={this.activeDocumentIndex === index} onClick={() => this.onNavigatePager(index)} />
            ))}
            <IconNavigationButton onClick={this.onNavigateNext}>
              <AngleRightIcon size={0.75} color={PRIMARY_COLOR} />
            </IconNavigationButton>
          </NavigationContainer>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 12px;
  flex-shrink: 0;
  margin: 0 auto;
  @media all and (min-width: ${MD_SIZE}px) {
    width: 40%;
  }
`;

const ItemContainer = styled.div`
  text-align: center;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 6px;
`;

const Link = styled.a``;

const Preview = styled.img`
  max-width: 100%;
  height: auto;
  border: 1px solid #c3ced5;
`;

const IconNavigationButton = styled(IconButton)`
  && {
    width: 20px;
    height: 20px;
  }
`;

interface IconNavigationPagerProps {
  $active?: boolean;
}

const IconNavigationPager = styled(IconButton)<IconNavigationPagerProps>`
  && {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${({$active}: IconNavigationPagerProps) => ($active ? SECONDARY_COLOR : GRAY_COLOR)};
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.2s ease-in;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:hover {
      background-color: ${({$active}: IconNavigationPagerProps) => ($active ? SECONDARY_COLOR : GRAY_COLOR)};
      &:after {
        opacity: 1;
      }
    }
  }
`;

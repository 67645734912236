import * as React from 'react';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {PickerBodyProps} from '..';
import {RenderDays, WeekDaysHeader} from '.';
import {RenderEmptyDays} from './RenderEmptyDays';

type PickerDaysProps = PickerBodyProps;

@observer
export class PickerDays extends React.Component<PickerDaysProps, {}> {
  constructor(props: PickerDaysProps) {
    super(props);

    makeObservable(this);
  }

  @computed get firstkDayOfMonth() {
    const {newDatePickerService} = this.props;
    const firstDay = new Date(newDatePickerService.newDateYearAndMonth.getFullYear(), newDatePickerService.newDateYearAndMonth.getMonth(), 1);
    const day = firstDay.getDay();
    if (day === 0) {
      return 6;
    }
    return day - 1;
  }

  @computed get monthLength() {
    const {newDatePickerService} = this.props;
    const lastDay = new Date(newDatePickerService.newDateYearAndMonth.getFullYear(), newDatePickerService.newDateYearAndMonth.getMonth() + 1, 0);
    return lastDay.getDate();
  }

  @computed get days() {
    const {newDatePickerService} = this.props;
    const days = [];
    for (let index = 1; index <= this.monthLength; index += 1) {
      days.push({
        label: newDatePickerService.pad(index, 2),
        date: new Date(newDatePickerService.newDateYearAndMonth.getFullYear(), newDatePickerService.newDateYearAndMonth.getMonth(), index),
      });
    }
    return days;
  }

  @computed get placeholders() {
    const {newDatePickerService} = this.props;
    const placeholders = [];
    for (let index = 0; index < this.firstkDayOfMonth; index += 1) {
      placeholders.push(newDatePickerService.pad(index, 2));
    }

    return placeholders;
  }

  render() {
    return (
      <div className='bfdp-popup-body-days'>
        <div className='bfdp-popup-body-days-inner'>
          <WeekDaysHeader {...this.props} />
          <RenderEmptyDays {...this.props} days={this.placeholders} />
          <RenderDays {...this.props} days={this.days} />
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import {createRoot, Root} from 'react-dom/client';
import {Context} from '@lib/App';
import {SearchConfig} from '@lib/Search';
import {App} from './App';

export class SftSearch {
  private context?: Context;
  private root?: Root;
  private readonly devMode: boolean;

  constructor(devMode?: boolean) {
    this.devMode = devMode || false;
  }

  init = async (domContainer: HTMLElement, config?: SearchConfig): Promise<true | undefined> => {
    try {
      await this.destroy();

      this.context = new Context();

      this.root = createRoot(domContainer);
      this.root.render(<App context={this.context} />);

      await this.context.init(config);
      return true;
    } catch (e) {
      return;
    }
  };

  destroy = async (): Promise<undefined> => {
    if (this.context && this.root) {
      this.root.unmount();
      this.root = undefined;
      this.context = undefined;
    }
    return;
  };
}

import React from 'react';
import {observer} from 'mobx-react';
import {ResultsType, SearchHistory, SearchHistoryData, SearchType} from '@lib/Search';
import {Datatable, DatatableColumnData} from '@components/Datatable';
import {Text, TextSize, TextWeight} from '@components/UI';
import {GenericSearchProps} from '../shared';
import {trackEvent} from '@lib/Utils';

interface SearchListResultsProps extends GenericSearchProps {
  data: SearchHistory;
}

@observer
export class SearchListResults extends React.Component<SearchListResultsProps, {}> {
  private onRowClick = async (row: SearchHistoryData) => {
    const {searchService, onRowClick} = this.props;

    trackEvent({
      event_name: 'search',
      event_linkname: row.search,
      event_subtype: 'search_history',
      event_order_code: row.search,
      event_id: `sft.en.search result.successful.search.${row.search}`,
    });
    await searchService.onSelectSearchHistoryEntry(row);
    onRowClick && onRowClick();
  };

  private get columns(): DatatableColumnData<SearchHistoryData>[] {
    const {translator, data} = this.props;

    const type: SearchType | undefined = data.length ? data[0].type : undefined;

    const ret: DatatableColumnData<SearchHistoryData>[] = [
      {
        width: type === ResultsType.ORDER_CODE ? 40 : 70,
        label: translator.translate('Order code'),
        key: 'search',
        sortable: true,
      },
    ];

    if (type === ResultsType.ORDER_CODE) {
      ret.push({
        width: 30,
        label: translator.translate('Production date'),
        key: 'productionDate',
        sortable: true,
        render: (row) => {
          const {productionDate} = row;

          return productionDate ? <Text paragraph>{productionDate.split('-').reverse().join('.')}</Text> : '-';
        },
      });
    }

    ret.push({
      width: 30,
      label: translator.translate('Search date'),
      key: 'searchAt',
      sortable: true,
      render: (row) => {
        const {searchAt} = row;

        const date = new Date(searchAt);

        const dayName = new Intl.DateTimeFormat(translator.activeLocale, {weekday: 'long'}).format(date);
        const monthName = new Intl.DateTimeFormat(translator.activeLocale, {month: 'long'}).format(date);

        return <Text paragraph>{`${dayName}, ${date.getDate() < 10 ? '0' : ''}${date.getDate()}. ${monthName} ${date.getFullYear()}`}</Text>;
      },
    });

    return ret;
  }

  render() {
    const {translator, data} = this.props;

    return (
      <>
        <Text weight={TextWeight.BOLD} size={TextSize.LARGE} paragraph>
          {translator.translate('Recent search list')}
        </Text>
        <Datatable
          name='search'
          translator={translator}
          columns={this.columns}
          rows={data}
          onRowClick={this.onRowClick}
          minWidth={560}
          resizable
          writeable
        />
      </>
    );
  }
}

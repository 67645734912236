import * as React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {v4 as uuidV4} from 'uuid';
import {CheckIcon} from '../Icons';
import {FormGroup, FormGroupOuter, INVALID_COLOR} from './shared';

interface CheckboxFieldProps {
  label?: string | React.ReactNode;
  name?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  invalid?: boolean;
  className?: string;
  readOnly?: boolean;
}

@observer
export class CheckboxField extends React.Component<CheckboxFieldProps, {}> {
  private readonly inputId: string = uuidV4();
  private isFocused: boolean = false;

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyEnter);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyEnter);
  }

  private handleKeyEnter = (e: KeyboardEvent) => {
    if (this.isFocused) {
      if (e.key === 'Enter') {
        const {checked, onChange} = this.props;
        onChange(!checked);
      }
    }
  };

  private onFocus = () => {
    this.isFocused = true;
  };

  private onBlur = () => {
    this.isFocused = false;
  };

  render() {
    const {label, name, checked, onChange, invalid = false, className, readOnly = false} = this.props;

    return (
      <FormGroupOuter className={className}>
        <FormGroup>
          <StyledInput
            type='checkbox'
            id={this.inputId}
            name={name}
            value={1}
            onChange={(v: React.ChangeEvent<HTMLInputElement>) => onChange(v.target.checked)}
            checked={checked}
            disabled={readOnly}
            tabIndex={0}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <StyledLabel htmlFor={this.inputId} $invalid={invalid} $readOnly={readOnly}>
            <StyledCheckIcon color='#ffffff' size={0.75} />
            {label}
          </StyledLabel>
        </FormGroup>
      </FormGroupOuter>
    );
  }
}

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  z-index: 99;
  left: 4px;
  top: 4px;
  opacity: 0;
  cursor: pointer;
`;

interface IStyledLabel {
  $invalid: boolean;
  $readOnly: boolean;
}

const StyledLabel = styled.label<IStyledLabel>`
  display: flex;
  grid-gap: 20px;
  align-items: flex-start;
  width: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #535353;
  ${({$readOnly}: IStyledLabel) =>
    $readOnly
      ? `
    pointer-events: none;
  `
      : `
    cursor: pointer;
  `}
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border: 1px solid ${({$invalid}: IStyledLabel) => (!$invalid ? '#BBBBBB' : `${INVALID_COLOR} !important;`)};
    border-radius: 3px;
    cursor: pointer;
    transition:
      background-color 0.2s ease-in,
      border-color 0.2s ease-in;
    flex-shrink: 0;
    ${({$readOnly}: IStyledLabel) =>
      $readOnly
        ? `
      border-color: #DADADA;
    `
        : `
      background-color: #ffffff;
    `}
  }
  &:hover {
    &:before {
      border-color: #506671;
    }
  }
`;

const StyledInput = styled.input`
  position: absolute;
  left: -9999px;
  top: 0;
  z-index: -9999;
  width: 0;
  height: 0;
  opacity: 0;
  &:checked {
    + ${StyledLabel} {
      &:before {
        background-color: #506671;
        border-color: #506671;
      }
      .icon {
        opacity: 1;
      }
    }
    &[disabled] {
      + ${StyledLabel} {
        &:before {
          border-color: #dadada;
          background-color: #dadada;
        }
      }
    }
  }
  &:focus,
  &:focus-visible {
    + ${StyledLabel} {
      &:before {
        border-color: #506671;
      }
    }
  }
`;

import * as React from 'react';
import {action, makeObservable, observable, reaction} from 'mobx';
import {InputDay, InputHour, InputMinute, InputMonth, InputYear} from '../Input';

export class DatePickerService {
  updateValue?: (date: Date) => void;
  @observable newDate: Date = new Date();
  @observable today: Date = new Date();
  @observable newDateYearAndMonth: Date = new Date();
  @observable year = '';
  @observable month = '';
  @observable day = '';
  @observable hour = '';
  @observable minute = '';
  locales?: string | string[];
  @observable isSet = true;
  inputYearRef: React.RefObject<InputYear> = React.createRef();
  inputMonthRef: React.RefObject<InputMonth> = React.createRef();
  inputDayRef: React.RefObject<InputDay> = React.createRef();
  inputHourRef: React.RefObject<InputHour> = React.createRef();
  inputMinuteRef: React.RefObject<InputMinute> = React.createRef();

  constructor(startDate?: Date, updateValue?: (date: Date) => void, isSet = true) {
    makeObservable(this);

    this.updateValue = updateValue;
    this.isSet = isSet;
    let value = startDate ? new Date(startDate) : undefined;
    if (!value && !(typeof value === 'function')) {
      value = undefined;
    }
    if (value) {
      this.newDate = new Date(value);
      this.newDateYearAndMonth = new Date(value);
      this.disposeDate(value);
      this.disposeTime(value);
    }

    reaction(
      () => [this.year, this.month, this.day, this.hour, this.minute].map((a) => a),
      () => {
        const year = this.num(this.year);
        const month = this.num(this.month) - 1;
        const day = this.num(this.day);
        const hour = this.num(this.hour);
        const minute = this.num(this.minute);
        if (year >= 1900 && month !== undefined && day && !Number.isNaN(hour) && !Number.isNaN(minute)) {
          this.newDate = new Date(year, month, day, hour, minute);
          if (this.updateValue) {
            this.updateValue(new Date(year, month, day, hour, minute));
          }
        } else if (year >= 1900 && month !== undefined && day && Number.isNaN(hour) && !Number.isNaN(minute)) {
          if (this.updateValue) {
            this.updateValue(new Date(year, month, day, hour));
          }
        } else if (year >= 1900 && month !== undefined && day && Number.isNaN(hour) && Number.isNaN(minute)) {
          if (this.updateValue) {
            this.updateValue(new Date(year, month, day));
          }
        }
      },
      {delay: 10},
    );

    return this;
  }

  @action cleanValue = () => {
    this.year = '';
    this.month = '';
    this.day = '';
    this.hour = '';
    this.minute = '';
  };

  @action
  public update = (value: Date) => {
    this.newDate = new Date(value);
    this.newDateYearAndMonth = new Date(value);
    this.disposeDate(value);
    this.disposeTime(value);
  };

  @action
  disposeDate = (date: Date) => {
    this.year = this.pad(date.getFullYear(), 4);
    this.month = this.pad(date.getMonth() + 1, 2);
    this.day = this.pad(date.getDate(), 2);
  };

  @action
  disposeTime = (date: Date) => {
    this.hour = this.pad(date.getHours(), 2);
    this.minute = this.pad(date.getMinutes(), 2);
  };

  @action
  prevMonth = () => {
    this.newDate = new Date(this.newDate.setMonth(this.newDate.getMonth() - 1));
  };

  @action
  nextMonth = () => {
    this.newDate = new Date(this.newDate.setMonth(this.newDate.getMonth() + 1));
  };

  @action
  prevYear = () => {
    this.newDate = new Date(this.newDate.setFullYear(this.newDate.getFullYear() - 1));
  };

  @action
  nextYear = () => {
    this.newDate = new Date(this.newDate.setFullYear(this.newDate.getFullYear() + 1));
  };

  @action
  changeMonth = (i: number) => {
    this.newDate = new Date(this.newDate.setMonth(i));
  };

  @action
  prevCalendarMonth = () => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setMonth(this.newDateYearAndMonth.getMonth() - 1));
  };

  @action
  nextCalendarMonth = () => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setMonth(this.newDateYearAndMonth.getMonth() + 1));
  };

  @action
  prevCalendarYear = () => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setFullYear(this.newDateYearAndMonth.getFullYear() - 1));
  };

  @action
  nextCalendarYear = () => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setFullYear(this.newDateYearAndMonth.getFullYear() + 1));
  };

  @action
  prevCalendarYearDecade = () => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setFullYear(this.newDateYearAndMonth.getFullYear() - 10));
  };

  @action
  nextCalendarYearDecade = () => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setFullYear(this.newDateYearAndMonth.getFullYear() + 10));
  };

  @action
  changeCalendarMonth = (i: number) => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setMonth(i));
  };

  @action
  changeCalendarYear = (i: number) => {
    this.newDateYearAndMonth = new Date(this.newDateYearAndMonth.setFullYear(i));
  };

  @action
  setYear = (value: string) => {
    const year = this.numReplace(value);
    if (year >= 0) {
      this.year = this.pad(year, 4);
      const day = this.numReplace(this.day);
      const month = this.numReplace(this.month);
      if (month === 2) {
        if (!(year % 4 === 0 && year % 100 !== 0) && day > 28 && year >= 1000) {
          this.day = '28';
        }
      }
    }
  };

  @action
  setMonth = (value: string) => {
    const month = this.numReplace(value);
    if (month >= 0 && month <= 12) {
      this.month = this.pad(month, 2);
      const day = this.numReplace(this.day);
      const year = this.numReplace(this.year);
      if (day > 28) {
        if ([4, 6, 9, 11].indexOf(month) !== -1 && day > 30) {
          this.day = '30';
        } else if (month === 2) {
          if ((Number.isNaN(year) || (year % 4 === 0 && year % 100 !== 0)) && day >= 29) {
            this.day = '29';
          } else {
            this.day = '28';
          }
        }
      }
    }
  };

  @action
  setDay = (value: string) => {
    const day = this.numReplace(value);
    if (day >= 0 && day <= 31) {
      const month = this.numReplace(this.month);
      const year = this.numReplace(this.year);
      if ([4, 6, 9, 11].indexOf(month) !== -1 && day <= 30) {
        this.day = this.pad(day, 2);
      } else if (month === 2) {
        if ((Number.isNaN(year) || (year % 4 === 0 && year % 100 !== 0)) && day <= 29) {
          this.day = this.pad(day, 2);
        } else if (day <= 28) {
          this.day = this.pad(day, 2);
        }
      } else {
        this.day = this.pad(day, 2);
      }
    }
  };

  @action
  setHour = (value: string) => {
    const hour = this.numReplace(value);
    if (hour >= 0 && hour <= 23) {
      this.hour = this.pad(hour, 2);
    }
  };

  @action
  setHourPopup = (value: string) => {
    this.disposeDate(this.newDate);
    this.setHour(value);
  };

  @action
  setMinute = (value: string) => {
    const minute = this.numReplace(value);
    if (minute >= 0 && minute <= 59) {
      this.minute = this.pad(minute, 2);
    }
  };

  @action
  setMinutePopup = (value: string) => {
    this.disposeDate(this.newDate);
    this.setMinute(value);
  };

  @action
  incrementYear = () => {
    const {year: y, setYear} = this;
    let year = this.numReplace(y);
    if (Number.isNaN(year)) {
      year = 0;
    }
    setYear((year + 1).toString());
  };

  @action
  decrementYear = () => {
    const {year: y, setYear} = this;
    let year = this.numReplace(y);
    if (Number.isNaN(year)) {
      year = 0;
    }
    if (year > 1900) {
      setYear((year - 1).toString());
    } else {
      setYear((1900).toString());
    }
  };

  @action
  incrementMonth = () => {
    const {month: m, setMonth, incrementYear} = this;
    let month = this.numReplace(m);
    if (Number.isNaN(month)) {
      month = 0;
    }
    if (month < 12) {
      setMonth((month + 1).toString());
    } else if (month === 12) {
      setMonth((1).toString());
      incrementYear();
    } else {
      setMonth((1).toString());
    }
  };

  @action
  decrementMonth = () => {
    const {month: m, setMonth, decrementYear} = this;
    let month = this.numReplace(m);
    if (Number.isNaN(month)) {
      month = 0;
    }
    if (month > 1) {
      setMonth((month - 1).toString());
    } else if (month === 1) {
      setMonth((12).toString());
      decrementYear();
    } else {
      setMonth((1).toString());
    }
  };

  @action
  incrementDay = () => {
    const {setDay, day: d, month: m, year: y, incrementMonth} = this;
    let day = this.numReplace(d);
    const month = this.numReplace(m);
    const year = this.numReplace(y);
    if (Number.isNaN(day)) {
      day = 0;
    }
    if (day === 31 && [1, 3, 5, 7, 8, 11].indexOf(month) > -1) {
      setDay((1).toString());
      incrementMonth();
    } else if (day === 30 && [4, 6, 9, 11].indexOf(month) > -1) {
      setDay((1).toString());
      incrementMonth();
    } else if (day === 29 && [2].indexOf(month) > -1 && year % 4 === 0 && year % 100 !== 0) {
      setDay((1).toString());
      incrementMonth();
    } else if (day === 28 && [2].indexOf(month) > -1 && !(year % 4 === 0 && year % 100 !== 0)) {
      setDay((1).toString());
      incrementMonth();
    } else {
      setDay((day + 1).toString());
    }
  };

  @action
  decrementDay = () => {
    const {setDay, day: d, month: m, year: y, decrementMonth} = this;
    let day = this.numReplace(d);
    if (Number.isNaN(day)) {
      day = 0;
    }
    if (day > 1) {
      setDay((day - 1).toString());
    } else if (day === 1) {
      decrementMonth();
      const month = this.numReplace(m);
      const year = this.numReplace(y);
      if ([4, 6, 9, 11].indexOf(month) > -1) {
        setDay((30).toString());
      } else if ([2].indexOf(month) > -1 && year % 4 === 0 && year % 100 !== 0) {
        setDay((29).toString());
      } else if ([2].indexOf(month) > -1) {
        setDay((28).toString());
      } else {
        setDay((31).toString());
      }
    } else {
      setDay((1).toString());
    }
  };

  @action
  incrementHour = () => {
    const {hour: h, setHour, incrementDay} = this;
    let hour = this.numReplace(h);
    if (Number.isNaN(hour)) {
      hour = 0;
    }
    if (hour < 23) {
      setHour((hour + 1).toString());
    } else if (hour === 23) {
      setHour((0).toString());
      incrementDay();
    } else {
      setHour((1).toString());
    }
  };

  @action
  incrementHourPopup = () => {
    this.disposeDate(this.newDate);
    this.incrementHour();
  };

  @action
  decrementHour = () => {
    const {hour: h, setHour, decrementDay} = this;
    let hour = this.numReplace(h);
    if (Number.isNaN(hour)) {
      hour = 0;
    }
    if (hour >= 1) {
      setHour((hour - 1).toString());
    } else if (hour === 0) {
      decrementDay();
      setHour((23).toString());
    } else {
      setHour((1).toString());
    }
  };

  @action
  decrementHourPopup = () => {
    this.disposeDate(this.newDate);
    this.decrementHour();
  };

  @action
  incrementMinute = () => {
    const {minute: m, setMinute, incrementHour} = this;
    let minute = this.numReplace(m);
    if (Number.isNaN(minute)) {
      minute = 0;
    }
    if (minute < 59) {
      setMinute((minute + 1).toString());
    } else if (minute === 59) {
      setMinute((0).toString());
      incrementHour();
    } else {
      setMinute((1).toString());
    }
  };

  @action
  incrementMinutePopup = () => {
    this.disposeDate(this.newDate);
    this.incrementMinute();
  };

  @action
  decrementMinute = () => {
    const {minute: m, setMinute, decrementHour} = this;
    let minute = this.numReplace(m);
    if (Number.isNaN(minute)) {
      minute = 0;
    }
    if (minute >= 1) {
      setMinute((minute - 1).toString());
    } else if (minute === 0) {
      decrementHour();
      setMinute((59).toString());
    } else {
      setMinute((1).toString());
    }
  };

  @action
  decrementMinutePopup = () => {
    this.disposeDate(this.newDate);
    this.decrementMinute();
  };

  pad = (num: number, size: number) => {
    let newNum = num.toString();
    while (newNum.length < size) {
      newNum = `0${newNum}`;
    }
    return newNum;
  };

  num = (value: string) => parseInt(value, 10);

  numReplace = (value: string) => parseInt(value.replace(/[^0-9]/g, ''), 10);
}

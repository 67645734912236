import {computed, isObservableArray, makeObservable, observable} from 'mobx';
import {v4 as uuidV4} from 'uuid';
import {FormField} from './FormField';
import {ValidatorResponse} from '@lib/Form/Validators';

export type FormChangeCallback = () => void;

export type FormFieldListIndexType = string | number;

export type FormFieldList<K extends FormFieldListIndexType> = {[key in K]: FormFieldType<K> | FormFieldType<K>[]};

export type FormFieldType<K extends FormFieldListIndexType = FormFieldListIndexType> = FormField<any> | CustomForm<FormFieldListIndexType, any>;

export type FormValidatorType = () => Promise<ValidatorResponse> | ValidatorResponse;

export abstract class CustomForm<K extends FormFieldListIndexType, T extends FormFieldList<K>> {
  private readonly _id: string;
  @observable readonly fields: T;
  @observable private _dirty = false;
  protected _onChangeCallback!: FormChangeCallback;

  constructor(fields: T) {
    this._id = uuidV4();

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        (fields[key] as FormFieldType).onChangeCallback = (field) => this.onChangeFieldValue(field);
      }
    }

    this.fields = fields;

    makeObservable(this);
  }

  get id(): string {
    return this._id;
  }

  protected onChangeFieldValue(field: FormFieldType) {
    if (this._onChangeCallback) {
      this._onChangeCallback();
    }
  }

  set onChangeCallback(callback: FormChangeCallback) {
    this._onChangeCallback = callback;
  }

  @computed get isValid(): boolean {
    let isValid = true;

    for (const key in this.fields) {
      if (this.fields.hasOwnProperty(key)) {
        const field = this.fields[key];

        if (isObservableArray(field)) {
          if (!field.every((f) => f.isValid)) {
            isValid = false;
          }
        } else if (!(field as FormFieldType).isValid) {
          isValid = false;
        }
      }
    }

    return isValid;
  }

  @computed get dirty(): boolean {
    let isDirty = this._dirty;

    for (const key in this.fields) {
      if (this.fields.hasOwnProperty(key)) {
        const field = this.fields[key];

        if (isObservableArray(field)) {
          if (field.find((f) => f.dirty)) {
            isDirty = true;
          }
        } else if ((field as FormFieldType).dirty) {
          isDirty = true;
        }
      }
    }

    return isDirty;
  }

  markDirty() {
    this._dirty = true;
  }

  async validate(): Promise<boolean> {
    let isValid = true;

    const promises: Promise<void>[] = [];

    for (const key in this.fields) {
      promises.push(
        new Promise<void>(async (resolve) => {
          if (!this.fields.hasOwnProperty(key)) {
            resolve();
          }

          const field = this.fields[key];

          if (field) {
            if (isObservableArray(field)) {
              const subPromises: Promise<boolean>[] = [];
              if (field.length) {
                field.forEach((f) => subPromises.push(f.validate()));
              }

              const fieldsIsValid = (await Promise.all(subPromises)).every((e) => e);
              if (!fieldsIsValid) {
                isValid = false;
              }
            } else {
              const fieldIsValid: boolean = await (field as FormFieldType).validate();
              if (!fieldIsValid) {
                isValid = false;
              }
            }
          }

          resolve();
        }),
      );
    }

    await Promise.all(promises);

    return isValid;
  }

  reset() {
    this._dirty = false;

    for (const key in this.fields) {
      if (this.fields.hasOwnProperty(key)) {
        const field = this.fields[key];

        if (isObservableArray(field)) {
          field.map((f) => {
            f.reset();
          });
        } else {
          (field as FormFieldType).reset();
        }
      }
    }
  }
}

import * as React from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from 'mobx';
import styled from 'styled-components';
import {Translator} from '@lib/Translation';
import {DatePickerCalendarIcon} from '@components/Icons';
import {IconButton} from '@components/UI';
import {PickerPopup} from './PickerPopup';
import {DatePickerService} from './lib';
import {DatePickerInput} from './Input';

import './style.scss';
import {ClearInputButton} from '@components/Form';

type ChangeCallback = (date?: Date) => void;

export enum DatePickerMode {
  DEFAULT = 1,
  POPUP_ONLY,
}

export interface DatePickerProps {
  placeholder?: string;
  dateSeparator?: string;
  readOnly?: boolean;
  value?: Date;
  onChange?: ChangeCallback;
  onPaste?: (v: string) => Date | undefined;
  locales?: string;
  gmtText?: string;
  showTimePicker?: boolean;
  allowClear?: boolean;
  className?: string;
  translator: Translator;
  onOpen?: () => void;
  onClose?: () => void;
  customIcon?: JSX.Element;
  mode?: DatePickerMode;
  children?: React.ReactNode;
}

@observer
export class DatePicker extends React.Component<DatePickerProps, {}> {
  @observable customValue?: Date;
  @observable showDatePicker = false;
  @observable focusInput = false;
  @observable datePickerService: DatePickerService = new DatePickerService(
    // eslint-disable-next-line react/destructuring-assignment
    this.props.value,
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onChange,
  );
  private onChange?: ChangeCallback;

  focusTimer?: number;
  parentRef: React.RefObject<HTMLDivElement> = React.createRef();
  inputRef: React.RefObject<DatePickerInput> = React.createRef();
  popupRef: React.RefObject<PickerPopup> = React.createRef();

  constructor(props: DatePickerProps) {
    super(props);

    this.onChange = props.onChange;

    makeObservable(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Readonly<DatePickerProps>) {
    const {value} = this.props;

    if (this.datePickerService && this.datePickerService.updateValue) {
      if (!value) {
        this.datePickerService.cleanValue();
      } else {
        this.datePickerService.update(value);
      }
    }
  }

  componentWillUnmount() {
    if (this.focusTimer) {
      clearTimeout(this.focusTimer);
    }
  }

  selectDate(date: Date | undefined, onSelect: ChangeCallback) {
    this.customValue = date ? new Date(date.getTime()) : undefined;
    this.onChange = onSelect;
    this.openPicker();
  }

  @action
  onFocusInput = () => {
    if (this.focusTimer) {
      clearTimeout(this.focusTimer);
    }
    this.focusInput = true;
  };

  @action
  onBlurInput = () => {
    this.focusTimer = window.setTimeout(() => {
      this.focusInput = false;
    }, 10);
  };

  @action
  openPicker = () => {
    const {onOpen} = this.props;

    this.showDatePicker = true;
    onOpen && onOpen();
  };

  @action
  closePicker = () => {
    const {onClose} = this.props;

    this.showDatePicker = false;
    onClose && onClose();
  };

  @action
  save = (date?: Date) => {
    const {onChange, allowClear} = this.props;

    if (date) {
      this.onChange && this.onChange(new Date(date));
      this.datePickerService = new DatePickerService(date, onChange);
    } else if (allowClear) {
      this.onClear();
    }
    this.closePicker();
  };

  @action
  private onClear = () => {
    const {onChange} = this.props;

    this.onChange && this.onChange(undefined);
    this.datePickerService = new DatePickerService(new Date(), onChange);
  };

  render() {
    const {
      readOnly = false,
      value,
      onChange,
      onPaste,
      gmtText,
      showTimePicker,
      placeholder,
      dateSeparator,
      allowClear,
      translator,
      customIcon,
      mode = DatePickerMode.DEFAULT,
      className,
      children,
    } = this.props;

    const classNames = `bfdp-input ${this.focusInput ? ' bfdp-input-focus' : ''}${readOnly ? ' bfdp-input-disabled' : ''} field-body${
      className ? ` ${className}` : ''
    }${this.showDatePicker ? ' bfdp-picker-visible' : ''}`;

    return (
      <div className={classNames} ref={this.parentRef}>
        {mode === DatePickerMode.DEFAULT && (
          <>
            <StyledIconButton onClick={!readOnly ? this.openPicker : () => ''} disabled={readOnly}>
              {customIcon || (
                <DatePickerCalendarIcon style={{fill: '#333333'}} className={`bfdp-calendar ${readOnly ? 'bfdp-calendar-disabled' : ''}`} />
              )}
            </StyledIconButton>
            <DatePickerInput
              placeholder={placeholder}
              dateSeparator={dateSeparator}
              isFocus={this.focusInput}
              readOnly={readOnly}
              onBlur={this.onBlurInput}
              onFocus={this.onFocusInput}
              value={value}
              ref={this.inputRef}
              onChange={this.onChange}
              onPasteFnc={onPaste}
              datePickerService={this.datePickerService}
              showTime={showTimePicker}
            />
            {!readOnly && allowClear && <ClearInputButton visible={!!value} onClick={this.onClear} />}
          </>
        )}

        {this.showDatePicker && (
          <PickerPopup
            bindValue={this.customValue ?? value}
            ref={this.popupRef}
            showDatePicker={this.showDatePicker}
            showTime={showTimePicker}
            save={this.save}
            closePicker={this.closePicker}
            gmtText={gmtText}
            allowClear={allowClear}
            translator={translator}
          />
        )}

        {children}
      </div>
    );
  }
}

const StyledIconButton = styled(IconButton)`
  && {
    border-radius: 0;
    vertical-align: top;
  }
`;

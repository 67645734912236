import React from 'react';
import {observer} from 'mobx-react';
import {Translator} from '@lib/Translation';
import {OrderCodeConfigurationData} from '@lib/Api';
import {Loader} from '@components/UI';
import {Datatable, DatatableColumnData} from '@components/Datatable';

interface ConfigurationData {
  characteristic: string;
  value: string;
  description: string;
}

interface OrderCodeStatusModalProps {
  configuration: OrderCodeConfigurationData[];
  translator: Translator;
}

export const OrderCodeConfigurationsModal = observer(({translator, configuration}: OrderCodeStatusModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [columns, setColumns] = React.useState<DatatableColumnData<ConfigurationData>[]>([]);
  const [rows, setRows] = React.useState<ConfigurationData[]>([]);

  React.useEffect(() => {
    setLoading(true);

    setColumns([
      {
        label: translator.translate('Characteristic'),
        key: 'characteristic',
        width: 30,
      },
      {
        label: translator.translate('Value'),
        key: 'value',
        width: 10,
      },
      {
        label: translator.translate('Description'),
        key: 'description',
        width: 50,
      },
    ]);

    const r: ConfigurationData[] = [];
    if (configuration?.length) {
      configuration.forEach((config, configIndex) => {
        config.options.forEach((option, optionIndex) => {
          r.push({
            characteristic: optionIndex === 0 ? config.label : '',
            value: option.value,
            description: option.label,
          });
        });
      });
    }
    setRows(r);

    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Datatable name='configuration' translator={translator} columns={columns} rows={rows} minWidth={660} scrollable resizable writeable />
      )}
    </>
  );
});

import styled from 'styled-components';
import * as React from 'react';
import {PRIMARY_COLOR, SECONDARY_COLOR} from '@lib/Utils';

export interface IButton {
  scheme?: IButtonScheme;
}

export enum IButtonScheme {
  PRIMARY,
  PRIMARY_INVERTED,
  TERTIARY,
}

export enum IButtonMode {
  DEFAULT,
  HOVER,
  DISABLED,
}

export enum IButtonColorTarget {
  BACKGROUND,
  BORDER,
  COLOR,
}

const buttonColorByScheme = (scheme: IButtonScheme, mode: IButtonMode, target?: IButtonColorTarget): string => {
  if (target === IButtonColorTarget.COLOR) {
    if (scheme === IButtonScheme.PRIMARY_INVERTED) {
      return PRIMARY_COLOR;
    }
    return '#ffffff';
  }

  switch (scheme) {
    case IButtonScheme.PRIMARY:
      if (mode === IButtonMode.DEFAULT) {
        return PRIMARY_COLOR;
      }
      if (mode === IButtonMode.HOVER) {
        return '#650037';
      }
      return '#F0F0F0';
    case IButtonScheme.PRIMARY_INVERTED:
      if (mode === IButtonMode.DEFAULT) {
        if (target === IButtonColorTarget.BORDER) {
          return PRIMARY_COLOR;
        }
        return 'transparent';
      }
      if (mode === IButtonMode.HOVER) {
        if (target === IButtonColorTarget.BORDER) {
          return PRIMARY_COLOR;
        }
        return '#f6e1ee';
      }
      return PRIMARY_COLOR;
    case IButtonScheme.TERTIARY:
      if (mode === IButtonMode.DEFAULT) {
        return '#8fa2ac';
      }
      if (mode === IButtonMode.HOVER) {
        return SECONDARY_COLOR;
      }
      return '#ffffff';
  }
};

export const ButtonGeneric = ({scheme = IButtonScheme.PRIMARY}: IButton) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  font-size: 16px;
  line-height: 18px;
  color: ${buttonColorByScheme(scheme, IButtonMode.DEFAULT, IButtonColorTarget.COLOR)};
  font-weight: 700;
  text-align: center;
  height: 34px;
  border-radius: 0;
  cursor: pointer;
  padding: 0 15px;
  outline: 0;
  border: 1px solid ${buttonColorByScheme(scheme, IButtonMode.DEFAULT, IButtonColorTarget.BORDER)};
  background-color: ${buttonColorByScheme(scheme, IButtonMode.DEFAULT, IButtonColorTarget.BACKGROUND)};
  transition: border-color 0.2s ease-in, background-color 0.2s ease-in, color 0.2s ease-in;
  &:hover, &:focus {
    border-color: ${buttonColorByScheme(scheme, IButtonMode.HOVER, IButtonColorTarget.BORDER)};
    background-color: ${buttonColorByScheme(scheme, IButtonMode.HOVER, IButtonColorTarget.BACKGROUND)};
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: ${buttonColorByScheme(scheme, IButtonMode.DISABLED, IButtonColorTarget.BACKGROUND)};
    ${
      scheme === IButtonScheme.PRIMARY
        ? `
      color: #bbbbbb;
      border-color: ${buttonColorByScheme(scheme, IButtonMode.DISABLED, IButtonColorTarget.BORDER)};
    `
        : `
        color: #DADADA;
        `
    };
  }
`;

export enum ButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset',
}

export interface ButtonProps {
  type?: ButtonType;
  inverted?: boolean;
  className?: string;
}

export const PrimaryButton = styled.button.attrs<ButtonProps>(({type = ButtonType.BUTTON, className = 'btn'}: ButtonProps) => ({
  type,
  className,
}))`
  ${ButtonGeneric({
    scheme: IButtonScheme.PRIMARY,
  })}
`;

export const PrimaryInvertedButton = styled.button.attrs<ButtonProps>(({type = ButtonType.BUTTON, className = 'btn'}: ButtonProps) => ({
  type,
  className,
}))`
  ${ButtonGeneric({
    scheme: IButtonScheme.PRIMARY_INVERTED,
  })}
`;

export const TertiaryButton = styled.button.attrs<ButtonProps>(({type = ButtonType.BUTTON, className = 'btn'}: ButtonProps) => ({
  type,
  className,
}))`
  ${ButtonGeneric({
    scheme: IButtonScheme.TERTIARY,
  })}
`;

export const IconButtonGeneric = `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 32px;
  height: 32px;    
  width: 32px;    
  padding: 0;
  outline: 0;
  border: 0;
  background-color: #ffffff;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  color: ${PRIMARY_COLOR};
  font-weight: 400;
  &:not([disabled]) {
    &:hover {
      background-color: rgba(29, 90, 126, 0.1);
    }
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: #d8d8d8;
  }
`;

export const IconButton = styled.button.attrs<ButtonProps>(({type = ButtonType.BUTTON, className = 'btn'}: ButtonProps) => ({
  type,
  className,
}))`
  ${IconButtonGeneric};
  > .icon {
    transition: fill 0.2s ease-in !important;
  }
`;

import * as React from 'react';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AxiosError} from 'axios';
import {createSearchForm, SearchForm, SearchFormFields} from '@lib/Form/Search';
import {
  ApiClient,
  CumulatedData,
  CumulatedDataType,
  ErrorResponseCode,
  ErrorResponseType,
  MaterialData,
  MaterialProductData,
  OrderCodeData,
} from '@lib/Api';
import {DateFormatString, generateTrackingViewBaseData, parseDate, trackEvent, TrackingData, trackView} from '@lib/Utils';
import {Language, Translator} from '@lib/Translation';
import {ModalService} from '@lib/Modal';
import {DefaultModalContainer} from '@components/Modal';
import {TertiaryButton, Text, TextSize, TextWeight} from '@components/UI';
import {decodeProductionDate} from './shared';

export interface SearchConfig {
  title?: boolean;
  lang?: Language;
  inline?: boolean;
  orderCode?: string;
  productionDate?: string;
  internal?: boolean;
  current?: boolean;
  hookUrl?: string;
  target?: string;
  appCall?: string;
  cumulated?: CumulatedDataType;
}

const SEARCH_STORAGE_KEY = 'sft_search_state';

const SEARCH_RESULTS_LIMIT = 15;

enum SearchQueryParams {
  TITLE = 'title',
  LANG = 'lang',
  INLINE = 'inline',
  ORDER_CODE = 'orderCode',
  PROD_DATE = 'proddate',
  INTERNAL = 'internal',
  CURRENT = 'current',
  HOOK_URL = 'hookurl',
  TARGET = 'target',
  APP_CALL = 'appcall',
}

export enum ResultsType {
  ORDER_CODE = 'orderCode',
  PART_NUMBER = 'partNumber',
  CUMULATED = 'cumulated',
  SEARCH_LIST = 'search',
}

export type ResultsDataType = MaterialData | OrderCodeData | CumulatedData | SearchHistory;

export interface ResultsData<T = ResultsDataType> {
  type: ResultsType;
  data?: T;
}

export type SearchType = ResultsType.ORDER_CODE | ResultsType.PART_NUMBER;

export interface SearchHistoryData {
  type: SearchType;
  search: string;
  productionDate?: string;
  searchAt: string;
}

export type SearchHistory = SearchHistoryData[];

export interface HookUrlParams {
  country?: string;
  appcall?: string;
  'nebp.hookTarget'?: string;
  'nebp.cartId'?: string;
}

export class SearchService {
  @observable private _initializing: boolean = true;
  @observable private _error: boolean = false;
  @observable private _loading: boolean = false;
  @observable private _results?: ResultsData;
  @observable private _searchResults?: ResultsData<SearchHistory>;
  @observable private _xmlBasket?: string;
  @observable private readonly _searchData: SearchHistory = [];
  private _searchForm!: SearchForm;
  private _showTitle: boolean = false;
  private _inline: boolean = false;
  private _internal: boolean = false;
  private current: boolean = false;
  private _hookUrl?: string;
  private _hookUrlParams?: HookUrlParams;
  private _target?: string;
  private _appCall?: string;
  readonly addToCartFormRef: React.RefObject<HTMLFormElement> = React.createRef();

  constructor(
    private readonly translator: Translator,
    private readonly apiClient: ApiClient,
    private readonly modalService: ModalService,
  ) {
    this._searchData = localStorage.getItem(SEARCH_STORAGE_KEY) ? JSON.parse(localStorage.getItem(SEARCH_STORAGE_KEY) as string) : [];

    makeObservable(this);
  }

  private handleSearchFormChange = () => {};

  @action
  onSelectSearchHistoryEntry = async (searchEntry: SearchHistoryData) => {
    this._searchForm.reset();

    const {type, search, productionDate: prettyProductionDate} = searchEntry;

    const orderCodeField = this._searchForm.fields[SearchFormFields.ORDER_CODE];
    const productionDateField = this._searchForm.fields[SearchFormFields.PRODUCTION_DATE];
    const partNumberField = this._searchForm.fields[SearchFormFields.PART_NUMBER];

    switch (type) {
      case ResultsType.ORDER_CODE:
        orderCodeField.value = search;
        if (prettyProductionDate) {
          productionDateField.value = new Date(prettyProductionDate);
        }
        break;
      case ResultsType.PART_NUMBER:
        partNumberField.value = search;
        break;
    }

    this._searchResults = undefined;
    await this.onSearch(type);
  };

  @action
  onSelectMaterialProduct = async (materialProductData: MaterialProductData) => {
    this._searchForm.reset();

    const {productRoot} = materialProductData;

    const orderCodeField = this._searchForm.fields[SearchFormFields.ORDER_CODE];
    orderCodeField.value = productRoot;

    await this.onSearch(ResultsType.ORDER_CODE);
  };

  @action
  onSearch = async (type: ResultsType, searchType?: SearchType) => {
    const orderCodeField = this._searchForm.fields[SearchFormFields.ORDER_CODE];
    const productionDateField = this._searchForm.fields[SearchFormFields.PRODUCTION_DATE];
    const partNumberField = this._searchForm.fields[SearchFormFields.PART_NUMBER];

    const baseTrackingData: TrackingData = {
      ...generateTrackingViewBaseData(this.translator, 'search result', this.internal, 'sft.en.search result.successful', this.appCall),
      site_h2: 'successful',
    };

    switch (type) {
      case ResultsType.ORDER_CODE:
        this._loading = true;
        const orderCode = orderCodeField.value;
        const productionDate = productionDateField.value;
        partNumberField.value = undefined;

        if (orderCode) {
          let prettyProductionDate = undefined;
          if (productionDate) {
            prettyProductionDate = parseDate(DateFormatString.D, productionDate);
          }

          try {
            const data = await this.apiClient.findOrderCode(orderCode, prettyProductionDate, this.current, this._internal);

            this.saveSearchEntry(type, orderCode, prettyProductionDate);
            trackView({
              ...baseTrackingData,
              search_term: orderCode,
              product_id: data?.productRoot,
              order_code: data?.orderCode,
              search_functionality: 'order code / product root / serial number',
              product_name: data?.label,
              search_results: `${data?.rows?.length ?? 0}`,
            });

            runInAction(() => {
              this._results = {
                type,
                data,
              };
            });
          } catch (e) {
            this.handleSearchError(e as AxiosError);
            this._results = {
              type,
            };
          }
        } else {
          this._results = undefined;
        }
        break;
      case ResultsType.PART_NUMBER:
        this._loading = true;
        const partNumber = partNumberField.value;
        orderCodeField.value = undefined;
        productionDateField.value = undefined;

        if (partNumber) {
          try {
            const data = await this.apiClient.findMainProduct(partNumber, this.current);

            this.saveSearchEntry(type, partNumber);
            trackView({
              ...baseTrackingData,
              search_term: partNumber,
              product_id: data?.materialNumber,
              order_code: data?.materialNumber,
              search_functionality: 'spare part number',
              product_name: data?.label,
              search_results: `${data?.products?.length ?? 0}`,
            });

            runInAction(() => {
              this._results = {
                type,
                data,
              };
            });
          } catch (e) {
            this.handleSearchError(e as AxiosError);
            this._results = {
              type,
            };
          }
        } else {
          this._results = undefined;
        }
        break;
      case ResultsType.SEARCH_LIST:
        const data: SearchHistoryData[] = [
          ...(this._searchData ?? [])
            .filter((item) => (searchType ? item.type === searchType : true))
            .sort((rowA, rowB) => {
              const rowACreatedAtTime = new Date(rowA.searchAt).getTime();
              const rowBCreatedAtTime = new Date(rowB.searchAt).getTime();

              if (rowACreatedAtTime > rowBCreatedAtTime) {
                return -1;
              }
              if (rowBCreatedAtTime < rowACreatedAtTime) {
                return 1;
              }
              return 0;
            })
            .splice(0, SEARCH_RESULTS_LIMIT),
        ];

        if (data.length) {
          this._searchResults = {
            type: ResultsType.SEARCH_LIST,
            data: [...data],
          };
        } else {
          this.clearSearchResults();
        }
        break;
    }

    setTimeout(() => {
      runInAction(() => {
        this._loading = false;
      });
    }, 0);
  };

  private handleSearchError(e: AxiosError) {
    // We have to prevent showing internal errors
    const errors = ((e.response?.data as ErrorResponseType | undefined) ?? []).filter((error) => !error.internal);

    if (errors.length > 0) {
      const [firstError] = errors;

      const title = this.translator.translate('Error');
      switch (firstError.code) {
        case ErrorResponseCode.NOT_FOUND:
          this.showErrorMessage(title, this.translator.translate('No data'), false);
          break;
        case ErrorResponseCode.ORDER_INVALID_CODE:
          this.showErrorMessage(title, this.translator.translate('The entered order code is invalid'), false);
          break;
        case ErrorResponseCode.ORDER_CODE_TOO_SHORT:
          this.showErrorMessage(title, this.translator.translate('Ordercode too short'), false);
          break;
        case ErrorResponseCode.ORDER_CODE_TOO_LONG:
          this.showErrorMessage(title, this.translator.translate('Ordercode too long'), false);
          break;
        default:
          this.showErrorMessage(title, firstError.message, false);
          break;
      }
    }
  }

  @action
  clearSearchResults() {
    this._searchResults = undefined;
  }

  @action
  onAddToCart = async (orderCodes: string[]) => {
    this._xmlBasket = `<bas:basket xmlns:bas="urn:com:endress:crm:onlineshop:basket.2.5.xsd" xmlns:c="urn:com:endress:crm:onlineshop:common.2.5.xsd" positionsCount="${orderCodes.length}" version="2.5" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">${orderCodes.map((orderCode) => `<bas:item><bas:product><bas:orderCode>${orderCode}</bas:orderCode></bas:product></bas:item>`).join('')}</bas:basket>`;

    switch (this._results?.type) {
      case ResultsType.ORDER_CODE:
        const data = this._results?.data as OrderCodeData;
        trackEvent({
          event_name: 'add_to_cart',
          event_linkname: 'Add to Cart',
          event_order_code: orderCodes.join(','),
          event_product_name: data.rows
            .filter((r) => orderCodes.find((o) => r.orderCode === o) !== undefined)
            .map((o) => o.label)
            .join(', '),
          event_sft_for: data.productRoot,
          event_id: 'sft.de.search result.successful.add_to_cart.Add to Cart',
        });
        break;
    }

    setTimeout(() => {
      this.addToCartFormRef.current?.submit();
    }, 0);
  };

  @action
  private saveSearchEntry(type: SearchType, search: string, productionDate?: string) {
    const entry = this._searchData.find((data) => data.search?.toLowerCase() === search?.toLowerCase() && data.productionDate === productionDate);

    if (entry) {
      entry.searchAt = new Date().toISOString();
    } else {
      let entry: SearchHistoryData = {
        type,
        search,
        productionDate,
        searchAt: new Date().toISOString(),
      };

      this._searchData.push(entry);
    }

    localStorage.setItem(SEARCH_STORAGE_KEY, JSON.stringify(this._searchData));
  }

  @action
  async init(config?: SearchConfig) {
    this._initializing = true;

    this._searchForm = createSearchForm(this.handleSearchFormChange);
    const orderCodeField = this._searchForm.fields[SearchFormFields.ORDER_CODE];
    const productionDateField = this._searchForm.fields[SearchFormFields.PRODUCTION_DATE];

    if (config) {
      const {title, lang, inline, orderCode, productionDate, internal, current, hookUrl, target, appCall} = config;

      if (lang) {
        this.translator.setActiveLocale(lang);
      }
      this._showTitle = title ?? false;
      this._inline = inline ?? false;
      orderCodeField.value = orderCode;
      productionDateField.value = decodeProductionDate(productionDate);
      this._internal = internal ?? false;
      this.current = current ?? false;
      this.updateHookUrl(hookUrl);
      this._target = target ?? undefined;
      this._appCall = appCall ?? 'SFT';
    }

    const params = new URLSearchParams(location.search);
    if (params.get(SearchQueryParams.TITLE)) {
      this._showTitle =
        params.get(SearchQueryParams.TITLE) !== 'false' && params.get(SearchQueryParams.TITLE) !== '0' && !!params.get(SearchQueryParams.TITLE);
    }
    if (params.get(SearchQueryParams.LANG)) {
      this.translator.setActiveLocale(params.get(SearchQueryParams.LANG) as Language);
    }
    if (params.get(SearchQueryParams.INLINE)) {
      this._inline =
        params.get(SearchQueryParams.INLINE) !== 'false' && params.get(SearchQueryParams.INLINE) !== '0' && !!params.get(SearchQueryParams.INLINE);
    }
    if (params.get(SearchQueryParams.ORDER_CODE)) {
      orderCodeField.value = params.get(SearchQueryParams.ORDER_CODE) ?? undefined;
    }
    if (params.get(SearchQueryParams.PROD_DATE)) {
      productionDateField.value = decodeProductionDate(params.get(SearchQueryParams.PROD_DATE) ?? undefined);
    }
    if (params.get(SearchQueryParams.INTERNAL)) {
      this._internal =
        params.get(SearchQueryParams.INTERNAL) !== 'false' &&
        params.get(SearchQueryParams.INTERNAL) !== '0' &&
        !!params.get(SearchQueryParams.INTERNAL);
    }
    if (params.get(SearchQueryParams.CURRENT)) {
      this.current =
        params.get(SearchQueryParams.CURRENT) !== 'false' && params.get(SearchQueryParams.CURRENT) !== '0' && !!params.get(SearchQueryParams.CURRENT);
    }
    this.updateHookUrl(params.get(SearchQueryParams.HOOK_URL) ?? undefined);
    if (params.get(SearchQueryParams.TARGET)) {
      this._target = params.get(SearchQueryParams.TARGET) ?? undefined;
    }
    if (params.get(SearchQueryParams.APP_CALL)) {
      this._appCall = params.get(SearchQueryParams.APP_CALL) ?? 'SFT';
    }

    if (config?.cumulated) {
      let cumulatedData: CumulatedData | undefined;
      try {
        cumulatedData = await this.apiClient.findCumulated(config.cumulated, this.current, this._internal);
      } catch (e) {}

      if (cumulatedData) {
        runInAction(() => {
          this._results = {
            type: ResultsType.CUMULATED,
            data: cumulatedData,
          };
        });
      } else {
        this.showErrorMessage(this.translator.translate('Error'), this.translator.translate('Could not load cumulated data'));
      }
    } else if (this._inline) {
      if (orderCodeField.value) {
        await this.onSearch(ResultsType.ORDER_CODE);
        if (!this._results) {
          this.showErrorMessage(this.translator.translate('Error'), this.translator.translate('No data available for the entered search parameters'));
        }
      } else {
        this.showErrorMessage(this.translator.translate('Error'), this.translator.translate('Please enter an order code'));
      }
    } else if (orderCodeField.value) {
      await this.onSearch(ResultsType.ORDER_CODE);
    }

    runInAction(() => {
      this._initializing = false;
    });
  }

  private updateHookUrl = (hookUrl?: string) => {
    if (!hookUrl) {
      return;
    }

    const hookUrlSearchParams = new URLSearchParams(hookUrl);
    console.log(`${hookUrl} -> nebp.hookTarget = ${hookUrlSearchParams.get('nebp.hookTarget') ?? ''}`);
    console.log(`${hookUrl} -> nebp.cartId = ${hookUrlSearchParams.get('nebp.cartId') ?? ''}`);
    if (hookUrlSearchParams.size) {
      this._hookUrlParams = {
        country: hookUrlSearchParams.get('country') ?? undefined,
        appcall: hookUrlSearchParams.get('appcall') ?? 'SFT',
        'nebp.hookTarget': hookUrlSearchParams.get('nebp.hookTarget') ?? undefined,
        'nebp.cartId': hookUrlSearchParams.get('nebp.cartId') ?? undefined,
      };
    }
    this._hookUrl = hookUrl.split('?')[0];
  };

  private showErrorMessage(title: string, message: string, propagate: boolean = true) {
    this.modalService.show(
      <DefaultModalContainer>
        <Text size={TextSize.LARGE} weight={TextWeight.LIGHT} color='#e94c0a' paragraph>
          {message}
        </Text>
        <TertiaryButton onClick={this.modalService.hide}>{this.translator.translate('Ok')}</TertiaryButton>
      </DefaultModalContainer>,
      {
        title,
      },
    );
    if (propagate) {
      this._error = true;
    }
  }

  @action
  async destroy() {
    this._searchForm?.reset();
    this._initializing = true;
  }

  @computed
  get searchForm() {
    return this._searchForm;
  }

  @computed
  get results() {
    return this._results;
  }

  @computed
  get searchResults() {
    return this._searchResults;
  }

  @computed
  get initializing() {
    return this._initializing;
  }

  @computed
  get error() {
    return this._error;
  }

  @computed
  get loading() {
    return this._loading;
  }

  @computed
  get xmlBasket() {
    return this._xmlBasket;
  }

  get showTitle() {
    return this._showTitle;
  }

  get inline() {
    return this._inline;
  }

  get internal() {
    return this._internal;
  }

  get hookUrl() {
    return this._hookUrl;
  }

  get hookUrlParams() {
    return this._hookUrlParams;
  }

  get target() {
    return this._target;
  }

  get appCall() {
    return this._appCall;
  }
}

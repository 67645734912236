import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const DatePickerClockIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 17 17'
    body={
      <path
        d='M8.5,17C3.8,17,0,13.2,0,8.5C0,3.8,3.8,0,8.5,0C13.2,0,17,3.8,17,8.5C17,13.2,13.2,17,8.5,17z M8.5,1.4
	        c-3.9,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1s7.1-3.2,7.1-7.1S12.4,1.4,8.5,1.4z M9,8V4H8v4v1h1h4V8H9z'
      />
    }
    {...props}
  />
);

export const decodeProductionDate = (date?: string): Date | undefined => {
  if (!date) {
    return;
  }

  try {
    let d: Date | undefined;
    const parts = date.split('.');
    if (parts.length === 2) {
      d = new Date();
      d.setDate(1);
      d.setMonth(-(-parts[0]) - 1);
      d.setFullYear(-(-parts[1]));
    } else if (parts.length === 3) {
      d = new Date();
      d.setDate(-(-parts[0]));
      d.setMonth(-(-parts[1]) - 1);
      d.setFullYear(-(-parts[2]));
    } else {
      throw new Error('Date format is not supported');
    }

    if (d && d.toString() !== 'Invalid Date') {
      return d;
    }
    return;
  } catch (e) {
    return;
  }
};

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const DatePickerCalendarIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 18 16'
    body={
      <path
        d='M6,8H4v0V6v0h2v0V8L6,8z M8,6v2v0h2v0V6v0L8,6L8,6z M12,6L12,6v2v0h2v0V6v0H12z M4,10v2v0h2v0v-2v0L4,10L4,10z M8,10v2v0h2
          v0v-2v0L8,10L8,10z M18,4v10c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h2v0V0v0h2v0v2v0h6v0V0v0h2v0v2v0h2
          C17.1,2,18,2.9,18,4z M16,4H2h0l0,10h14V4z'
      />
    }
    {...props}
  />
);

import {OrderCodeStatusType, orderCodeStatusTypeToColor} from '@lib/Api';
import styled from 'styled-components';
import {LG_SIZE, XS_SIZE} from '@lib/Utils';

interface StatusDotProps {
  $status: OrderCodeStatusType;
}

export const StatusDot = styled.span<StatusDotProps>`
  display: block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({$status}: StatusDotProps) => orderCodeStatusTypeToColor($status)};
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
  width: 100%;
  @media all and (min-width: ${XS_SIZE}px) {
    flex-wrap: nowrap;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding: 0 15px;
  width: 100%;
  @media all and (min-width: ${XS_SIZE}px) {
    width: 50%;
  }
  @media all and (min-width: ${LG_SIZE}px) {
    width: 33%;
  }
`;

import * as React from 'react';
import {observer} from 'mobx-react';
import {DatePickerInputProps, InputDay, InputMinute} from '.';

interface InputHourProps extends DatePickerInputProps {
  hour: string;
  setHour: (value: string) => void;
  inputDayRef?: React.RefObject<InputDay>;
  inputMinuteRef: React.RefObject<InputMinute>;
  popup?: boolean;
}

@observer
export class InputHour extends React.Component<InputHourProps, {}> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  onChangeHour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {setHour, inputMinuteRef, datePickerService} = this.props;
    setHour(e.currentTarget.value);
    const hour = parseInt(datePickerService.hour.replace(/[^0-9]/g, ''), 10);
    if (inputMinuteRef.current && inputMinuteRef.current.inputRef.current && hour > 2) {
      inputMinuteRef.current.inputRef.current.focus();
    }
  };

  onKeyDownHour = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {inputDayRef, inputMinuteRef, datePickerService, popup} = this.props;
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (popup) {
          datePickerService.incrementHourPopup();
        } else {
          datePickerService.incrementHour();
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (popup) {
          datePickerService.decrementHourPopup();
        } else {
          datePickerService.decrementHour();
        }
        break;
      case 'ArrowRight':
        if (this.inputRef.current && inputMinuteRef.current && inputMinuteRef.current.inputRef.current) {
          if (this.inputRef.current.selectionEnd === 2) {
            inputMinuteRef.current.inputRef.current.focus();
          }
        }
        break;
      case 'ArrowLeft':
        if (this.inputRef.current && inputDayRef && inputDayRef.current && inputDayRef.current.inputRef.current) {
          if (this.inputRef.current.selectionStart === 0) {
            inputDayRef.current.inputRef.current.focus();
          }
        }
        break;
      default:
    }
  };

  onFocus = () => {
    const {onFocus} = this.props;
    if (this.inputRef.current) {
      this.inputRef.current.select();
    }
    onFocus();
  };

  render() {
    const {readOnly, hour, onBlur} = this.props;

    return (
      <input
        ref={this.inputRef}
        className='bfdp-input-short'
        value={hour}
        onChange={this.onChangeHour}
        onFocus={this.onFocus}
        onBlur={onBlur}
        disabled={readOnly}
        onKeyDown={this.onKeyDownHour}
      />
    );
  }
}

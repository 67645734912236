export interface MaterialData {
  label: string;
  materialNumber: string;
  materialNumbers: MaterialNumberData[];
  products: MaterialProductData[];
  mode: MaterialDataMode;
}

export enum MaterialDataMode {
  MAIN_PRODUCT = 'MAINPRODUCT',
  PRODUCTION_NUMBERS = 'PRODUCTION_NUMBERS',
}

export interface MaterialNumberData {
  label: string;
  materialNumber: string;
  productionNumbers: string[];
}

export interface MaterialProductData {
  productRoot: string;
  label: string;
}

export interface OrderCodeMainData {
  label: string;
  orderCode: string;
  productRoot: string;
  metadata: OrderCodeMetaData;
}

export enum OrderCodeCumulatedStatusType {
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND',
}

export interface OrderCodeCumulatedData extends OrderCodeMainData {
  status: OrderCodeCumulatedStatusType;
  tag?: string;
}

export interface CumulatedData {
  orderCodes: OrderCodeCumulatedData[];
  rows: OrderCodeRowData[];
}

export interface OrderCodeData extends OrderCodeMainData {
  rows: OrderCodeRowData[];
  documents: OrderCodeDocumentData[];
  materialNumber: string;
  status: OrderCodeStatusData;
  configuration: OrderCodeConfigurationData[];
}

export type OrderCodeDataType = OrderCodeData | OrderCodeCumulatedData[];

export interface OrderCodeMetaData {
  id: number;
  latestRevision: boolean;
  systemName: string;
  changeDate: string;
  updateDate: string;
}

export enum ServiceSegmentationType {
  TYPE_1 = 1,
  TYPE_2,
  TYPE_3,
  TYPE_4,
}

export interface OrderCodeRowData {
  id: string;
  sorting: number;
  orderCode: string;
  index: number;
  label: string;
  positionInDrawing: string;
  drawingGroup?: OrderCodeRowDrawingGroupData;
  configuration: OrderCodeConfigurationData[];
  internal: boolean;
  installationManuals: OrderCodeRowFileData[];
  compatibilityInformation: OrderCodeRowFileData[];
  infotexts: string[];
  category?: OrderCodeRowCategoryData;
  serviceSegmentation?: ServiceSegmentationType;
  recommendation: boolean;
  quoteTexts: string[];
  validFrom?: string;
  validUntil?: string;
  tags?: string[];
  orderable?: boolean;
}

export interface OrderCodeRowFileData {
  key?: string;
  url: string;
}

export enum DrawingGroupKey {
  INFO = 'info',
}

export type DrawingGroupKeyType = DrawingGroupKey | string;

export interface OrderCodeRowDrawingGroupData {
  key: DrawingGroupKeyType;
  label: string;
}

export enum PartClass {
  A = 'A',
  C = 'C',
  M = 'M',
  S = 'S',
  T = 'T',
  Z = 'Z',
}

export interface OrderCodeRowCategoryData {
  code: PartClass;
  name: string;
}

export interface OrderCodeDocumentData {
  master: string;
  thumbnail: string;
  name?: string;
}

export interface OrderCodeStatusData {
  materialNumber: string;
  salesReleasePlannedDate: string;
  repairCode: string;
  status: OrderCodeStatusType;
  noticeOfProductWithdrawalDate?: string;
  spareSensorsAvailableUntilDate?: string;
  sparesAvailableUntilDate?: string;
  pcOrderStopDate?: string;
  calibrationAvailableUntilDate?: string;
  repairPossibleUntilDate?: string;
  successor?: {
    label: string;
    orderCode: string;
  };
  maintenanceAdvices?: string[];
}

export enum OrderCodeStatusType {
  AVAILABLE = 'AVAILABLE',
  PHASED_OUT = 'PHASED_OUT',
  DISCONTINUED = 'DISCONTINUED',
}

export interface OrderCodeConfigurationData {
  label: string;
  feature: string;
  options: OrderCodeConfigurationOptionData[];
}

export interface OrderCodeConfigurationOptionData {
  value: string;
  label: string;
}

export type ErrorResponseType = ErrorResponseData[];

export enum ErrorResponseCode {
  ORDER_CODE_TOO_SHORT = 'orderCodeTooShort',
  ORDER_CODE_TOO_LONG = 'orderCodeTooLong',
  NOT_FOUND = 'notFound',
  ORDER_INVALID_CODE = 'orderCodeInvalid',
}

export interface ErrorResponseData {
  code: ErrorResponseCode;
  internal: boolean;
  level: string;
  message: string;
  parameters?: object;
}

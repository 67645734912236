export const GenericScrollbarStyles = `
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  /* button */
  ::-webkit-scrollbar-button {
    display: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  /* Corner */
  ::-webkit-scrollbar-corner {
    border-radius: 8px;
  }
`;

import * as React from 'react';
import {observer} from 'mobx-react';
import writeXlsxFile from 'write-excel-file';
import {PRIMARY_COLOR} from '@lib/Utils';
import {MaterialNumberData} from '@lib/Api';
import {Datatable, DatatableActionsContainer, DatatableColumnData} from '@components/Datatable';
import {PrimaryInvertedButton, Text} from '@components/UI';
import {DownloadIcon} from '@components/Icons';
import {GenericSearchProps} from '../../shared';

interface PartNumberMaterialNumbersResultsProps extends GenericSearchProps {
  data: MaterialNumberData[];
}

@observer
export class PartNumberMaterialNumbersResults extends React.Component<PartNumberMaterialNumbersResultsProps, {}> {
  private readonly datatableRef: React.RefObject<Datatable<MaterialNumberData>> = React.createRef();

  private onExport = async () => {
    const {translator} = this.props;

    const rowsToExport: MaterialNumberData[] = [...(this.datatableRef.current?.filteredSortedRows ?? [])];

    const orderCodeColumnName = translator.translate('Order code');
    const descriptionColumnName = translator.translate('Description');
    const productionNumbersColumnName = translator.translate('Production numbers');

    await writeXlsxFile(rowsToExport, {
      schema: [
        {
          column: orderCodeColumnName,
          width: Math.max(orderCodeColumnName.length, ...rowsToExport.map((row) => row.materialNumber?.length ?? 0)) + 1,
          value: (row) => row.materialNumber,
        },
        {
          column: descriptionColumnName,
          width: Math.max(descriptionColumnName.length, ...rowsToExport.map((row) => row.label?.length ?? 0)) + 1,
          value: (row) => row.label,
        },
        {
          column: productionNumbersColumnName,
          width: Math.max(productionNumbersColumnName.length, ...rowsToExport.map((row) => row.productionNumbers.join(', ')?.length ?? 0)) + 1,
          value: (row) => row.productionNumbers.join(', '),
        },
      ],
      headerStyle: {
        fontWeight: 'bold',
      },
      sheet: translator.translate('Search products'),
      fileName: `productsExport.xlsx`,
    });
  };

  private get columns(): DatatableColumnData<MaterialNumberData>[] {
    const {translator} = this.props;

    return [
      {
        width: 30,
        label: translator.translate('Order code'),
        key: 'materialNumber',
        sortable: true,
        searchable: true,
      },
      {
        width: 40,
        label: translator.translate('Description'),
        key: 'label',
        sortable: true,
        searchable: true,
      },
      {
        width: 30,
        label: translator.translate('Production numbers'),
        key: 'productionNumbers',
        sortable: false,
        searchable: true,
        render: (row) => <Text paragraph>{row.productionNumbers.join(', ')}</Text>,
      },
    ];
  }

  render() {
    const {data, translator} = this.props;

    return (
      <>
        <DatatableActionsContainer>
          <PrimaryInvertedButton onClick={this.onExport}>
            <DownloadIcon size={1.5} color={PRIMARY_COLOR} />
            {translator.translate('Download Excel')}
          </PrimaryInvertedButton>
        </DatatableActionsContainer>
        <Datatable
          ref={this.datatableRef}
          name='partNumberMaterialNumbers'
          translator={translator}
          columns={this.columns}
          rows={data}
          minWidth={560}
          resizable
          writeable
        />
      </>
    );
  }
}

export const FHD_SIZE = 1920;
export const XLG_SIZE = 1366;
export const LG_SIZE = 1170;
export const MD_SIZE = 992;
export const SM_SIZE = 640;
export const XS_SIZE = 480;

declare const GLOBAL_ENV: string;

export const IS_DEV_MODE: boolean = !!GLOBAL_ENV && GLOBAL_ENV === 'development';

export const PRIMARY_COLOR = '#a8005c';
export const SECONDARY_COLOR = '#007caa';
export const GRAY_COLOR = '#F0F4F6';

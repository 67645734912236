import * as React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {IconButton} from '@components/UI';
import {ClearIcon} from '@components/Icons';

export const INVALID_COLOR: string = '#ffaf31';

export const FormGroupOuter = styled.div`
  margin: 0 0 15px 0;
`;

export const FormGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 6px;
`;

export const Label = styled.label`
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: #333333;
`;

interface ILengthInfo {
  $invalid: boolean;
}

export const LengthInfo = styled.p<ILengthInfo>`
  margin: 5px 0 0 0 !important;
  font-size: 11px !important;
  line-height: 12px !important;
  color: ${({$invalid}: ILengthInfo) => ($invalid ? INVALID_COLOR : '#9c9c9c')} !important;
  cursor: default;
  text-align: right;
`;

interface ClearInputButtonProps {
  visible: boolean;
  onClick: () => void;
}

export const ClearInputButton = observer(({visible, onClick}: ClearInputButtonProps) => (
  <ClearIconButton onClick={onClick} $visible={visible}>
    <ClearIcon color='#333333' size={0.5} />
  </ClearIconButton>
));

interface ClearIconButtonProps {
  $visible: boolean;
}

const ClearIconButton = styled(IconButton)<ClearIconButtonProps>`
  && {
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 50%;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    transform: translateY(-50%);
    background-color: rgba(29, 90, 126, 0.1);
    transition: all 0.2s ease-in;
    ${({$visible}: ClearIconButtonProps) =>
      !$visible
        ? `
    opacity: 0;
    pointer-events: none;
  `
        : ''}
    &:hover {
      background-color: rgba(29, 90, 126, 0.15);
      > .icon {
        fill: #333333 !important;
      }
    }
  }
`;

import * as React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {v4 as uuidV4} from 'uuid';
import {Tooltip, TooltipPropsI} from './Tooltip';

interface ComponentWithTooltipProps {
  tooltip?: string | React.ReactNode;
  tooltipProps?: TooltipPropsI;
  children: React.ReactNode;
  className?: string;
}

export const ComponentWithTooltip = observer(({tooltip, tooltipProps, children, className}: ComponentWithTooltipProps) => {
  const id = uuidV4();

  if (!tooltip) {
    return children;
  }

  return (
    <>
      <Container data-tip data-for={id} className={className}>
        {children}
      </Container>
      <Tooltip {...tooltipProps} id={id}>
        {tooltip}
      </Tooltip>
    </>
  );
});

const Container = styled.span`
  display: flex;
  align-items: center;
`;

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const CaretUpIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 8 6'
    body={
      <path d='M0.518232 5.22265L3.58397 0.624038C3.78189 0.327172 4.21811 0.327172 4.41603 0.624038L7.48177 5.22265C7.70328 5.55493 7.46509 6 7.06574 6L0.934257 6C0.53491 6 0.296714 5.55493 0.518232 5.22265Z' />
    }
    {...props}
  />
);

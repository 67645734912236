import * as React from 'react';
import {observer} from 'mobx-react';
import {PickerBodyProps} from '..';

type PickerYearsProps = PickerBodyProps;

@observer
export class PickerYears extends React.Component<PickerYearsProps, {}> {
  render() {
    const {togglePickerMode, newDatePickerService} = this.props;

    const startYear = newDatePickerService.newDateYearAndMonth.getFullYear() - 10;
    return (
      <div className='bfdp-popup-body-selection'>
        <div className='bfdp-popup-body-selection-inner'>
          {new Array(11).fill('').map((v, i) => {
            const year = startYear + i;
            const selected = newDatePickerService.newDate.getFullYear() === year;
            return (
              <div key={i} className={`${selected ? 'bfdp-popup-body-selection-active' : ''}${selected ? ' bfdp-popup-body-selection-today' : ''}`}>
                <button
                  type='button'
                  onClick={() => {
                    newDatePickerService.changeCalendarYear(year);
                    togglePickerMode();
                  }}
                >
                  {year}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Translator} from '@lib/Translation';
import {CheckboxField} from './CheckboxField';
import {Label} from './shared';

export enum CheckboxGroupItemPredefinedId {
  ALL = 'all',
}

export type CheckboxGroupItemIdType = CheckboxGroupItemPredefinedId | string;

export interface CheckboxGroupItem {
  id: CheckboxGroupItemIdType;
  label: string;
}

interface CheckboxGroupFieldProps {
  label: string;
  allSelectable?: boolean;
  items: CheckboxGroupItem[];
  checkedIds?: CheckboxGroupItemIdType[];
  onChange: (id: CheckboxGroupItemIdType) => void;
  translator: Translator;
}

@observer
export class CheckboxGroupField extends React.Component<CheckboxGroupFieldProps, {}> {
  render() {
    const {label, allSelectable, items, checkedIds, onChange, translator} = this.props;

    return (
      <Container>
        <StyledLabel>{label}</StyledLabel>
        {allSelectable && (
          <StyledCheckboxField
            label={translator.translate('Check all')}
            onChange={() => onChange(CheckboxGroupItemPredefinedId.ALL)}
            checked={(checkedIds ?? []).indexOf(CheckboxGroupItemPredefinedId.ALL) > -1}
          />
        )}
        {items.map((item) => {
          const itemChecked = (checkedIds ?? []).indexOf(item.id) > -1 || (checkedIds ?? []).indexOf(CheckboxGroupItemPredefinedId.ALL) > -1;

          return <StyledCheckboxField key={item.id} label={item.label} checked={itemChecked} onChange={() => onChange(item.id)} />;
        })}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
`;

const StyledLabel = styled(Label)`
  && {
    font-size: 16px;
    font-weight: 600;
  }
`;

const StyledCheckboxField = styled(CheckboxField)`
  && {
    margin: 0;
  }
`;

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const DocPdfIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 40 40'
    body={
      <g>
        <path
          d='M32,10.6l-5.3-5.2c-0.8-0.9-1.9-1.4-3-1.4H11C8.8,4,7,5.8,7,8v24c0,2.2,1.8,4,4,4h18c2.2,0,4-1.8,4-4V13.3
		C33,12.3,32.7,11.4,32,10.6z M30.5,12C30.5,12,30.5,12,30.5,12L25,12V6.6c0.1,0.1,0.1,0.1,0.2,0.2L30.5,12z M31,32c0,1.1-0.9,2-2,2
		H11c-1.1,0-2-0.9-2-2V8c0-1.1,0.9-2,2-2h12v7c0,0.6,0.4,1,1,1h7V32z'
        />
        <path
          d='M14.3,23.6h-2.2V30h1.7v-2.1h0.5c0.8,0,1.4-0.2,1.8-0.6c0.4-0.4,0.6-0.9,0.6-1.7c0-0.7-0.2-1.2-0.6-1.5
		S15.2,23.6,14.3,23.6z M14.8,26.2c-0.2,0.1-0.4,0.2-0.6,0.2h-0.3V25h0.4c0.5,0,0.7,0.2,0.7,0.7C15,25.9,15,26.1,14.8,26.2z'
        />
        <path
          d='M20,23.6h-2.2V30h2.1c1.1,0,1.9-0.3,2.5-0.9c0.6-0.6,0.9-1.4,0.9-2.5c0-1-0.3-1.8-0.8-2.3
		C21.9,23.8,21.1,23.6,20,23.6z M21.1,28.1c-0.2,0.3-0.6,0.5-1.2,0.5h-0.4V25h0.5c0.5,0,0.8,0.1,1.1,0.4c0.2,0.3,0.3,0.7,0.3,1.3
		C21.5,27.3,21.3,27.8,21.1,28.1z'
        />
        <polygon points='26.1,27.6 28,27.6 28,26.2 26.1,26.2 26.1,25 28.2,25 28.2,23.6 24.4,23.6 24.4,30 26.1,30' />
      </g>
    }
    {...props}
  />
);

import * as React from 'react';
import styled from 'styled-components';

export enum TextWeight {
  LIGHT = 300,
  NORMAL = 400,
  BOLD = 600,
}

export type TextColorType = string;

export enum TextSize {
  TINY = 10,
  SMALL = 11,
  DEFAULT = 13,
  BIGGER = 16,
  LARGE = 18,
  HUGE = 26,
}

export enum TextStyle {
  ITALIC = 'italic',
}

export enum TextTransform {
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  CAPITALIZE = 'capitalize',
}

export interface TextProps {
  weight?: TextWeight;
  color?: TextColorType;
  size?: TextSize;
  style?: TextStyle;
  transform?: TextTransform;
  paragraph?: boolean;
  defaultMargin?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  __html?: string;
  className?: string;
  children?: React.ReactNode;
  innerRef?: React.RefObject<HTMLElement>;
}

export const Text = ({
  weight = TextWeight.NORMAL,
  color = '#333333',
  size = TextSize.DEFAULT,
  style,
  transform,
  paragraph = false,
  defaultMargin = false,
  onClick,
  __html,
  className,
  children,
  innerRef,
}: TextProps) => {
  if (paragraph) {
    return (
      <TextP
        ref={innerRef as React.RefObject<HTMLParagraphElement>}
        $textWeight={weight}
        $textSize={size}
        $textColor={color}
        $textStyle={style}
        $textTransform={transform}
        $defaultMargin={defaultMargin}
        onClick={onClick}
        className={className}
        {...(__html ? {dangerouslySetInnerHTML: {__html}} : {})}
      >
        {children}
      </TextP>
    );
  }

  return (
    <TextDiv
      ref={innerRef as React.RefObject<HTMLDivElement>}
      $textWeight={weight}
      $textSize={size}
      $textColor={color}
      $textStyle={style}
      $textTransform={transform}
      $defaultMargin={defaultMargin}
      onClick={onClick}
      className={className}
      {...(__html ? {dangerouslySetInnerHTML: {__html}} : {})}
    >
      {children}
    </TextDiv>
  );
};

interface TextGenericStylesProps {
  $textWeight: TextWeight;
  $textSize: TextSize;
  $textColor: TextColorType;
  $textStyle?: TextStyle;
  $textTransform?: TextTransform;
  $defaultMargin: boolean;
}

const TextGenericStyles = ({$textWeight, $textSize, $textColor, $textStyle, $textTransform, $defaultMargin}: TextGenericStylesProps) => `
  font-size: ${$textSize}px;
  line-height: 1.2em;
  font-weight: ${$textWeight};
  color: ${$textColor};
  ${$textStyle ? `font-style: ${$textStyle}` : ''};
  ${$textTransform ? `text-transform: ${$textTransform}` : ''};
  ${
    $defaultMargin
      ? `
    margin: 0 0 20px 0;
  `
      : `
    margin: 0;    
  `
  }
  
  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    font-weight: ${TextWeight.BOLD};
  }
`;

export const TextP = styled.p<TextGenericStylesProps>`
  ${TextGenericStyles}
`;

export const TextDiv = styled.div<TextGenericStylesProps>`
  ${TextGenericStyles}
`;

import * as React from 'react';
import {observer} from 'mobx-react';
import {DatePickerInputProps, InputHour} from '.';

interface InputMinuteProps extends DatePickerInputProps {
  minute: string;
  setMinute: (value: string) => void;
  inputHourRef: React.RefObject<InputHour>;
  popup?: boolean;
}

@observer
export class InputMinute extends React.Component<InputMinuteProps, {}> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  onChangeMinute = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {setMinute} = this.props;
    setMinute(e.currentTarget.value);
  };

  onKeyDownMinute = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {inputHourRef, datePickerService, popup} = this.props;
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (popup) {
          datePickerService.incrementMinutePopup();
        } else {
          datePickerService.incrementMinute();
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (popup) {
          datePickerService.decrementMinutePopup();
        } else {
          datePickerService.decrementMinute();
        }
        break;
      case 'ArrowRight':
        break;
      case 'ArrowLeft':
        if (this.inputRef.current && inputHourRef.current && inputHourRef.current.inputRef.current) {
          if (this.inputRef.current.selectionStart === 0) {
            inputHourRef.current.inputRef.current.focus();
          }
        }
        break;
      default:
    }
  };

  onFocus = () => {
    const {onFocus} = this.props;
    if (this.inputRef.current) {
      this.inputRef.current.select();
    }
    onFocus();
  };

  render() {
    const {readOnly, minute, onBlur} = this.props;

    return (
      <input
        ref={this.inputRef}
        className='bfdp-input-short'
        value={minute}
        onChange={this.onChangeMinute}
        onFocus={this.onFocus}
        onBlur={onBlur}
        disabled={readOnly}
        onKeyDown={this.onKeyDownMinute}
      />
    );
  }
}

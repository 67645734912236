import * as React from 'react';
import {observer} from 'mobx-react';
import {PickerBodyProps} from '..';
import {InputHour, InputMinute} from '../../../Input';
import {ArrowIcon, DatePickerClockIcon} from '@components/Icons';

type PickerTimeProps = PickerBodyProps;

@observer
export class PickerTime extends React.Component<PickerTimeProps, {}> {
  render() {
    const {newDatePickerService} = this.props;

    return (
      <div className='bfdp-popup-body-time'>
        <DatePickerClockIcon className='bfdp-popup-body-time-clock-icon' />
        <div className='bfdp-popup-body-time-hour'>
          <ArrowIcon className='bfdp-popup-body-time-hour-up' onClick={newDatePickerService.incrementHourPopup} />
          <InputHour
            onFocus={() => ''}
            onBlur={() => ''}
            popup
            readOnly={false}
            datePickerService={newDatePickerService}
            hour={newDatePickerService.hour}
            ref={newDatePickerService.inputHourRef}
            setHour={newDatePickerService.setHourPopup}
            inputMinuteRef={newDatePickerService.inputMinuteRef}
          />
          <ArrowIcon className='bfdp-popup-body-time-hour-down' onClick={newDatePickerService.decrementHourPopup} />
        </div>
        <input className='bfdp-popup-body-time-separator' value=':' disabled />
        <div className='bfdp-popup-body-time-minute'>
          <ArrowIcon className='bfdp-popup-body-time-minute-up' onClick={newDatePickerService.incrementMinutePopup} />
          <InputMinute
            onFocus={() => ''}
            onBlur={() => ''}
            popup
            readOnly={false}
            datePickerService={newDatePickerService}
            minute={newDatePickerService.minute}
            ref={newDatePickerService.inputMinuteRef}
            setMinute={newDatePickerService.setMinutePopup}
            inputHourRef={newDatePickerService.inputHourRef}
          />
          <ArrowIcon className='bfdp-popup-body-time-minute-down' onClick={newDatePickerService.decrementMinutePopup} />
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import {observer} from 'mobx-react';
import {DatePickerInputProps, InputDay, InputYear} from '.';

interface InputMonthProps extends DatePickerInputProps {
  month: string;
  setMonth: (value: string) => void;
  inputYearRef: React.RefObject<InputYear>;
  inputDayRef: React.RefObject<InputDay>;
  onPaste: (e: React.ClipboardEvent) => void;
}

@observer
export class InputMonth extends React.Component<InputMonthProps, {}> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  onChangeMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {setMonth, inputYearRef, datePickerService} = this.props;
    setMonth(e.currentTarget.value);
    const month = parseInt(datePickerService.month.replace(/[^0-9]/g, ''), 10);
    if (inputYearRef.current && inputYearRef.current.inputRef.current && month > 1) {
      inputYearRef.current.inputRef.current.focus();
    }
  };

  onKeyDownMonth = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {inputDayRef, inputYearRef, datePickerService} = this.props;
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        datePickerService.incrementMonth();
        break;
      case 'ArrowDown':
        e.preventDefault();
        datePickerService.decrementMonth();
        break;
      case 'ArrowRight':
        if (this.inputRef.current && inputYearRef.current && inputYearRef.current.inputRef.current) {
          if (this.inputRef.current.selectionStart === 0) {
            inputYearRef.current.inputRef.current.focus();
          }
        }
        break;
      case 'ArrowLeft':
        if (this.inputRef.current && inputDayRef.current && inputDayRef.current.inputRef.current) {
          if (this.inputRef.current.selectionEnd === 2) {
            inputDayRef.current.inputRef.current.focus();
          }
        }
        break;
      default:
    }
  };

  onFocus = () => {
    const {onFocus} = this.props;
    if (this.inputRef.current) {
      this.inputRef.current.select();
    }
    onFocus();
  };

  render() {
    const {readOnly, month, onBlur, onPaste} = this.props;

    return (
      <input
        ref={this.inputRef}
        className='bfdp-input-short'
        value={month}
        onChange={this.onChangeMonth}
        onFocus={this.onFocus}
        onBlur={onBlur}
        disabled={readOnly}
        onKeyDown={this.onKeyDownMonth}
        onPaste={onPaste}
      />
    );
  }
}

import styled from 'styled-components';
import {MD_SIZE, SM_SIZE} from '@lib/Utils';
import {Text, TextProps, TextSize, TextWeight} from '@components/UI';

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  width: 100%;
`;

export const SummaryInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px dotted #c3ced5;
  padding: 4px 0;
  @media all and (min-width: ${SM_SIZE}px) {
    flex-wrap: nowrap;
  }
  &:first-child {
    border-bottom-style: solid;
  }
`;

export const SummaryProps: TextProps = {
  size: TextSize.LARGE,
  weight: TextWeight.BOLD,
  paragraph: true,
};

export const SummaryTitle = styled(Text)`
  && {
    width: 100%;
    @media all and (min-width: ${MD_SIZE}px) {
      width: 30%;
    }
  }
`;

export const SummaryValue = styled(Text)`
  && {
    width: 100%;
    @media all and (min-width: ${MD_SIZE}px) {
      width: 70%;
    }
  }
`;

import {action, computed, makeObservable, observable} from 'mobx';
import {v4 as uuidV4} from 'uuid';
import {ValidatorErrorCode, ValidatorResponse, ValidatorType} from './Validators';
import {FormFieldType} from './CustomForm';

export type FormFieldChangeCallback = (field: FormFieldType) => void;

export class FormField<T> {
  private readonly _id: string;
  private readonly _name: string;
  @observable private _value?: T;
  @observable private _dirty = false;
  @observable private _errors: ValidatorErrorCode[] = [];
  private readonly _validators: ValidatorType[];
  private _onChangeCallback!: FormFieldChangeCallback;

  constructor(name: string, value: T | undefined, validators: ValidatorType[]) {
    this._id = uuidV4();
    this._name = name;
    this._value = value;
    this._validators = validators;

    makeObservable(this);
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  @computed get value(): T | undefined {
    return this._value;
  }

  set value(value: T | undefined) {
    this._value = value;
    this.markDirty();
    this.validate().then(() => {});
    this.propagateChange();
  }

  markDirty() {
    this._dirty = true;
  }

  @computed get dirty(): boolean {
    return this._dirty;
  }

  propagateChange() {
    if (this._onChangeCallback) {
      this._onChangeCallback(this);
    }
  }

  get validators(): ValidatorType[] {
    return this._validators;
  }

  set validators(validators: ValidatorType[]) {
    this._validators.length = 0;
    this._validators.push(...validators);
  }

  set onChangeCallback(callback: FormFieldChangeCallback) {
    this._onChangeCallback = callback;
  }

  @action
  reset(v?: T | undefined) {
    this._value = v;
    this._errors.length = 0;
  }

  @computed get errors(): ValidatorErrorCode[] {
    return this._errors;
  }

  @computed get invalid(): boolean {
    return !this.isValid;
  }

  @computed get isValid(): boolean {
    return this._errors.length === 0;
  }

  @action
  async validate(): Promise<boolean> {
    this._errors.length = 0;

    await Promise.all(
      this._validators.map(async (validator) => {
        const resp: ValidatorResponse = await validator(this._value);

        if (!resp.success && resp.errorCode) {
          this.errors.push(resp.errorCode);
        }
      }),
    );

    return this.isValid;
  }
}

import {PartClass, OrderCodeStatusType, ServiceSegmentationType} from './ApiData';
import {Translator} from '@lib/Translation';

export const orderCodeStatusTypeToLabel = (translator: Translator, status: OrderCodeStatusType): string => {
  switch (status) {
    case OrderCodeStatusType.AVAILABLE:
      return translator.translate('Available');
    case OrderCodeStatusType.PHASED_OUT:
      return translator.translate('Phase out');
    case OrderCodeStatusType.DISCONTINUED:
      return translator.translate('Discontinued');
  }
};

export const orderCodeStatusTypeToColor = (status: OrderCodeStatusType): string => {
  switch (status) {
    case OrderCodeStatusType.AVAILABLE:
      return '#0bc21e';
    case OrderCodeStatusType.DISCONTINUED:
      return '#ff4370';
    case OrderCodeStatusType.PHASED_OUT:
      return '#fbc224';
  }
};

export const partClassToLabel = (translator: Translator, partClass?: PartClass): string => {
  switch (partClass) {
    case PartClass.A:
      return translator.translate('accessories');
    case PartClass.C:
      return translator.translate('commissioning part');
    case PartClass.M:
      return translator.translate('wear part');
    case PartClass.S:
      return translator.translate('spare part');
    case PartClass.T:
      return translator.translate('tool');
    case PartClass.Z:
      return translator.translate('drawing');
    default:
      return translator.translate('no value');
  }
};

export const serviceSegmentationToLabel = (translator: Translator, serviceSegmentation?: ServiceSegmentationType) => {
  switch (serviceSegmentation) {
    case ServiceSegmentationType.TYPE_1:
      return translator.translate('service segmentation 1');
    case ServiceSegmentationType.TYPE_2:
      return translator.translate('service segmentation 2');
    case ServiceSegmentationType.TYPE_3:
      return translator.translate('service segmentation 3');
    case ServiceSegmentationType.TYPE_4:
      return translator.translate('service segmentation 4');
    default:
      return translator.translate('none');
  }
};

import {isObservableArray} from 'mobx';

export interface AbstractResponse<B = {}, E = number> {
  success: boolean;
  errorCode?: E;
  errorMessage?: string;
  body?: B;
}

export interface ValidatorList {
  [key: string]: ValidatorType[];
}

export type ValidatorType = ValidatorFunc | ValidatorFuncAsync;

export type ValidatorFunc = (value: any) => ValidatorResponse;
export type ValidatorFuncAsync = (value: any) => Promise<ValidatorResponse>;

export type ValidatorResponse<T = any> = AbstractResponse<T, ValidatorErrorCode>;

export enum ValidatorErrorCode {
  REQUIRED = 1,
  INVALID_EMAIL,
  INVALID_PASSWORD,
  VALUE_TOO_SHORT,
  VALUE_TOO_LONG,
  PASSWORDS_DONT_MATCH,
  INVALID_PHONE_NUMBER,
  INVALID_DATE,
  MAX_VARIABLES,
  MIN_VALUE,
  MAX_VALUE,
  REGEX,
  DATE_IN_PAST,
  DATE_IN_FUTURE,
  CUSTOM,
}

export const prettyError = (err: ValidatorErrorCode): string | undefined => {
  switch (err) {
    case ValidatorErrorCode.REQUIRED:
      return 'This field is required';
    case ValidatorErrorCode.INVALID_EMAIL:
      return 'Invalid email format';
    case ValidatorErrorCode.INVALID_PASSWORD:
      return 'Invalid password format';
    case ValidatorErrorCode.VALUE_TOO_SHORT:
      return 'Value is too short';
    case ValidatorErrorCode.VALUE_TOO_LONG:
      return 'Value is too long';
    case ValidatorErrorCode.PASSWORDS_DONT_MATCH:
      return "Passwords don't match";
    case ValidatorErrorCode.INVALID_PHONE_NUMBER:
      return 'Invalid phone number';
    case ValidatorErrorCode.INVALID_DATE:
      return 'Invalid date';
    case ValidatorErrorCode.MAX_VARIABLES:
      return 'Too many variables';
    case ValidatorErrorCode.MIN_VALUE:
      return 'Value is to small';
    case ValidatorErrorCode.MAX_VALUE:
      return 'Value is to big';
    case ValidatorErrorCode.REGEX:
      return 'Pattern does not match';
    case ValidatorErrorCode.DATE_IN_PAST:
      return 'Date/Time combination lies in the past';
    case ValidatorErrorCode.DATE_IN_FUTURE:
      return 'Date/Time combination lies in the future';
    default:
      return undefined;
  }
};

export const emailValidator = (value: string): ValidatorResponse => {
  const valid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(value).toLowerCase(),
    );

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.INVALID_EMAIL,
  };
};

export const requiredValidator = (value?: string | number | boolean | object): ValidatorResponse => {
  const valid =
    typeof value === 'boolean'
      ? true
      : (typeof value === 'object' && !Array.isArray(value) && !isObservableArray(value)) || (value && !isNaN(-(-value)))
      ? !!value || value === 0
      : typeof value === 'number'
      ? (!!value || value === 0) && !isNaN(value)
      : !!(value && value.length >= 1);

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.REQUIRED,
  };
};

export const fileRequiredValidator = (value?: File): ValidatorResponse => {
  const valid = !!value;

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.REQUIRED,
  };
};

export const minValidator = (min: number) => {
  return (value?: number): ValidatorResponse => {
    const valid = !!value || value === 0 ? value >= min : true;

    return {
      success: valid,
      errorCode: valid ? undefined : ValidatorErrorCode.MIN_VALUE,
    };
  };
};

export const minLengthValidator = (min: number) => {
  return (value: string | string[]): ValidatorResponse => {
    const valid = !value || Boolean(value && value.length >= min);

    return {
      success: valid,
      errorCode: valid ? undefined : ValidatorErrorCode.VALUE_TOO_SHORT,
    };
  };
};

export const maxValidator = (max: number) => {
  return (value?: number): ValidatorResponse => {
    const valid = !!value || value === 0 ? value <= max : true;

    return {
      success: valid,
      errorCode: valid ? undefined : ValidatorErrorCode.MAX_VALUE,
    };
  };
};

export const maxLengthValidator = (max: number) => {
  return (value: string): ValidatorResponse => {
    const valid = !value || Boolean(value && value.length <= max);

    return {
      success: valid,
      errorCode: valid ? undefined : ValidatorErrorCode.VALUE_TOO_LONG,
    };
  };
};

export const passwordStrongValidator = (value: string): ValidatorResponse => {
  const valid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$&+,:;_=?@#|'<>.-^*()%!]{8,}$/.test(String(value));

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.INVALID_PASSWORD,
  };
};

export const passwordValidator = (value: string): ValidatorResponse => {
  // const valid = /^[a-zA-Z\d$&+,:;=?@#|'<>.-^*()%!]{5,}$/.test(String(value));
  const valid = value.length >= 8;

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.INVALID_PASSWORD,
  };
};

export const phoneNumberValidator = (required = false) => {
  return (value: string): ValidatorResponse => {
    if (!required && value.trim() == '') {
      return {success: true};
    }

    const valid = /^[0-9\+ ]{9,}$/.test(value);

    return {
      success: valid,
      errorCode: valid ? undefined : ValidatorErrorCode.INVALID_PHONE_NUMBER,
    };
  };
};

export const dateValidator = (v: any): ValidatorResponse => {
  const valid = v instanceof Date;

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.INVALID_DATE,
  };
};

export const maxVariableValidator = (findVariables: (content: string) => string[], max: number) => {
  return (value: string): ValidatorResponse => {
    let valid = true;
    if (value) {
      const variables = findVariables(value);
      valid = variables.length <= max;
    }

    return {
      success: valid,
      errorCode: valid ? undefined : ValidatorErrorCode.MAX_VARIABLES,
    };
  };
};

export const dateInPastValidator = (value?: Date): ValidatorResponse => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const valid = !value || value.getTime() >= today.getTime();

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.DATE_IN_PAST,
  };
};

export const dateInFutureValidator = (value?: Date): ValidatorResponse => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const valid = !value || value.getTime() <= today.getTime();

  return {
    success: valid,
    errorCode: valid ? undefined : ValidatorErrorCode.DATE_IN_FUTURE,
  };
};

export const regexValidator = (reg: string | RegExp) => {
  return (value?: string): ValidatorResponse => {
    let valid = true;
    if (value) {
      valid = !!value.match(reg);
    }

    return {
      success: valid,
      errorCode: valid ? undefined : ValidatorErrorCode.REGEX,
    };
  };
};

import {Columns, SheetData} from 'write-excel-file';

export class ExcelSheet {
  constructor(
    readonly name: string,
    readonly data: SheetData,
  ) {}

  get columns(): Columns {
    const cols: Columns = [];

    this.data.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const values = (cell?.value as string)?.split('\n');
        const width = Math.max(...(values ?? []).map((value) => value?.length ?? 0)) + 1;

        if (!cols[cellIndex] || (cols[cellIndex]?.width ?? 0) < width) {
          cols[cellIndex] = {
            width,
          };
        }
      });
    });

    return cols;
  }

  get sheetName(): string {
    return this.name.replace(/\?/g, '_');
  }
}

const DefaultLightFontEot = require('@assets/fonts/E+H_SansLig.eot');
const DefaultLightFontWoff = require('@assets/fonts/E+H_SansLig.woff');
const DefaultLightFontTtf = require('@assets/fonts/E+H_SansLig.ttf');
const DefaultRegularFontEot = require('@assets/fonts/E+H_SansReg.eot');
const DefaultRegularFontWoff = require('@assets/fonts/E+H_SansReg.woff');
const DefaultRegularFontTtf = require('@assets/fonts/E+H_SansReg.ttf');
const DefaultBoldFontEot = require('@assets/fonts/E+H_SansDem.eot');
const DefaultBoldFontWoff = require('@assets/fonts/E+H_SansDem.woff');
const DefaultBoldFontTtf = require('@assets/fonts/E+H_SansDem.ttf');

export const DefaultFontName = 'E+H_Sans';

export const GenericFontsStyles = `
  @font-face {
    font-family: '${DefaultFontName}';
    src: url('${DefaultLightFontEot}') format('eot'), 
    url('${DefaultLightFontWoff}') format('woff'), 
    url('${DefaultLightFontTtf}') format('truetype');
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: '${DefaultFontName}';
    src: url('${DefaultRegularFontEot}') format('eot'), 
    url('${DefaultRegularFontWoff}') format('woff'), 
    url('${DefaultRegularFontTtf}') format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: '${DefaultFontName}';
    src: url('${DefaultBoldFontEot}') format('eot'), 
    url('${DefaultBoldFontWoff}') format('woff'), 
    url('${DefaultBoldFontTtf}') format('truetype');
    font-weight: 600;
    font-display: swap;
  }
`;

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const NotFoundIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 96 96'
    body={
      <>
        <rect
          y='1.41772'
          width='3.67265'
          height='32.0359'
          transform='matrix(-0.705349 0.70886 0.705349 0.70886 48.4932 54.2648)'
          fill='#E6ECF0'
          stroke='#8FA2AC'
          strokeWidth='2'
        />
        <path
          d='M14.9996 45.1925C14.9996 57.1731 24.7101 66.885 36.6883 66.885C48.6664 66.885 58.377 57.1731 58.377 45.1925C58.377 33.2119 48.6664 23.5 36.6883 23.5C24.7101 23.5 14.9996 33.2119 14.9996 45.1925Z'
          fill='white'
          stroke='#8FA2AC'
          strokeWidth='2'
        />
        <path d='M64.0547 13L71.6102 20.5556' stroke='#8FA2AC' strokeWidth='2.8' />
        <path d='M71.6104 13L64.0548 20.5556' stroke='#8FA2AC' strokeWidth='2.8' />
        <path d='M66.8896 31.8889L71.6119 36.6111' stroke='#8FA2AC' strokeWidth='2' />
        <path d='M71.6123 31.8891L66.8901 36.6113' stroke='#8FA2AC' strokeWidth='2' />
        <path d='M48 13L52.7222 17.7222' stroke='#8FA2AC' strokeWidth='2' />
        <path d='M52.7227 13L48.0004 17.7222' stroke='#8FA2AC' strokeWidth='2' />
      </>
    }
    {...props}
    color='transparent'
  />
);

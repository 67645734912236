import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const BasketIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 30 30'
    body={
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.46655 7.3146C5.46655 6.84516 5.84711 6.4646 6.31655 6.4646H8.13282C8.92912 6.4646 9.63608 6.97414 9.88789 7.72958L12.8147 16.5101C12.8352 16.5714 12.8925 16.6127 12.957 16.6127H20.818C20.8825 16.6127 20.9398 16.5714 20.9603 16.5101L22.8306 10.8991C22.863 10.8019 22.7907 10.7016 22.6883 10.7016H14.5619C14.0925 10.7016 13.7119 10.3211 13.7119 9.85163C13.7119 9.38219 14.0925 9.00163 14.5619 9.00163H22.6883C23.9511 9.00163 24.8427 10.2387 24.4434 11.4366L22.573 17.0477C22.3212 17.8032 21.6143 18.3127 20.818 18.3127H12.957C12.1607 18.3127 11.4538 17.8032 11.202 17.0477L8.27512 8.26717C8.25471 8.20591 8.19739 8.1646 8.13282 8.1646H6.31655C5.84711 8.1646 5.46655 7.78404 5.46655 7.3146ZM14.9433 21.7756C14.9433 22.7564 14.1481 23.5515 13.1673 23.5515C12.1865 23.5515 11.3914 22.7564 11.3914 21.7756C11.3914 20.7948 12.1865 19.9997 13.1673 19.9997C14.1481 19.9997 14.9433 20.7948 14.9433 21.7756ZM20.7786 23.5515C21.7594 23.5515 22.5545 22.7564 22.5545 21.7756C22.5545 20.7948 21.7594 19.9997 20.7786 19.9997C19.7978 19.9997 19.0027 20.7948 19.0027 21.7756C19.0027 22.7564 19.7978 23.5515 20.7786 23.5515Z'
      />
    }
    {...props}
  />
);

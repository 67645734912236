import * as React from 'react';
import {Context} from '@lib/App/Context';
import {generateTrackingViewBaseData, trackView} from '@lib/Utils';
import {SftSearch} from '@components/SftSearch';

interface AppProps {
  context: Context;
}

export class App extends React.Component<AppProps, {}> {
  componentDidMount() {
    const {
      context: {
        translator,
        searchService: {internal, appCall},
      },
    } = this.props;

    trackView({
      ...generateTrackingViewBaseData(translator, 'home', internal, 'sft.en.home', appCall),
    });
  }

  async componentWillUnmount() {
    const {context} = this.props;

    await context.destroy();
  }

  render() {
    const {
      context: {searchService, modalService, translator},
    } = this.props;

    return <SftSearch searchService={searchService} modalService={modalService} translator={translator} />;
  }
}

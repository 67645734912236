import * as React from 'react';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import {SearchFormFields} from '@lib/Form/Search';
import {ResultsType, SearchType} from '@lib/Search';
import {GRAY_COLOR, MD_SIZE, trackEvent} from '@lib/Utils';
import {ButtonType, IconButtonGeneric, PrimaryButton, PrimaryInvertedButton, Text, TextSize, TextWeight} from '@components/UI';
import {DatePicker, FormGroup, FormGroupOuter, InputField, Label} from '@components/Form';
import {Popover} from '@components/UI/Popover';
import {Results} from '@components/SftSearch/Results';
import {GenericSearchProps} from './shared';

const CYLINDER_IMAGE = require('@assets/cylinder.jpg').default;
const SENSOR_IMAGE = require('@assets/sensor.jpg').default;

interface SearchProps extends GenericSearchProps {}

@observer
export class Search extends React.Component<SearchProps, {}> {
  @observable private searchType?: SearchType;

  constructor(props: SearchProps) {
    super(props);

    makeObservable(this);
  }

  @action
  private onSearch = async (type: SearchType) => {
    const {searchService} = this.props;

    this.searchType = type;
    await searchService.onSearch(ResultsType.SEARCH_LIST, type);
  };

  @action
  private clearSearch = () => {
    const {searchService} = this.props;

    this.searchType = undefined;
    searchService.clearSearchResults();
  };

  private onFocusInput = async (type: SearchType) => {
    const {
      searchService: {searchForm, results},
    } = this.props;

    const orderCodeField = searchForm.fields[SearchFormFields.ORDER_CODE];
    const partNumberField = searchForm.fields[SearchFormFields.PART_NUMBER];

    switch (type) {
      case ResultsType.ORDER_CODE:
        if (!orderCodeField.value) {
          await this.onSearch(type);
        } else {
          this.clearSearch();
        }
        break;
      case ResultsType.PART_NUMBER:
        if (!partNumberField.value) {
          await this.onSearch(type);
        } else {
          this.clearSearch();
        }
        break;
    }
  };

  @action
  private onOrderCodeChange = async (v?: string) => {
    const {
      searchService: {searchForm},
    } = this.props;

    const orderCodeField = searchForm.fields[SearchFormFields.ORDER_CODE];

    orderCodeField.value = v;
    if (!v) {
      await this.onSearch(ResultsType.ORDER_CODE);
    } else {
      this.clearSearch();
    }
  };

  @action
  private onPartNumberChange = async (v?: string) => {
    const {
      searchService: {searchForm},
    } = this.props;

    const partNumberField = searchForm.fields[SearchFormFields.PART_NUMBER];

    partNumberField.value = v;
    if (!v) {
      await this.onSearch(ResultsType.PART_NUMBER);
    } else {
      this.clearSearch();
    }
  };

  private onFlyoutListRowClick = () => {
    this.clearSearch();
  };

  private onFlyoutListOutsideClick = (e: MouseEvent) => {
    this.clearSearch();
  };

  render() {
    const {
      translator,
      searchService: {searchForm, onSearch, searchResults},
    } = this.props;

    const orderCodeField = searchForm.fields[SearchFormFields.ORDER_CODE];
    const productionDateField = searchForm.fields[SearchFormFields.PRODUCTION_DATE];
    const partNumberField = searchForm.fields[SearchFormFields.PART_NUMBER];

    return (
      <Container>
        <BoxesContainer>
          <Box
            onSubmit={(e) => {
              e.preventDefault();
              onSearch(ResultsType.ORDER_CODE);
            }}
          >
            <InlineItemsContainer>
              <TextInputField
                label={`${translator.translate('Order code / product root / serial number')}:`}
                value={orderCodeField.value ?? ''}
                onFocus={() => this.onFocusInput(ResultsType.ORDER_CODE)}
                onChange={this.onOrderCodeChange}
                allowClear
                inputProps={{
                  maxLength: 50,
                }}
              />
              <Popover
                initiator={<FakeIconButton>?</FakeIconButton>}
                onOpen={() => trackEvent({event_name: 'info_icon', event_linkname: 'search_info', event_id: (this.props.searchService.results===undefined? 'sft.en.home.info_icon.search_info':'sft.en.search result.successful.info_icon.search_info')})}
                closeOnClickOutside
              >
                <PopoverInnerContainer>
                  <PopoverTitle size={TextSize.LARGE} weight={TextWeight.BOLD} paragraph>
                    {translator.translate('Search spare parts')}
                  </PopoverTitle>
                  {translator.translate(
                    'Which spare parts are available for my product? Filling in the order code (e.g. FTC260-AA2A1 or PMC51-BA21JB1CGBTDJB) or the product id (e.g. FTC260 or 83F50) shows the relevant spare parts.',
                  )}
                  <PopoverImage src={CYLINDER_IMAGE} />
                </PopoverInnerContainer>
              </Popover>
              {this.searchType === ResultsType.ORDER_CODE && searchResults && (
                <FlyoutSearchResultsContainer $position={FlyoutSearchResultsPosition.LEFT}>
                  <OutsideClickHandler onOutsideClick={this.onFlyoutListOutsideClick}>
                    <FlyoutSearchResults {...this.props} onRowClick={this.onFlyoutListRowClick} results={searchResults} />
                  </OutsideClickHandler>
                </FlyoutSearchResultsContainer>
              )}
            </InlineItemsContainer>
            <FormGroup>
              <Label>{translator.translate('Production date')}</Label>
              <InlineItemsContainer>
                <DatePicker
                  placeholder=''
                  translator={translator}
                  value={productionDateField.value}
                  onChange={(v) => (productionDateField.value = v)}
                  allowClear
                />
                <OrderCodeSearchButton
                  type={ButtonType.SUBMIT}
                  onClick={() => {
                    trackEvent({event_name: 'search', event_linkname: 'Search', event_order_code: orderCodeField.value, event_id: 'sft.en.search result.successful.search.Search'});
                  }}
                >
                  {translator.translate('Search')}
                </OrderCodeSearchButton>
              </InlineItemsContainer>
            </FormGroup>
          </Box>
          <Box
            onSubmit={(e) => {
              e.preventDefault();
              onSearch(ResultsType.PART_NUMBER);
            }}
          >
            <InlineItemsContainer>
              <TextInputField
                label={`${translator.translate('Spare part number')}:`}
                value={partNumberField.value ?? ''}
                onFocus={() => this.onFocusInput(ResultsType.PART_NUMBER)}
                onChange={this.onPartNumberChange}
                allowClear
                inputProps={{
                  maxLength: 50,
                }}
              />
              <Popover
                initiator={<FakeIconButton>?</FakeIconButton>}
                closeOnClickOutside
                onOpen={() => trackEvent({event_name: 'info_icon', event_linkname: 'search_info', event_id:  (this.props.searchService.results===undefined? 'sft.en.home.info_icon.search_info':'sft.en.search result.successful.info_icon.search_info')})}
              >
                <PopoverInnerContainer>
                  <PopoverTitle size={TextSize.LARGE} weight={TextWeight.BOLD} paragraph>
                    {translator.translate('Search products')}
                  </PopoverTitle>
                  {translator.translate(
                    'For which product(s) can my spare part be used? Filling in the spare part order code (e.g. 71002865 or XPD0024) shows the relevant devices this spare part can be used in.',
                  )}
                  <PopoverImage src={SENSOR_IMAGE} />
                </PopoverInnerContainer>
              </Popover>
              {this.searchType === ResultsType.PART_NUMBER && searchResults && (
                <FlyoutSearchResultsContainer $position={FlyoutSearchResultsPosition.RIGHT}>
                  <OutsideClickHandler onOutsideClick={this.onFlyoutListOutsideClick}>
                    <FlyoutSearchResults {...this.props} onRowClick={this.onFlyoutListRowClick} results={searchResults} />
                  </OutsideClickHandler>
                </FlyoutSearchResultsContainer>
              )}
            </InlineItemsContainer>
            <PartNumberSearchButton
              type={ButtonType.SUBMIT}
              onClick={() => {
                trackEvent({event_name: 'search', event_linkname: 'Search', event_order_code: partNumberField.value, event_id: 'sft.en.search result.successful.search.Search'});
              }}
            >
              {translator.translate('Search')}
            </PartNumberSearchButton>
          </Box>
        </BoxesContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media all and (min-width: ${MD_SIZE}px) {
    flex-direction: row;
  }
`;

const Box = styled.form`
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  background-color: ${GRAY_COLOR};
  padding: 12px;
  ${PrimaryButton} {
    align-self: flex-start;
  }
  ${FormGroupOuter} {
    margin: 0;
  }
  @media all and (min-width: ${MD_SIZE}px) {
    width: 50%;
  }
`;

const TextInputField = styled(InputField)`
  && {
    width: 100%;
  }
`;

const InlineItemsContainer = styled.div`
  position: relative;
  display: flex;
  grid-gap: 12px;
  width: 100%;
  > .btn {
    margin: auto 0 0 0;
  }
`;

const GenericSearchButtonStyles = `
  align-self: flex-end;
  margin: 0 0 0 auto;
`;

const OrderCodeSearchButton = styled(PrimaryButton)`
  && {
    ${GenericSearchButtonStyles}
  }
`;

const PartNumberSearchButton = styled(PrimaryInvertedButton)`
  && {
    ${GenericSearchButtonStyles};
    margin: auto 0 0 auto;
  }
`;

const FakeIconButton = styled.span`
  ${IconButtonGeneric};
  align-self: flex-end;
  margin: 0 0 1px 0;
  font-weight: 600;
`;

const PopoverTitle = styled(Text)`
  && {
    display: flex;
    align-items: center;
    height: 32px;
    background-color: ${GRAY_COLOR};
    padding: 4px 8px;
    margin: -8px -8px 0 -8px;
  }
`;

const PopoverInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  white-space: break-spaces;
  padding: 8px;
`;

const PopoverImage = styled.img`
  display: block;
  max-width: 200px;
  height: auto;
  margin: 0 auto;
`;

interface FlyoutSearchResultsContainerProps {
  $position: FlyoutSearchResultsPosition;
}

enum FlyoutSearchResultsPosition {
  LEFT = 1,
  RIGHT,
}

const FlyoutSearchResultsContainer = styled.div<FlyoutSearchResultsContainerProps>`
  position: absolute;
  z-index: 99;
  ${({$position}: FlyoutSearchResultsContainerProps) =>
    $position === FlyoutSearchResultsPosition.RIGHT
      ? `
      right: 0;
    `
      : `
      left: 0;
    `}
  top: calc(100% + 16px);
`;

const FlyoutSearchResults = styled(Results)`
  && {
    width: auto;
    max-width: 600px;
    height: auto;
    background-color: #ffffff;
    padding: 16px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  }
`;

import * as React from 'react';
import styled from 'styled-components';

export interface IconGeneratorProps extends IconProps {
  body: React.ReactNode;
}

export interface IconProps {
  x?: number;
  y?: number;
  viewBox?: string;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export class IconGenerator extends React.Component<IconGeneratorProps, {}> {
  private get clickable(): boolean {
    const {onClick} = this.props;

    return !!onClick;
  }

  private handleClick = (e: React.MouseEvent): void => {
    const {onClick} = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  private handleMouseEnter = (): void => {
    const {onMouseEnter} = this.props;

    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  private handleMouseLeave = (): void => {
    const {onMouseLeave} = this.props;

    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  render() {
    const {x = 0, y = 0, viewBox, size = 1, color, body, style, className = ''} = this.props;

    const baseStyle = {
      width: 16 * (size || 1),
      height: 16 * (size || 1),
      fill: color || '#000',
    };

    return (
      <StyledSvg
        style={{...baseStyle, ...style}}
        viewBox={viewBox || '0 0 16 16'}
        className={['icon', className].join(' ')}
        $clickable={this.clickable}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {body}
      </StyledSvg>
    );
  }
}

interface StyledSvgProps {
  $clickable: boolean;
}

const StyledSvg = styled.svg<StyledSvgProps>`
  transition: fill 0.2s ease-in;
  ${({$clickable}: StyledSvgProps) =>
    $clickable
      ? `
    cursor: pointer;
  `
      : ''}
`;

import * as React from 'react';
import {observer} from 'mobx-react';
import {action, computed, makeObservable, observable} from 'mobx';
import * as ReactDOM from 'react-dom';
import {PickerBody, PickerFooter, PickerHeader} from '.';
import {DatePickerService} from '../lib';
import {Translator} from '@lib/Translation';

export enum PickerMode {
  DAY = 'day',
  MONTH = 'month',
  YEARS = 'years',
}

interface PickerPopupProps {
  showDatePicker: boolean;
  showTime?: boolean;
  closePicker: () => void;
  bindValue?: Date;
  save: (date?: Date) => void;
  gmtText?: string;
  allowClear?: boolean;
  translator: Translator;
}

@observer
export class PickerPopup extends React.Component<PickerPopupProps, {}> {
  @observable pickerMode: PickerMode = PickerMode.DAY;
  @observable newDatePickerService: DatePickerService = new DatePickerService(
    // eslint-disable-next-line react/destructuring-assignment
    this.props.bindValue || new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    undefined,
    // eslint-disable-next-line react/destructuring-assignment
    !!this.props.bindValue,
  );

  private pickerRoot?: HTMLDivElement;
  private el: HTMLDivElement;
  private wrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: PickerPopupProps) {
    super(props);

    let root: HTMLDivElement | null = document.querySelector('.bfdp-root');
    if (!root) {
      root = document.createElement('div');
      root.classList.add('bfdp-root');
    }
    document.body.appendChild(root);
    this.pickerRoot = root;

    this.el = document.createElement('div');
    this.el.classList.add('bfdp-input');
    this.el.classList.add('popup-whole-page-container');

    makeObservable(this);
  }

  componentDidMount() {
    if (this.pickerRoot) {
      this.pickerRoot.appendChild(this.el);
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    this.el?.remove();
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: Event) => {
    if (this.pickerRoot) {
      const el = this.pickerRoot.firstChild;
      if (el && event.target) {
        const target = event.target as HTMLElement;
        const containsClass = target.classList.contains('popup-whole-page-container');
        if (!el.contains(event.target as Element) || containsClass) {
          this.close();
        }
      }
    }
  };

  @computed get headerLabel() {
    const {newDatePickerService} = this;
    const {translator} = this.props;

    switch (this.pickerMode) {
      case PickerMode.DAY:
        return new Intl.DateTimeFormat(translator.activeLocale, {
          month: 'long',
          year: 'numeric',
        }).format(newDatePickerService.newDateYearAndMonth);
      case PickerMode.MONTH:
        return new Intl.DateTimeFormat(translator.activeLocale, {
          year: 'numeric',
        }).format(newDatePickerService.newDateYearAndMonth);
      case PickerMode.YEARS:
        const before = new Date(newDatePickerService.newDateYearAndMonth.toISOString());
        before.setFullYear(before.getFullYear() - 10);

        return `${new Intl.DateTimeFormat(translator.activeLocale, {
          year: 'numeric',
        }).format(before)} - ${new Intl.DateTimeFormat(translator.activeLocale, {
          year: 'numeric',
        }).format(newDatePickerService.newDateYearAndMonth)}`;
    }
  }

  save = () => {
    const {save, closePicker} = this.props;

    save(this.newDatePickerService.newDate);
    closePicker();
  };

  close = () => {
    const {closePicker} = this.props;

    closePicker();
  };

  @action
  togglePickerMode = () => {
    switch (this.pickerMode) {
      case PickerMode.DAY:
        this.pickerMode = PickerMode.YEARS;
        break;
      case PickerMode.YEARS:
        this.pickerMode = PickerMode.MONTH;
        break;
      case PickerMode.MONTH:
        this.pickerMode = PickerMode.DAY;
        break;
    }
  };

  @action
  changePickerMode = (pickerMode: PickerMode) => {
    this.pickerMode = pickerMode;
  };

  @action
  next = () => {
    const {newDatePickerService} = this;

    switch (this.pickerMode) {
      case PickerMode.DAY:
        newDatePickerService.nextCalendarMonth();
        break;
      case PickerMode.MONTH:
        newDatePickerService.nextCalendarYear();
        break;
      case PickerMode.YEARS:
        newDatePickerService.nextCalendarYearDecade();
        break;
    }
  };

  @action
  prev = () => {
    const {newDatePickerService} = this;

    switch (this.pickerMode) {
      case PickerMode.DAY:
        newDatePickerService.prevCalendarMonth();
        break;
      case PickerMode.MONTH:
        newDatePickerService.prevCalendarYear();
        break;
      case PickerMode.YEARS:
        newDatePickerService.prevCalendarYearDecade();
        break;
    }
  };

  render() {
    const {showDatePicker, closePicker, bindValue, gmtText, showTime, allowClear, save, translator} = this.props;

    return ReactDOM.createPortal(
      <div className={`bfdp-popup${showDatePicker ? ' bfdp-popup-open' : ''}`} ref={this.wrapperRef}>
        <div>
          <PickerHeader next={this.next} prev={this.prev} togglePickerMode={this.togglePickerMode} label={this.headerLabel} />
          <PickerBody
            translator={translator}
            save={this.save}
            bindValue={bindValue}
            togglePickerMode={this.togglePickerMode}
            pickerMode={this.pickerMode}
            newDatePickerService={this.newDatePickerService}
            gmtText={gmtText}
            showTime={showTime}
          />
          {this.pickerMode === PickerMode.DAY && (
            <PickerFooter onClose={this.close} onSave={this.save} onClear={allowClear ? () => save(undefined) : undefined} translator={translator} />
          )}
        </div>
      </div>,
      this.el,
    );
  }
}

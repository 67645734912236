import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const CaretDownIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 8 6'
    body={
      <path d='M7.48177 0.777349L4.41603 5.37596C4.21811 5.67283 3.78189 5.67283 3.58397 5.37596L0.518234 0.777349C0.296716 0.445072 0.534911 -6.52619e-07 0.934259 -6.17707e-07L7.06574 -8.16755e-08C7.46509 -4.67634e-08 7.70329 0.445072 7.48177 0.777349Z' />
    }
    {...props}
  />
);

import * as React from 'react';
import {computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Row_} from 'write-excel-file';
import {OrderCodeCumulatedData, OrderCodeCumulatedStatusType} from '@lib/Api';
import {DateFormatString, parseDate} from '@lib/Utils';
import {Text} from '@components/UI';
import {Datatable, DatatableColumnData} from '@components/Datatable';
import {OrderCodeResultsRenderer, OrderCodeResultsRendererProps} from './OrderCodeResultsRenderer';

interface OrderCodeCumulatedSelectableData extends OrderCodeCumulatedData {
  id?: string;
}

interface CumulatedResultsProps extends OrderCodeResultsRendererProps {
  data: OrderCodeCumulatedData[];
}

@observer
export class CumulatedResults extends OrderCodeResultsRenderer<CumulatedResultsProps> {
  @observable protected readonly orderCodes: OrderCodeCumulatedSelectableData[] = [];
  protected readonly orderCodesDatatableRef: React.RefObject<Datatable<OrderCodeCumulatedSelectableData>> = React.createRef();

  constructor(props: CumulatedResultsProps) {
    super(props);

    const {data} = props;
    this.orderCodes.length = 0;
    this.orderCodes.push(
      ...data.map((orderCode) => ({
        ...orderCode,
        id: orderCode.status === OrderCodeCumulatedStatusType.FOUND ? orderCode.orderCode : undefined,
      })),
    );

    makeObservable(this);
  }

  protected get columns() {
    const {translator} = this.props;

    const columns = [...super.columns];

    const descriptionColumn = columns.find((column) => column.key === 'label');
    if (descriptionColumn) {
      descriptionColumn.width = descriptionColumn.width - 5;
    }

    columns.push({
      width: 5,
      key: 'tags',
      label: translator.translate('Tag'),
      searchable: true,
      render: (row) => <WrappedText paragraph>{row.tags.join('\n')}</WrappedText>,
    });

    return columns;
  }

  protected get additionallySelectedOrderCodes(): string[] {
    return [...(this.orderCodesDatatableRef.current?.checkedIds ?? [])];
  }

  protected get orderCodesColumns(): DatatableColumnData<OrderCodeCumulatedData>[] {
    const {translator} = this.props;

    return [
      {
        width: 35,
        label: translator.translate('Order code'),
        key: 'orderCode',
        sortable: true,
      },
      {
        width: 45,
        label: translator.translate('Description'),
        key: 'label',
        sortable: true,
      },
      {
        width: 10,
        label: translator.translate('Tag'),
        key: 'tag',
      },
    ];
  }

  protected get resultsDatatableName() {
    return 'cumulatedRows';
  }

  protected get exportFileName() {
    const now = new Date();
    return `cumulated-${parseDate(DateFormatString.D, now)}.xlsx`;
  }

  protected get exportTitleRows(): Row_<string>[] {
    const {data, translator} = this.props;

    return [
      [
        {
          value: translator.translate('Order code'),
          fontWeight: 'bold',
        },
        {
          value: translator.translate('Description'),
          fontWeight: 'bold',
        },
        {
          value: translator.translate('Tag'),
          fontWeight: 'bold',
        },
      ],
      ...data.map(({orderCode, label, tag}) => [
        {
          value: orderCode,
        },
        {
          value: label,
        },
        {
          value: tag,
        },
      ]),
    ];
  }

  @computed
  protected get renderHeader(): React.ReactNode {
    const {translator, data} = this.props;

    return (
      <Datatable
        ref={this.orderCodesDatatableRef}
        name='cumulatedOrderCodes'
        translator={translator}
        columns={this.orderCodesColumns}
        rows={this.orderCodes}
        minWidth={940}
        selectable
        resizable
        writeable
      />
    );
  }
}

const WrappedText = styled(Text)`
  && {
    white-space: break-spaces;
  }
`;

import * as React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import {ModalConfig} from '@lib/Modal';
import {GRAY_COLOR, MD_SIZE} from '@lib/Utils';
import {DefaultGenericStyles} from '@components/UI/Styles';
import {ModalCloseButton} from './shared';

export interface ModalProps {
  config?: ModalConfig;
  onClose?: () => void;
  show?: boolean;
  className?: string;
  customBody?: React.ReactNode;
  children?: React.ReactNode;
}

@observer
export class Modal extends React.Component<ModalProps, {}> {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  private onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        this.onEscapeClick(e);
        break;
    }
  };

  private onEscapeClick = (e: KeyboardEvent) => {
    const {show} = this.props;

    if (!show) {
      return;
    }

    e.stopImmediatePropagation();
    this.handleClose();
  };

  private onOutsideClick = (): void => {
    this.handleClose();
  };

  private handleClose = () => {
    const {onClose, config} = this.props;

    const closeOnOutsideClick = config?.closeOnOutsideClick ?? true;

    if (onClose && closeOnOutsideClick) {
      onClose();
    }
  };

  render() {
    const {show = false, config, className, children, customBody, onClose} = this.props;

    return (
      <Container $show={show} className={className}>
        {customBody ||
          (children && (
            <ModalBody config={config} onOutsideClick={this.onOutsideClick}>
              {onClose && <ModalCloseButton onClick={onClose} />}
              {config?.title && <TitleContainer>{config.title}</TitleContainer>}
              {children}
            </ModalBody>
          )) ||
          null}
      </Container>
    );
  }
}

const TitleContainer = styled.div`
  && {
    background-color: ${GRAY_COLOR};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 12px;
    padding: 4px 8px;
    width: 100%;
    @media all and (min-width: ${MD_SIZE}px) {
      flex-wrap: nowrap;
      height: 32px;
    }
    div {
      padding: 0;
    }
  }
`;

interface ModalBodyProps {
  children?: React.ReactNode;
  onOutsideClick: () => void;
  config?: ModalConfig;
  className?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
}

export const ModalBody = observer(({children, onOutsideClick, className, innerRef}: ModalBodyProps) => (
  <OuterContainer className={['modal-outer-container', className].join(' ')}>
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <InnerContainer ref={innerRef} className='modal-inner-container'>
        {children}
      </InnerContainer>
    </OutsideClickHandler>
  </OuterContainer>
));

interface ContainerProps {
  $show: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: ${({$show}: ContainerProps) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
  padding: 0 25px;
  transform-style: preserve-3d;
  transform: translate(0, 0);
  overflow: auto;
  ${DefaultGenericStyles};
`;

const OuterContainer = styled.div`
  width: 100%;
  margin: auto;
  max-height: 100%;
  overflow: auto;
  @media all and (min-width: ${MD_SIZE}px) {
    max-width: 660px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.13);
`;

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const InfoIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 30 30'
    body={
      <>
        <circle cx='15.3755' cy='15' r='7.75' stroke={props.color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M16.1394 12.7778C16.753 12.7778 17.2505 12.2803 17.2505 11.6667C17.2505 11.053 16.753 10.5555 16.1394 10.5555C15.5257 10.5555 15.0283 11.053 15.0283 11.6667C15.0283 12.2803 15.5257 12.7778 16.1394 12.7778Z'
          fill={props.color}
        />
        <path
          d='M17.2756 17.286C17.2449 17.2653 17.2104 17.251 17.1741 17.2439C17.1378 17.2368 17.1004 17.237 17.0642 17.2445C17.028 17.252 16.9936 17.2666 16.9631 17.2876C16.9326 17.3086 16.9067 17.3354 16.8867 17.3665C16.6182 17.7642 16.2892 18.1175 15.9117 18.4138C15.82 18.4832 15.4784 18.7471 15.3339 18.6915C15.2339 18.661 15.2922 18.4638 15.3117 18.3804L15.4589 17.9443C15.52 17.7665 16.5839 14.611 16.7006 14.2499C16.8728 13.7221 16.7978 13.2026 16.0117 13.3276C15.7978 13.3499 13.6284 13.6304 13.5895 13.6332C13.553 13.6356 13.5173 13.6451 13.4845 13.6613C13.4518 13.6774 13.4225 13.6999 13.3983 13.7273C13.3742 13.7548 13.3558 13.7868 13.344 13.8214C13.3322 13.856 13.3274 13.8926 13.3297 13.929C13.3321 13.9655 13.3417 14.0012 13.3578 14.034C13.374 14.0668 13.3964 14.0961 13.4239 14.1202C13.4513 14.1443 13.4833 14.1628 13.5179 14.1745C13.5525 14.1863 13.5891 14.1911 13.6256 14.1888C13.6256 14.1888 14.4589 14.0804 14.5506 14.0721C14.5975 14.0675 14.6448 14.0766 14.6867 14.0983C14.7286 14.12 14.7633 14.1533 14.7867 14.1943C14.837 14.3499 14.8301 14.5184 14.7672 14.6693C14.695 14.9471 13.5534 18.1638 13.5172 18.3499C13.4785 18.5056 13.4893 18.6695 13.548 18.8189C13.6068 18.9682 13.7106 19.0956 13.845 19.1832C14.0974 19.3514 14.3979 19.4324 14.7006 19.4138C14.9949 19.4102 15.286 19.3518 15.5589 19.2415C16.2506 18.9638 16.9728 18.2249 17.3534 17.6443C17.3861 17.5853 17.3963 17.5164 17.382 17.4505C17.3677 17.3845 17.3299 17.3261 17.2756 17.286Z'
          fill={props.color}
        />
      </>
    }
    {...props}
    color='transparent'
  />
);

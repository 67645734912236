import * as React from 'react';
import {observer} from 'mobx-react';
import {DatePickerInputProps, InputHour, InputMonth} from '.';

interface InputYearProps extends DatePickerInputProps {
  year: string;
  setYear: (value: string) => void;
  inputMonthRef: React.RefObject<InputMonth>;
  inputHourRef?: React.RefObject<InputHour>;
  onPaste: (e: React.ClipboardEvent) => void;
}

@observer
export class InputYear extends React.Component<InputYearProps, {}> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  onChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {setYear, inputHourRef, datePickerService} = this.props;
    setYear(e.currentTarget.value);
    const year = parseInt(datePickerService.year.replace(/[^0-9]/g, ''), 10);
    if (inputHourRef && inputHourRef.current && inputHourRef.current.inputRef.current && year > 1900) {
      inputHourRef.current.inputRef.current.focus();
    }
  };

  onKeyDownYear = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {inputHourRef, inputMonthRef, datePickerService} = this.props;
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        datePickerService.incrementYear();
        break;
      case 'ArrowDown':
        e.preventDefault();
        datePickerService.decrementYear();
        break;
      case 'ArrowRight':
        if (this.inputRef.current && inputHourRef && inputHourRef.current && inputHourRef.current.inputRef.current) {
          if (this.inputRef.current.selectionEnd === 4) {
            inputHourRef.current.inputRef.current.focus();
          }
        }
        break;
      case 'ArrowLeft':
        if (this.inputRef.current && inputMonthRef.current && inputMonthRef.current.inputRef.current) {
          if (this.inputRef.current.selectionEnd === 4) {
            inputMonthRef.current.inputRef.current.focus();
          }
        }
        break;
      default:
    }
  };

  onFocus = () => {
    const {onFocus} = this.props;
    if (this.inputRef.current) {
      this.inputRef.current.select();
    }
    onFocus();
  };

  onBlur = () => {
    const {onBlur, datePickerService, setYear} = this.props;
    const year = parseInt(datePickerService.year.replace(/[^0-9]/g, ''), 10);
    if (year < 1900) {
      setYear('1900');
    }
    onBlur();
  };

  render() {
    const {readOnly, year, onPaste} = this.props;

    return (
      <input
        ref={this.inputRef}
        className='bfdp-input-long'
        value={year}
        onChange={this.onChangeYear}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        disabled={readOnly}
        onKeyDown={this.onKeyDownYear}
        onPaste={onPaste}
      />
    );
  }
}

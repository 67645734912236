import React from 'react';
import {action, computed, makeObservable, observable} from 'mobx';
import styled from 'styled-components';
import writeXlsxFile, {Row_} from 'write-excel-file';
import {
  DrawingGroupKey,
  DrawingGroupKeyType,
  OrderCodeConfigurationData,
  OrderCodeDataType,
  OrderCodeRowData,
  OrderCodeRowFileData,
  PartClass,
  partClassToLabel,
  serviceSegmentationToLabel,
  ServiceSegmentationType,
} from '@lib/Api';
import {PRIMARY_COLOR, SECONDARY_COLOR, slugify, trackEvent} from '@lib/Utils';
import {ExcelSheet} from '@lib/Excel';
import {
  AccordionItem,
  ComponentWithTooltip,
  PrimaryButton,
  PrimaryInvertedButton,
  TertiaryButton,
  Text,
  TextProps,
  TextSize,
  TextStyle,
  TextWeight,
} from '@components/UI';
import {Datatable, DatatableActionsContainer, DatatableColumnData} from '@components/Datatable';
import {CheckboxGroupField, CheckboxGroupItem, CheckboxGroupItemPredefinedId} from '@components/Form';
import {BasketIcon, DocPdfIcon, DownloadIcon} from '@components/Icons';
import {DefaultModalContainer} from '@components/Modal';
import {GenericSearchProps} from '../../shared';
import {Col, Row} from './shared';
import {OrderCodeConfigurationsModal} from './OrderCodeConfigurationsModal';

const RecommendedSparePartsId = 'recommended';

enum FilterKey {
  GROUPS = 'groups',
  PART_CLASSES = 'partClasses',
  RECOMMENDED_SPARE_PARTS = 'recommendedSpareParts',
}

interface OrderCodeRowDatatableData {
  id: string;
  identifier: string;
  selectable: boolean;
  orderCode: string;
  configuration: OrderCodeConfigurationData[];
  label: string;
  recommendation: boolean;
  category?: PartClass;
  serviceSegmentation?: ServiceSegmentationType;
  positionInDrawing: string;
  drawingGroupKey?: DrawingGroupKeyType;
  drawingGroupLabel?: string;
  validFrom?: string;
  validUntil?: string;
  validPretty: string[];
  compatibilityInformation: OrderCodeRowFileData[];
  installationManuals: OrderCodeRowFileData[];
  quoteTexts: string[];
  infoTexts: string[];
  tags: string[];
}

export interface OrderCodeResultsRendererProps extends GenericSearchProps {
  data: OrderCodeDataType;
  rows: OrderCodeRowData[];
}

export abstract class OrderCodeResultsRenderer<T extends OrderCodeResultsRendererProps> extends React.Component<T, {}> {
  @observable protected filters: {[key in FilterKey]?: string[]} = {
    [FilterKey.GROUPS]: [CheckboxGroupItemPredefinedId.ALL],
    [FilterKey.PART_CLASSES]: [CheckboxGroupItemPredefinedId.ALL],
  };
  @observable protected readonly rows: OrderCodeRowData[] = [];
  protected readonly datatableRef: React.RefObject<Datatable<OrderCodeRowDatatableData>> = React.createRef();

  protected constructor(props: T) {
    super(props);

    const {rows} = props;

    this.rows.length = 0;
    this.rows.push(...rows);

    makeObservable(this);
  }

  protected onShowConfiguration = (orderCode: string, configuration: OrderCodeConfigurationData[]) => {
    const {translator, modalService} = this.props;

    modalService.show(<OrderCodeConfigurationsModal configuration={configuration} translator={translator} />, {
      title: orderCode,
    });
  };

  protected onOpenUrlInNewWindow = (e: React.MouseEvent, row: OrderCodeRowDatatableData, url: string) => {
    const {data} = this.props;

    e.preventDefault();
    trackEvent({
      event_name: 'download',
      event_linkname: 'icon_table',
      event_order_code: row.orderCode,
      event_sft_for: Array.isArray(data) ? data.map((x) => x.productRoot).join(',') : data.productRoot,
      event_id: 'sft.en.search result.successful.download.icon_table',
    });
    window.open(url, 'document', `width=${window.innerWidth / 2},height=${window.innerHeight / 1.5}`);
  };

  protected onAddSelectionToCart = async () => {
    const {searchService} = this.props;

    const checkedIds = [...(this.datatableRef.current?.checkedIds ?? [])];
    const orderCodesToOrder = [
      ...this.rows.filter((row) => checkedIds.indexOf(row.id) > -1).map((row) => row.orderCode),
      ...this.additionallySelectedOrderCodes,
    ];

    if (!orderCodesToOrder.length) {
      this.showAddToCartWarning();
    } else {
      await searchService.onAddToCart(orderCodesToOrder);
    }
  };

  protected showAddToCartWarning = () => {
    const {modalService, translator} = this.props;

    modalService.show(
      <DefaultModalContainer>
        <Text size={TextSize.LARGE} weight={TextWeight.LIGHT} paragraph>
          {translator.translate('There are no items selected. Please use the checkboxes to make your selection')}
        </Text>
        <TertiaryButton onClick={modalService.hide}>{translator.translate('Ok')}</TertiaryButton>
      </DefaultModalContainer>,
      {
        title: translator.translate('Warning'),
      },
    );
  };

  protected async onExport() {
    const {translator, data} = this.props;

    const fileName = this.exportFileName;
    const rowsToExport: OrderCodeRowDatatableData[] = [...(this.datatableRef.current?.filteredSortedRows ?? [])];

    const sheets: ExcelSheet[] = [];

    sheets.push(
      new ExcelSheet(translator.translate('Spare part list'), [
        [],
        ...this.exportTitleRows,
        [],
        [
          {
            value: translator.translate('Order code'),
            fontWeight: 'bold',
          },
          {
            value: translator.translate('Description'),
            fontWeight: 'bold',
          },
          {
            value: translator.translate('Rec'),
            fontWeight: 'bold',
          },
          {
            value: translator.translate('Tk'),
            fontWeight: 'bold',
          },
          {
            value: translator.translate('Pos'),
            fontWeight: 'bold',
          },
          {
            value: translator.translate('Group'),
            fontWeight: 'bold',
          },
          {
            value: translator.translate('Valid'),
            fontWeight: 'bold',
          },
        ],
        ...rowsToExport.map((row) => [
          {
            value: row.orderCode,
          },
          {
            value: [row.label?.trim(), ...row.quoteTexts.map((text) => text?.trim())].join(', '),
          },
          {
            value: row.recommendation ? '+' : undefined,
          },
          {
            value: row.category,
          },
          {
            value: row.positionInDrawing,
          },
          {
            value: row.drawingGroupLabel,
          },
          {
            value: [row.validFrom ? `> ${row.validFrom}` : undefined, row.validUntil ? `< ${row.validUntil}` : undefined]
              .filter((v) => !!v)
              .join(', '),
          },
        ]),
      ]),
    );

    this.onExportPreRowsDecorator(sheets);

    rowsToExport.forEach((row) => {
      if (row.configuration?.length) {
        const sheet = this.createConfigurationExcelSheet(row.orderCode || slugify(row.label), row.configuration);
        if (sheets.findIndex((s) => s.sheetName === sheet.sheetName) === -1) {
          sheets.push(sheet);
        }
      }
    });

    await writeXlsxFile([...sheets.map((sheet) => sheet.data)], {
      columns: [...sheets.map((sheet) => sheet.columns)],
      sheets: [...sheets.map((sheet) => sheet.sheetName)],
      fileName,
    });
  }

  protected onExportPreRowsDecorator(sheets: ExcelSheet[]) {}

  protected createConfigurationExcelSheet = (orderCode: string, configuration: OrderCodeConfigurationData[]): ExcelSheet => {
    const {translator} = this.props;

    const data: Row_<String>[] = [];
    configuration.forEach((config, configIndex) => {
      config.options.forEach((option, optionIndex) => {
        data.push([
          {
            value: optionIndex === 0 ? config.label : '',
          },
          {
            value: option.value,
          },
          {
            value: option.label,
          },
        ]);
      });
    });

    return new ExcelSheet(orderCode, [
      [],
      [],
      [
        {
          value: orderCode,
          fontWeight: 'bold',
        },
      ],
      [],
      [
        {
          value: translator.translate('Characteristic'),
          fontWeight: 'bold',
        },
        {
          value: translator.translate('Value'),
          fontWeight: 'bold',
        },
        {
          value: translator.translate('Description'),
          fontWeight: 'bold',
        },
      ],
      ...data,
    ]);
  };

  @action
  protected onToggleFilter = (key: FilterKey, id: string) => {
    let checkedIds = this.filters[key];
    if (!checkedIds) {
      checkedIds = observable([]);
      this.filters[key] = checkedIds;
    }

    const allChecked = (checkedIds ?? []).indexOf(CheckboxGroupItemPredefinedId.ALL) > -1;
    if (id === CheckboxGroupItemPredefinedId.ALL) {
      checkedIds.length = 0;
      if (!allChecked) {
        checkedIds.push(CheckboxGroupItemPredefinedId.ALL);
      }
    } else {
      if (allChecked) {
        checkedIds.splice(checkedIds.indexOf(CheckboxGroupItemPredefinedId.ALL), 1);
      }

      const indexToDelete = checkedIds.findIndex((checkedId) => checkedId === id);
      if (indexToDelete === -1) {
        checkedIds.push(id);
      } else {
        checkedIds.splice(indexToDelete, 1);
      }
    }
  };

  @computed
  protected get filteredRows(): OrderCodeRowDatatableData[] {
    let rows: OrderCodeRowData[] = [...this.rows];

    if (Object.keys(this.filters).length) {
      const groupsCheckedIds = this.filters[FilterKey.GROUPS];
      if (groupsCheckedIds && groupsCheckedIds.indexOf(CheckboxGroupItemPredefinedId.ALL) === -1) {
        rows = rows.filter((row) => row.drawingGroup && groupsCheckedIds.indexOf(row.drawingGroup.key) > -1);
      }
      const partClassesCheckedIds = this.filters[FilterKey.PART_CLASSES];
      if (partClassesCheckedIds && partClassesCheckedIds.indexOf(CheckboxGroupItemPredefinedId.ALL) === -1) {
        rows = rows.filter((row) => row.category && partClassesCheckedIds.indexOf(row.category.code) > -1);
      }
      const recommendedSparePartsCheckedIds = this.filters[FilterKey.RECOMMENDED_SPARE_PARTS];
      if (recommendedSparePartsCheckedIds) {
        const showOnlyRecommended = recommendedSparePartsCheckedIds.indexOf(RecommendedSparePartsId) > -1;
        if (showOnlyRecommended) {
          rows = rows.filter((row) => row.recommendation);
        }
      }
    }

    return rows.map(
      (row) =>
        ({
          id: [DrawingGroupKey.INFO].indexOf(row.drawingGroup?.key as DrawingGroupKey) === -1 ? row.id : undefined,
          identifier: row.id,
          selectable: !!row.orderable,
          orderCode: row.orderCode,
          configuration: [...row.configuration],
          label: `${row.label}`,
          recommendation: row.recommendation,
          category: row.category?.code ?? undefined,
          serviceSegmentation: row.serviceSegmentation,
          positionInDrawing: row.positionInDrawing,
          drawingGroupKey: row.drawingGroup?.key ?? undefined,
          drawingGroupLabel: row.drawingGroup?.label ?? undefined,
          validFrom: row.validFrom,
          validUntil: row.validUntil,
          validPretty: [row.validFrom ? `> ${row.validFrom}` : undefined, row.validUntil ? `< ${row.validUntil}` : undefined].filter(
            (v) => !!v,
          ) as string[],
          compatibilityInformation: [...row.compatibilityInformation],
          installationManuals: [...row.installationManuals],
          quoteTexts: [...row.quoteTexts],
          infoTexts: [...row.infotexts],
          tags: [...(row.tags ?? [])],
        }) as OrderCodeRowDatatableData,
    );
  }

  protected get columns(): DatatableColumnData<OrderCodeRowDatatableData>[] {
    const {
      translator,
      searchService: {internal},
    } = this.props;

    return [
      {
        width: 10,
        label: translator.translate('Order code'),
        key: 'orderCode',
        render: (row) =>
          row.configuration?.length ? (
            <Title
              onClick={() => this.onShowConfiguration(row.orderCode, row.configuration)}
              color={PRIMARY_COLOR}
              weight={TextWeight.BOLD}
              paragraph
            >
              {row.orderCode}
            </Title>
          ) : (
            <Text paragraph>{row.orderCode}</Text>
          ),
        sortable: true,
        searchable: true,
      },
      {
        width: internal ? 40 : 45,
        label: translator.translate('Description'),
        key: 'label',
        render: (row) => {
          const titleProps: TextProps = {};
          if (row.drawingGroupKey === DrawingGroupKey.INFO) {
            titleProps.style = TextStyle.ITALIC;
            titleProps.color = '#007ca9';
          }

          const mainContent = (
            <DescriptionContainer>
              {(row.infoTexts.length > 0 && (
                <Text style={TextStyle.ITALIC} color='#007ca9' paragraph>
                  {row.infoTexts.map((infoText, index) => (
                    <React.Fragment key={index}>
                      {infoText} <br />
                    </React.Fragment>
                  ))}
                </Text>
              )) ||
                null}
              <Text {...titleProps} paragraph>
                {row.label}
              </Text>
            </DescriptionContainer>
          );

          if (row.quoteTexts?.length) {
            return (
              <DescriptionAccordionItem
                title={mainContent}
                onToggle={(visible) => {
                  trackEvent({
                    event_name: 'accordion',
                    event_linkname: 'full description',
                    event_subtype: visible ? 'check' : 'uncheck',
                    event_id: 'sft.en.search result.successful.checkbox.full description',
                  });
                }}
              >
                {row.quoteTexts.map((salesText, index) => (
                  <DescriptionAccordionItemText key={index} style={TextStyle.ITALIC} paragraph>
                    {salesText}
                  </DescriptionAccordionItemText>
                ))}
              </DescriptionAccordionItem>
            );
          }

          return mainContent;
        },
        sortable: true,
        searchable: true,
      },
      {
        width: 5,
        label: translator.translate('Rec'),
        tooltip: translator.translate('Recommended'),
        key: 'recommendation',
        render: (row) =>
          row.recommendation ? (
            <Text color='#2cb725' size={TextSize.BIGGER} weight={TextWeight.BOLD} paragraph>
              +
            </Text>
          ) : null,
        center: true,
        sortable: true,
      },
      ...((internal
        ? [
            {
              width: 5,
              label: translator.translate('Seg'),
              tooltip: translator.translate('Service segmentation'),
              key: 'serviceSegmentation',
              render: (row) => (
                <ComponentWithTooltip tooltip={serviceSegmentationToLabel(translator, row.serviceSegmentation)}>
                  <Text paragraph>{row.serviceSegmentation}</Text>
                </ComponentWithTooltip>
              ),
              center: true,
              sortable: true,
              searchable: true,
            },
          ]
        : []) as DatatableColumnData<OrderCodeRowDatatableData>[]),
      {
        width: 5,
        label: translator.translate('Tk'),
        tooltip: translator.translate('Category code'),
        key: 'category',
        render: (row) => (
          <ComponentWithTooltip tooltip={partClassToLabel(translator, row.category)}>
            <Text paragraph>{row.category}</Text>
          </ComponentWithTooltip>
        ),
        center: true,
        sortable: true,
        searchable: true,
      },
      {
        width: 5,
        label: translator.translate('Pos'),
        tooltip: translator.translate('Drawing Position Number'),
        key: 'positionInDrawing',
        center: true,
        sortable: true,
        searchable: true,
      },
      {
        width: 10,
        label: translator.translate('Group'),
        key: 'drawingGroupLabel',
        sortable: true,
        searchable: true,
      },
      {
        width: 5,
        label: translator.translate('Valid'),
        key: 'validPretty',
        searchable: true,
        render: (row) => (
          <Text paragraph>
            {row.validPretty.map((v, index) => (
              <React.Fragment key={index}>
                {v} <br />
              </React.Fragment>
            ))}
          </Text>
        ),
      },
      {
        width: 5,
        label: translator.translate('Co.'),
        tooltip: translator.translate('Compatibility advices'),
        key: 'compatibilityInformation',
        render: (row) => (
          <ItemsContainer>
            {row.compatibilityInformation.map(({url, key}, index) => (
              <ComponentWithTooltip key={index} tooltip={key}>
                <PreviewLink href={url} target='_blank' onClick={(e) => this.onOpenUrlInNewWindow(e, row, url)}>
                  <DocPdfIcon size={1.25} color={PRIMARY_COLOR} />
                </PreviewLink>
              </ComponentWithTooltip>
            )) || null}
          </ItemsContainer>
        ),
      },
      {
        width: 5,
        label: translator.translate('Mo.'),
        tooltip: translator.translate('Mounting advices'),
        key: 'installationManuals',
        render: (row) => (
          <ItemsContainer>
            {row.installationManuals.map(({url, key}, index) => (
              <ComponentWithTooltip key={index} tooltip={key}>
                <PreviewLink href={url} target='_blank' onClick={(e) => this.onOpenUrlInNewWindow(e, row, url)}>
                  <DocPdfIcon size={1.25} color={PRIMARY_COLOR} />
                </PreviewLink>
              </ComponentWithTooltip>
            )) || null}
          </ItemsContainer>
        ),
      },
    ];
  }

  @computed
  protected get groupFilters(): CheckboxGroupItem[] {
    const filters: Map<string, string> = new Map();

    this.rows.forEach((row) => {
      if (row.drawingGroup && !filters.has(row.drawingGroup.key)) {
        filters.set(row.drawingGroup.key, row.drawingGroup.label);
      }
    });

    return [
      ...[...filters].map(([key, value]) => ({
        id: key,
        label: value,
      })),
    ];
  }

  @computed
  protected get partClassFilters(): CheckboxGroupItem[] {
    const filters: Map<string, string> = new Map();

    this.rows.forEach((row) => {
      if (row.category && !filters.has(row.category.code)) {
        filters.set(row.category.code, row.category.name);
      }
    });

    return [
      ...[...filters].map(([key, value]) => ({
        id: key,
        label: value,
      })),
    ];
  }

  @computed
  protected get recommendedSparePartFilters(): CheckboxGroupItem[] {
    const {translator} = this.props;

    return [
      {
        id: RecommendedSparePartsId,
        label: translator.translate('Recommended parts only'),
      },
    ];
  }

  protected get additionallySelectedOrderCodes(): string[] {
    return [];
  }

  protected abstract get renderHeader(): React.ReactNode;

  protected abstract get resultsDatatableName(): string;

  protected abstract get exportFileName(): string;

  protected abstract get exportTitleRows(): Row_<string>[];

  render() {
    const {
      searchService: {hookUrl},
      translator,
    } = this.props;

    return (
      <>
        {this.renderHeader}
        {(this.groupFilters.length > 0 || this.partClassFilters.length > 0 || this.recommendedSparePartFilters.length > 0) && (
          <FilterContainer title={translator.translate('Set filter')}>
            <Row>
              {this.groupFilters.length > 0 && (
                <Col>
                  <CheckboxGroupField
                    translator={translator}
                    label={translator.translate('Group')}
                    items={this.groupFilters}
                    checkedIds={this.filters[FilterKey.GROUPS]}
                    onChange={(id: string) => this.onToggleFilter(FilterKey.GROUPS, id)}
                    allSelectable
                  />
                </Col>
              )}
              {(this.partClassFilters.length > 0 || this.recommendedSparePartFilters.length > 0) && (
                <Col>
                  {this.partClassFilters.length > 0 && (
                    <CheckboxGroupField
                      translator={translator}
                      label={translator.translate('Category code')}
                      items={this.partClassFilters}
                      checkedIds={this.filters[FilterKey.PART_CLASSES]}
                      onChange={(id: string) => this.onToggleFilter(FilterKey.PART_CLASSES, id)}
                      allSelectable
                    />
                  )}
                  {this.recommendedSparePartFilters.length > 0 && (
                    <CheckboxGroupField
                      translator={translator}
                      label={translator.translate('Recommended')}
                      items={this.recommendedSparePartFilters}
                      checkedIds={this.filters[FilterKey.RECOMMENDED_SPARE_PARTS]}
                      onChange={(id: string) => this.onToggleFilter(FilterKey.RECOMMENDED_SPARE_PARTS, id)}
                    />
                  )}
                </Col>
              )}
            </Row>
          </FilterContainer>
        )}
        <DatatableActionsContainer>
          <PrimaryInvertedButton onClick={() => this.onExport()}>
            <DownloadIcon size={1.5} color={PRIMARY_COLOR} />
            {translator.translate('Download Excel')}
          </PrimaryInvertedButton>
          {hookUrl && (
            <AddToCartButton onClick={this.onAddSelectionToCart}>
              <BasketIcon size={2} color='#ffffff' />
              {translator.translate('Add selection to cart')}
            </AddToCartButton>
          )}
        </DatatableActionsContainer>
        <Datatable
          ref={this.datatableRef}
          name={this.resultsDatatableName}
          translator={translator}
          columns={this.columns}
          rows={this.filteredRows}
          minWidth={940}
          selectable
          resizable
          writeable
        />
        <DatatableActionsContainer>
          {hookUrl && (
            <AddToCartButton onClick={this.onAddSelectionToCart}>
              <BasketIcon size={2} color='#ffffff' />
              {translator.translate('Add selection to cart')}
            </AddToCartButton>
          )}
        </DatatableActionsContainer>
      </>
    );
  }
}

const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 6px;
`;

const PreviewLink = styled.a``;

const FilterContainer = styled(AccordionItem)`
  && {
    border-top: 1px dotted #c3ced5;
    border-bottom: 1px dotted #c3ced5;
    .accordion-header {
      height: 30px;
      padding: 0;
      p {
        font-size: ${TextSize.BIGGER}px;
        font-weight: ${TextWeight.NORMAL};
        line-height: 30px;
        color: ${PRIMARY_COLOR};
        transition: color 0.2s ease-in;
      }
      &:hover {
        p {
          color: ${SECONDARY_COLOR};
        }
        .icon {
          path {
            stroke: ${SECONDARY_COLOR} !important;
          }
        }
      }
    }
  }
`;

const Title = styled(Text)`
  && {
    cursor: pointer;
    transition: color 0.2s ease-in;
    &:hover {
      color: ${SECONDARY_COLOR};
    }
  }
`;

const AddToCartButton = styled(PrimaryButton)`
  && {
    margin: 0 0 0 auto;
  }
`;

const DescriptionContainer = styled.span`
  display: block;
  width: 100%;
`;

const DescriptionAccordionItem = styled(AccordionItem)`
  && {
    padding: 5px 0;
    width: 100%;
    .accordion-header {
      width: 100%;
      height: auto;
      min-height: 22px;
      padding: 0;
      ${DescriptionContainer} {
        width: calc(100% - 24px);
      }
    }
    .accordion-body {
      padding: 0 0 0 24px;
    }
  }
`;

const DescriptionAccordionItemText = styled(Text)`
  && {
    overflow: visible;
    text-overflow: unset;
    white-space: break-spaces;
  }
`;

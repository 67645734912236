import {CustomForm, FormChangeCallback, FormField} from '@lib/Form';

export enum SearchFormFields {
  ORDER_CODE = 'orderCode',
  PRODUCTION_DATE = 'productionDate',
  PART_NUMBER = 'partNumber',
}

export class SearchForm extends CustomForm<
  SearchFormFields,
  {
    [SearchFormFields.ORDER_CODE]: FormField<string>;
    [SearchFormFields.PRODUCTION_DATE]: FormField<Date>;
    [SearchFormFields.PART_NUMBER]: FormField<string>;
  }
> {}

export const createSearchForm = (changeCallback: FormChangeCallback): SearchForm => {
  const form = new SearchForm({
    [SearchFormFields.ORDER_CODE]: new FormField<string>(SearchFormFields.ORDER_CODE, undefined, []),
    [SearchFormFields.PRODUCTION_DATE]: new FormField<Date>(SearchFormFields.PRODUCTION_DATE, undefined, []),
    [SearchFormFields.PART_NUMBER]: new FormField<string>(SearchFormFields.PART_NUMBER, undefined, []),
  });

  form.onChangeCallback = changeCallback;
  return form;
};

import * as React from 'react';
import styled from 'styled-components';
import {Popover as TinyPopover, PopoverProps as TinyPopoverProps} from 'react-tiny-popover';
import {CloseIcon, InfoIcon} from '@components/Icons';
import {DefaultGenericStyles} from '@components/UI/Styles';
import {IconButton} from './Button';
import {ModalCloseButton} from '@components/Modal';

export const GenericPopoverStyles = `
  .tiny-popover {
    z-index: 9;
  }
`;

export enum PopoverTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface PopoverProps extends Omit<TinyPopoverProps, 'isOpen' | 'content'> {
  initiator?: JSX.Element;
  onClose?: (e?: React.MouseEvent) => void;
  onOpen?: (e?: React.MouseEvent) => void;
  hideCloseButton?: boolean;
  stopEventPrevention?: boolean;
  closeOnClickOutside?: boolean;
  theme?: PopoverTheme;
  className?: string;
}

export const Popover = (props: PopoverProps) => {
  const {
    children,
    initiator,
    onOpen,
    onClose,
    onClickOutside,
    hideCloseButton = false,
    stopEventPrevention = false,
    closeOnClickOutside = false,
    theme = PopoverTheme.LIGHT,
    className,
  } = props;
  const [isOpen, setOpen] = React.useState<boolean>(false);

  return (
    <TinyPopover
      {...props}
      isOpen={isOpen}
      onClickOutside={(e) => {
        if (!stopEventPrevention) {
          e.preventDefault();
        }
        if (closeOnClickOutside) {
          onClose && onClose();
          setOpen(false);
          return;
        }
        onClickOutside && onClickOutside(e);
      }}
      content={
        <Container className='tiny-popover-content' onClick={(e) => e.stopPropagation()} theme={theme}>
          {!hideCloseButton && (
            <ModalCloseButton
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onClose && onClose(e);
                setOpen(false);
              }}
            />
          )}
          {children}
        </Container>
      }
      containerClassName={['tiny-popover', className].join(' ')}
    >
      <PopoverButton
        onClick={(e: React.MouseEvent) => {
          onOpen && onOpen(e);
          setOpen(!isOpen);
        }}
        className={['btn', className].join(' ')}
      >
        {initiator || <InfoIcon color='#bbbbbb' size={2} />}
      </PopoverButton>
    </TinyPopover>
  );
};

interface ContainerProps {
  theme: PopoverTheme;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${({theme}: ContainerProps) => (theme === PopoverTheme.DARK ? '#717E87' : '#ffffff')};
  box-shadow:
    0 8px 18px rgba(0, 0, 0, 0.08),
    0 0 2px rgba(0, 0, 0, 0.19);
  transform: translateY(-10px);
  max-width: 400px;
  ${DefaultGenericStyles};
`;

interface PopoverBodyProps {
  scrollbar?: boolean;
}

export const PopoverBody = styled.div<PopoverBodyProps>`
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  ${({scrollbar}: PopoverBodyProps) =>
    scrollbar
      ? `
    max-height: 150px;
    overflow: auto;
    padding: 0 10px 0 0;
    ::-webkit-scrollbar {
      display: block;
      width: 3px;
      height: 3px;
    }
  `
      : ''}
`;

const PopoverButton = styled.span`
  display: block;
  padding: 0;
  > .icon {
    display: block;
  }
`;

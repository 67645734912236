import * as React from 'react';
import {action, computed, isObservableArray, makeObservable, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {escapeRegExpSpecialChars, SECONDARY_COLOR} from '@lib/Utils';
import {Translator} from '@lib/Translation';
import {ComponentWithTooltip, Text, TextDiv, TextP, TextWeight} from '@components/UI';
import {CaretDownIcon, CaretUpIcon} from '@components/Icons';
import {CheckboxField, InputField} from '@components/Form';

const DEFAULT_SELECTABLE_COLUMN_KEY = 'id';
const DEFAULT_SELECTABLE_COLUMN_WIDTH = 5;

const DATATABLE_STORAGE_KEY = 'sft_datatable_state';

export type DatatableRowId = string | undefined;

export type DatatableRowData = {
  id: DatatableRowId;
  identifier: DatatableRowId;
  selectable?: boolean;
  [key: string]: DatatableRowValue;
};

export type DatatableRowValue = string | number | boolean | undefined;

export type DatableSortCallback = (vA: string, vB: string) => 1 | -1 | 0;

export interface DatatableColumnData<E = DatatableRowData> {
  key: keyof E;
  width: number;
  label?: string | React.ReactNode;
  renderLabel?: () => React.ReactNode;
  tooltip?: string | React.ReactNode;
  render?: (row: E) => string | React.ReactNode;
  sortable?: boolean;
  defaultSortDir?: DatatableSortDir;
  sortCallback?: DatableSortCallback;
  searchable?: boolean;
  center?: boolean;
}

interface DatatableWriteableColumnData<E = DatatableRowData> extends Pick<DatatableColumnData<E>, 'key' | 'width'> {}

type DatatableWriteableData<E> = Partial<{
  columns: DatatableWriteableColumnData<E>[];
}>;

type DatatableWriteableStorageData<E> = {[key: string]: DatatableWriteableData<E>};

interface MousePosition {
  x: number;
  y: number;
}

export enum DatatableSortDir {
  ASC = 'asc',
  DESC = 'desc',
}

interface DatatableProps<E> {
  name: string;
  translator: Translator;
  columns: DatatableColumnData<E>[];
  rows: E[];
  selectable?: boolean;
  onRowClick?: (row: E) => void;
  minWidth?: number;
  scrollable?: boolean;
  resizable?: boolean;
  writeable?: boolean;
}

@observer
export class Datatable<E = DatatableRowData> extends React.Component<DatatableProps<E>, {}> {
  @observable private filters: {[key in keyof E]?: string} = {};
  @observable private sortKey?: keyof E;
  @observable private sortDir?: DatatableSortDir;
  @observable private activeRowIndex?: number;
  @observable private columns: DatatableColumnData<E>[] = [];
  @observable private rows: E[] = [];
  @observable private checked: Set<string> = new Set<string>();
  @observable private containerWidth?: number;
  @observable private data?: DatatableWriteableData<E>;
  @observable private resizingColumnKey?: keyof E;
  @observable private resizingColumnWidthSnapshot?: number;
  @observable private mousePosition?: MousePosition;
  private activeRowTimeout?: number;
  private readonly containerOuterRef: React.RefObject<HTMLDivElement> = React.createRef();
  private readonly containerInnerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: DatatableProps<E>) {
    super(props);

    const {selectable, columns, rows} = props;

    runInAction(() => {
      this.columns.length = 0;
      if (selectable) {
        this.columns.push({
          key: DEFAULT_SELECTABLE_COLUMN_KEY as keyof E,
          width: DEFAULT_SELECTABLE_COLUMN_WIDTH,
          renderLabel: () => <StyledCheckboxField checked={this.allRowsChecked} onChange={this.handleToggleSelectionAllRows} />,
          render: (row) => {
            const {id, selectable = true} = row as DatatableRowData;

            if (!id) {
              return null;
            }

            const rowId = id;

            return (
              <StyledCheckboxField
                checked={this.checked.has(rowId)}
                onChange={() => runInAction(() => (this.checked.has(rowId) ? this.checked.delete(rowId) : this.checked.add(rowId)))}
                readOnly={!selectable}
              />
            );
          },
        });
      }
      this.columns.push(...columns);
      this.rows.length = 0;
      this.rows.push(...rows);
    });

    this.readData();

    makeObservable(this);
  }

  componentDidMount() {
    //setTimeout(() => {
    this.calculateContainerWidth();
    if (this.initDataRequired) {
      this.initData();
    }
    //}, 0);
  }

  componentDidUpdate(prevProps: Readonly<DatatableProps<E>>) {
    const {rows} = this.props;

    if (rows !== prevProps.rows) {
      runInAction(() => {
        this.rows.length = 0;
        this.rows.push(...rows);
      });
    }
  }

  @action
  private calculateContainerWidth = () => {
    const {minWidth} = this.props;

    let containerWidth = this.containerInnerRef.current?.clientWidth ?? undefined;
    if (minWidth && containerWidth && minWidth > containerWidth) {
      containerWidth = minWidth;
    }

    this.containerWidth = containerWidth ?? undefined;
  };

  @action
  private handlePointerDown = (e: React.PointerEvent, columnKey: keyof E) => {
    let column = (this.data?.columns ?? []).find((c) => c.key === columnKey);
    if (!column) {
      const {columns: initialColumns} = this.props;

      const initialColumn = initialColumns.find((c) => c.key === columnKey);
      if (initialColumn) {
        column = {
          key: initialColumn?.key,
          width: initialColumn?.width,
        };
      }
    }

    this.resizingColumnKey = columnKey;
    this.resizingColumnWidthSnapshot = column?.width ?? 0;
    this.containerOuterRef.current!.style.cursor = 'ew-resize';

    this.updatePointerPosition(e);
  };

  @action
  private handlePointerUp = (e: React.PointerEvent) => {
    this.resizingColumnKey = undefined;
    this.resizingColumnWidthSnapshot = undefined;
    this.containerOuterRef.current!.style.cursor = 'default';
    this.updatePointerPosition(e);
  };

  @action
  private handlePointerMove = (e: React.PointerEvent) => {
    if (this.mousePosition && this.resizingColumnKey && this.resizingColumnWidthSnapshot) {
      let column = (this.data?.columns ?? []).find((c) => c.key === this.resizingColumnKey);
      if (!column) {
        const {columns: initialColumns} = this.props;

        const initialColumn = initialColumns.find((c) => c.key === this.resizingColumnKey);
        if (initialColumn) {
          column = {
            key: initialColumn.key,
            width: initialColumn.width,
          };

          (this.data?.columns ?? []).push(column);
        }
      }

      if (column) {
        const distanceX = e.clientX - this.mousePosition.x;
        if (distanceX > 0 || distanceX < 0) {
          column.width = this.resizingColumnWidthSnapshot + distanceX;
          this.storeData();
        }
      }
    }
  };

  @action
  private updatePointerPosition = (e: React.PointerEvent) => {
    this.mousePosition = {
      x: e.pageX,
      y: e.pageY,
    };
  };

  @action
  private initData = () => {
    let totalColumnsWidth: number = 0;
    let containerWidth = this.containerWidth;
    if (!containerWidth) {
      return;
    }

    const columns: DatatableWriteableColumnData<E>[] = [];

    this.columns.forEach((column) => {
      let width = Math.floor(containerWidth * (column.width / 100));
      if (totalColumnsWidth > containerWidth) {
        width = containerWidth - totalColumnsWidth;
      }
      totalColumnsWidth += width;

      columns.push({
        key: column.key,
        width,
      });
    });

    if (!this.data) {
      this.data = {};
    }

    this.data.columns = columns;
    this.storeData();
  };

  @action
  private readData() {
    const {name, writeable = false} = this.props;

    if (!writeable) {
      return;
    }

    const data: DatatableWriteableStorageData<E> = localStorage.getItem(DATATABLE_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(DATATABLE_STORAGE_KEY) as string)
      : ({} as DatatableWriteableStorageData<E>);
    if (data[name]) {
      this.data = data[name];
    }
  }

  @action
  private storeData() {
    const {name, writeable = false} = this.props;

    if (!writeable) {
      return;
    }

    const data: DatatableWriteableStorageData<E> = localStorage.getItem(DATATABLE_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(DATATABLE_STORAGE_KEY) as string)
      : ({} as DatatableWriteableStorageData<E>);
    data[name] = {...this.data};

    localStorage.setItem(DATATABLE_STORAGE_KEY, JSON.stringify(data));
  }

  @action
  private handleToggleSelectionAllRows = () => {
    if (this.allRowsChecked) {
      this.checked.clear();
    } else {
      this.filteredSelectableSortedRows.forEach((row) => {
        runInAction(() => {
          const selectableRow = row as DatatableRowData;

          if (!selectableRow.id) {
            return null;
          }

          this.checked.add(selectableRow.id);
        });
      });
    }
  };

  @action
  private handleColumnClick = (column: DatatableColumnData<E>) => {
    const {key, sortable, defaultSortDir} = column;

    if (!key || !sortable) {
      return;
    }

    const realDefaultSortDir = defaultSortDir ?? DatatableSortDir.ASC;

    if (this.sortKey === key) {
      const needToReset = this.sortDir !== realDefaultSortDir;
      if (needToReset) {
        this.sortKey = undefined;
        this.sortDir = undefined;
      } else {
        this.sortDir = this.sortDir === DatatableSortDir.ASC ? DatatableSortDir.DESC : DatatableSortDir.ASC;
      }
    } else {
      this.sortKey = key;
      this.sortDir = realDefaultSortDir;
    }
  };

  @action
  private handleFilterChange = (column: DatatableColumnData<E>, value?: string) => {
    this.filters[column.key] = value || undefined;
    this.checked.clear();
  };

  @action
  private handleRowClick = (rowIndex: number, callback: () => void) => {
    if (this.activeRowTimeout) {
      window.clearTimeout(this.activeRowTimeout);
      this.activeRowTimeout = undefined;
    }

    this.activeRowIndex = rowIndex;
    this.activeRowTimeout = window.setTimeout(() => {
      runInAction(() => {
        this.activeRowIndex = undefined;
      });
    }, 3000);
    callback();
  };

  @computed
  get filteredSortedRows(): E[] {
    let rows = [...this.rows];

    const {filters, sortKey, sortDir} = this;

    Object.keys(filters).forEach((key) => {
      const filterKey = key as keyof E;
      const filterValue = filters[filterKey];

      if (filterValue) {
        const regExp = new RegExp(`${escapeRegExpSpecialChars(filterValue)}`, 'i');
        rows = rows.filter((row) => {
          const rowValue = row[filterKey];

          if (rowValue) {
            if (typeof rowValue === 'string' || typeof rowValue === 'number') {
              return !!`${rowValue}`.match(regExp);
            } else if (Array.isArray(rowValue) || isObservableArray(rowValue)) {
              return rowValue.findIndex((value) => !!`${value}`.match(regExp)) > -1;
            }
          }

          return false;
        });
      }
    });

    if (sortKey && sortDir) {
      const column = this.columns.find((column) => column.key === sortKey);

      rows.sort((rowA, rowB) => {
        const rowAValue = (rowA[sortKey] ?? '') as string;
        const rowBValue = (rowB[sortKey] ?? '') as string;

        if (column?.sortCallback) {
          return column.sortCallback(rowAValue, rowBValue);
        }

        if (rowAValue > rowBValue) {
          return 1;
        }
        if (rowBValue > rowAValue) {
          return -1;
        }
        return 0;
      });

      if (sortDir === DatatableSortDir.DESC) {
        rows.reverse();
      }
    }

    return rows;
  }

  @computed
  private get filteredSelectableSortedRows() {
    return this.filteredSortedRows.filter((row) => (row as DatatableRowData).selectable);
  }

  @computed
  private get hasAnyColumnSearchable() {
    return this.columns.some((column) => column.searchable);
  }

  @computed
  private get allRowsChecked() {
    const rowsAmount = this.filteredSelectableSortedRows.length;

    return rowsAmount ? rowsAmount === this.checked.size : false;
  }

  @computed
  private get initDataRequired() {
    return !this.data || (this.data.columns ?? []).length !== this.columns.length;
  }

  get checkedIds() {
    return [...this.checked];
  }

  render() {
    const {translator, onRowClick, minWidth = 760, scrollable = false, resizable = false} = this.props;

    return (
      <ContainerOuter
        ref={this.containerOuterRef}
        onPointerMove={this.handlePointerMove}
        onPointerUp={this.handlePointerUp}
        $resizing={!!this.resizingColumnKey}
      >
        <ContainerInner ref={this.containerInnerRef} $minWidth={!this.data ? minWidth : undefined} $resizable={resizable}>
          <Header>
            <Row>
              {this.columns.map((column, index) => {
                const dynamicColumnData = (this.data?.columns ?? []).find((c) => c.key === column.key);

                return (
                  <Column
                    key={index}
                    $width={column.width}
                    $center={column.center}
                    $clickable={column.sortable}
                    onClick={() => this.handleColumnClick(column)}
                    style={{...(dynamicColumnData?.width ? {width: `${dynamicColumnData.width}px`} : {})}}
                  >
                    <StyledComponentWithTooltip tooltip={column.tooltip}>
                      {column.renderLabel ? (
                        column.renderLabel()
                      ) : typeof column.label === 'string' ? (
                        <Text weight={TextWeight.BOLD} paragraph>
                          {column.label}
                        </Text>
                      ) : (
                        column.label
                      )}
                    </StyledComponentWithTooltip>
                    {this.sortKey && column.sortable && this.sortKey === column.key && (
                      <>
                        {this.sortDir === DatatableSortDir.ASC ? (
                          <SortDirCaretDownIcon size={0.5} color='#c3ced5' />
                        ) : (
                          <SortDirCaretUpIcon size={0.5} color='#c3ced5' />
                        )}
                      </>
                    )}
                    {resizable && (
                      <ResizeSeparator
                        onClick={(e) => e.stopPropagation()}
                        onPointerDown={(e) => this.handlePointerDown(e, column.key)}
                        onPointerUp={this.handlePointerUp}
                      />
                    )}
                  </Column>
                );
              })}
            </Row>
            {this.hasAnyColumnSearchable && (
              <Row>
                {this.columns.map((column, index) => {
                  const dynamicColumnData = (this.data?.columns ?? []).find((c) => c.key === column.key);

                  return (
                    <Column
                      key={index}
                      $width={column.width}
                      $center={column.center}
                      style={{...(dynamicColumnData?.width ? {width: `${dynamicColumnData.width}px`} : {})}}
                    >
                      {column.searchable && (
                        <SearchField
                          type='text'
                          placeholder={translator.translate('Filter')}
                          value={this.filters[column.key] ?? ''}
                          onChange={(value) => this.handleFilterChange(column, value)}
                          allowClear
                        />
                      )}
                    </Column>
                  );
                })}
              </Row>
            )}
          </Header>
          <Body $scrollable={scrollable}>
            {this.filteredSortedRows.map((row, rowIndex) => {
              return (
                <Row
                  key={rowIndex}
                  $clickable={!!onRowClick}
                  $active={this.activeRowIndex === rowIndex}
                  $hasId={!!(row as DatatableRowData).id}
                  $selectable={(row as DatatableRowData).selectable}
                  onClick={() => (onRowClick ? this.handleRowClick(rowIndex, () => onRowClick(row)) : undefined)}
                  data-row-id={(row as DatatableRowData).identifier}
                >
                  {this.columns.map((column, columnIndex) => {
                    const dynamicColumnData = (this.data?.columns ?? []).find((c) => c.key === column.key);

                    return (
                      <Column
                        key={`${rowIndex}_${columnIndex}`}
                        $width={column.width}
                        $center={column.center}
                        style={{...(dynamicColumnData?.width ? {width: `${dynamicColumnData.width}px`} : {})}}
                        data-column-id={column.key}
                      >
                        {column.render ? column.render(row) : column.key ? <Text paragraph>{(row as DatatableRowData)[column.key]}</Text> : null}
                      </Column>
                    );
                  })}
                </Row>
              );
            }) || null}
          </Body>
        </ContainerInner>
      </ContainerOuter>
    );
  }
}

interface ContainerOuterProps {
  $resizing: boolean;
}

const ContainerOuter = styled.div<ContainerOuterProps>`
  overflow-x: auto;
  ${({$resizing}: ContainerOuterProps) =>
    $resizing
      ? `
    * {
      user-select: none;
      cursor: ew-resize !important;
    }
  `
      : ''}
`;

interface ContainerInnerProps {
  $minWidth?: number;
  $resizable: boolean;
}

const ContainerInner = styled.div<ContainerInnerProps>`
  scrollbar-width: none;
  ${({$minWidth}: ContainerInnerProps) => ($minWidth ? `min-width: ${$minWidth}px;` : '')};
  ${({$resizable}: ContainerInnerProps) =>
    $resizable
      ? `
    min-width: 100%;
    width: max-content;
  `
      : ''};
`;

const StyledComponentWithTooltip = styled(ComponentWithTooltip)``;

interface RowProps {
  $clickable?: boolean;
  $active?: boolean;
  $hasId?: boolean;
  $selectable?: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px dotted #c3ced5;
  transition: background-color 0.2s ease-in;
  ${({$clickable, $active}: RowProps) =>
    $clickable
      ? `
      cursor: pointer;
      * {
        transition: color 0.2s ease-in;
      }
      ${
        $active
          ? `
        background-color: ${SECONDARY_COLOR};
        * {
          color: #ffffff;
        }
      `
          : ''
      }
    `
      : ''}
  ${({$hasId = true, $selectable = true}: RowProps) =>
    $hasId && !$selectable
      ? `
    p {
      color: #bdbdbd;
    }
  `
      : ''}
`;

interface ColumnProps {
  $width: number;
  $center?: boolean;
  $clickable?: boolean;
}

const Column = styled.div<ColumnProps>`
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  /*flex-grow: 1;
  flex-shrink: 0;*/
  padding: 2px 10px;
  width: ${({$width}: ColumnProps) => $width}%;
  ${({$center}: ColumnProps) =>
    $center
      ? `
    text-align: center;
    justify-content: center;
    ${StyledComponentWithTooltip} {
      display: block;
      width: 100%;
    }
  `
      : ''};
  ${({$clickable}: ColumnProps) =>
    $clickable
      ? `
    cursor: pointer;
  `
      : ''}
  ${TextP}, ${TextDiv} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const GenericSortDirStyles = `
  margin: 0 0 0 auto;
  flex-shrink: 0;
`;

const SortDirCaretDownIcon = styled(CaretDownIcon)`
  ${GenericSortDirStyles};
`;

const SortDirCaretUpIcon = styled(CaretUpIcon)`
  ${GenericSortDirStyles};
`;

const Header = styled.div`
  ${Row} {
    padding-right: 7px;
    &:first-child {
      border-bottom-style: solid;
    }
  }
`;

interface BodyProps {
  $scrollable: boolean;
}

const Body = styled.div<BodyProps>`
  ${({$scrollable}: BodyProps) =>
    $scrollable
      ? `
    max-height: 400px;
    overflow: auto;
  `
      : ''}
`;

const SearchField = styled(InputField)`
  && {
    margin: 0;
    .form-control {
      height: 24px;
      font-size: 13px;
      padding: 0 6px;
    }
    .btn {
      right: 2px;
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledCheckboxField = styled(CheckboxField)`
  && {
    margin: 0;
  }
`;

const ResizeSeparator = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  cursor: ew-resize;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #c3ced5;
  }
`;

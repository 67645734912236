import * as React from 'react';
import {observer} from 'mobx-react';
import writeXlsxFile from 'write-excel-file';
import {PRIMARY_COLOR} from '@lib/Utils';
import {MaterialProductData} from '@lib/Api';
import {Datatable, DatatableActionsContainer, DatatableColumnData} from '@components/Datatable';
import {PrimaryInvertedButton} from '@components/UI';
import {DownloadIcon} from '@components/Icons';
import {GenericSearchProps} from '../../shared';

interface PartNumberProductsResultsProps extends GenericSearchProps {
  data: MaterialProductData[];
}

@observer
export class PartNumberProductsResults extends React.Component<PartNumberProductsResultsProps, {}> {
  private readonly datatableRef: React.RefObject<Datatable<MaterialProductData>> = React.createRef();

  private onRowClick = async (row: MaterialProductData) => {
    const {searchService, onRowClick} = this.props;

    await searchService.onSelectMaterialProduct(row);
    onRowClick && onRowClick();
  };

  private onExport = async () => {
    const {translator} = this.props;

    const rowsToExport: MaterialProductData[] = [...(this.datatableRef.current?.filteredSortedRows ?? [])];

    const orderCodeColumnName = translator.translate('Order code');
    const descriptionColumnName = translator.translate('Description');

    await writeXlsxFile(rowsToExport, {
      schema: [
        {
          column: orderCodeColumnName,
          width: Math.max(orderCodeColumnName.length, ...rowsToExport.map((row) => row.productRoot?.length ?? 0)) + 1,
          value: (row) => row.productRoot,
        },
        {
          column: descriptionColumnName,
          width: Math.max(descriptionColumnName.length, ...rowsToExport.map((row) => row.label?.length ?? 0)) + 1,
          value: (row) => row.label,
        },
      ],
      headerStyle: {
        fontWeight: 'bold',
      },
      sheet: translator.translate('Search products'),
      fileName: `productsExport.xlsx`,
    });
  };

  private get columns(): DatatableColumnData<MaterialProductData>[] {
    const {translator} = this.props;

    return [
      {
        width: 30,
        label: translator.translate('Order code'),
        key: 'productRoot',
        sortable: true,
        searchable: true,
      },
      {
        width: 70,
        label: translator.translate('Description'),
        key: 'label',
        sortable: true,
        searchable: true,
      },
    ];
  }

  render() {
    const {data, translator} = this.props;

    return (
      <>
        <DatatableActionsContainer>
          <PrimaryInvertedButton onClick={this.onExport}>
            <DownloadIcon size={1.5} color={PRIMARY_COLOR} />
            {translator.translate('Download Excel')}
          </PrimaryInvertedButton>
        </DatatableActionsContainer>
        <Datatable
          ref={this.datatableRef}
          name='partNumberProducts'
          translator={translator}
          columns={this.columns}
          rows={data}
          onRowClick={this.onRowClick}
          minWidth={560}
          resizable
          writeable
        />
      </>
    );
  }
}

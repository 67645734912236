import {DefaultFontName} from '@components/UI/Fonts';

export const DefaultGenericStyles = `
  font-family: '${DefaultFontName}', sans-serif;
  input,
  button,
  textarea {
    font-family: '${DefaultFontName}', sans-serif;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
`;

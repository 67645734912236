import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const AngleRightIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 7 10'
    body={<path d='M0.999999 9L5 5L0.999999 1' stroke={props.color} strokeWidth='2' />}
    {...props}
    color='transparent'
  />
);

export const prettyDate = (date: Date | undefined): string => {
  if (!date) return '';

  return parseDate(DateFormatString.X, date);
};

export const prettyTime = (date: Date | undefined): string => {
  if (!date) return '';
  const d = date;
  return `${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}`;
};

export const prettyDateTime = (date: Date | undefined): string => {
  if (!date) return '';

  return [prettyDate(date), prettyTime(date)].join(' ');
};

export const datePickerPlaceholderDate = (value: Date | undefined, placeholder?: string, placeholderFormatter?: (date?: Date) => string) => {
  const placeholderDate = value ? (placeholderFormatter && placeholderFormatter(value)) || prettyDateTime(value) : undefined;
  return (placeholderDate || placeholder) ?? 'set Date & Time';
};

export enum DateFormatString {
  U = 'mm/dd/yyyy',
  E = 'dd/mm/yyyy',
  D = 'yyyy-mm-dd',
  X = 'dd.mm.yyyy',
  X_S = 'mm.yyyy',
}

export const parseDate = (dateFormatString: DateFormatString, date: Date) => {
  let dateFormatted: string = dateFormatString.toLowerCase();

  let month: string | number = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day: string | number = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }

  dateFormatted = dateFormatted.replace('yyyy', `${date.getFullYear()}`);
  dateFormatted = dateFormatted.replace('mm', `${month}`);
  dateFormatted = dateFormatted.replace('dd', `${day}`);

  return dateFormatted;
};

export const unparseDate = (dateFormatString: DateFormatString, dateString: string): Date => {
  const dateFormatted: string = dateFormatString.toLowerCase();

  const DAY_STR = 'dd';
  const MONTH_STR = 'mm';
  const YEAR_STR = 'yyyy';

  const day = dateString.substring(dateFormatted.indexOf(DAY_STR), dateFormatted.indexOf(DAY_STR) + DAY_STR.length);
  const month = dateString.substring(dateFormatted.indexOf(MONTH_STR), dateFormatted.indexOf(MONTH_STR) + MONTH_STR.length);
  const year = dateString.substring(dateFormatted.indexOf(YEAR_STR), dateFormatted.indexOf(YEAR_STR) + YEAR_STR.length);

  const date = new Date();
  date.setFullYear(-(-year));
  date.setMonth(-(-month) - 1);
  date.setDate(-(-day));
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

import * as React from 'react';
import {observer} from 'mobx-react';
import {PickerBodyProps} from '..';

interface RenderEmptyDaysProps extends PickerBodyProps {
  days: string[];
}

@observer
export class RenderEmptyDays extends React.Component<RenderEmptyDaysProps, {}> {
  render() {
    const {days} = this.props;

    return days.map((i, index) => {
      return (
        <div key={i} className={index > 4 ? 'bfdp-popup-body-days-weekend' : ''}>
          <button type='button' onClick={() => {}} />
        </div>
      );
    });
  }
}

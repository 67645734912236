import * as React from 'react';
import {observer} from 'mobx-react';
import styled, {keyframes} from 'styled-components';
import {PRIMARY_COLOR, SECONDARY_COLOR} from '@lib/Utils';

const loaderColorsAmount = 2;
const loaderDash = 63;
const loaderDuration = `${loaderColorsAmount}s`;
const loaderDurationAlt = 1;
const loaderKeyframe = (1 / (loaderColorsAmount * 2)) * 100;

interface LoaderProps {
  width?: number;
  height?: number;
  weight?: number;
  colors?: string[];
  className?: string;
}

export const Loader = observer(({width = 32, height = 32, weight = 2, colors = [PRIMARY_COLOR, SECONDARY_COLOR], className}: LoaderProps) => (
  <Container className={className}>
    <LoaderAnimation $width={width} $height={height} viewBox='0 0 24 24'>
      {colors.map((v, index) => (
        <LoaderValue key={index} $weight={weight} $color={v} cx={12} cy={12} r={6} />
      ))}
    </LoaderAnimation>
  </Container>
));

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const animationLoaderTurn = keyframes`
  50% { transform: rotate(180deg) }
  100% { transform: rotate(720deg) }
`;

const animationLoaderStroke = keyframes`
  ${`${loaderKeyframe}%`} { stroke-dashoffset: 0 }
  ${`${loaderKeyframe * 2}%, 100%`} { stroke-dashoffset: ${loaderDash} }
`;

interface LoaderAnimationProps {
  $width: number;
  $height: number;
}

const LoaderAnimation = styled.svg<LoaderAnimationProps>`
  animation: ${animationLoaderTurn} ${loaderDurationAlt}s linear infinite;
  ${({$width, $height}: LoaderAnimationProps) => `
    width: ${$width}px;
    height: ${$height}px;
  `}
  box-sizing: content-box !important;
  * {
    box-sizing: content-box !important;
  }
  ${new Array(loaderColorsAmount).fill(1).map(
    (v, index) =>
      `
    :nth-child(${index + 1}) {
      ${
        index > 0
          ? `
        animation-delay: ${index * loaderDurationAlt}s;
      `
          : ''
      }
    }
  }
    `,
  )}
`;

interface LoaderValueProps {
  $weight: number;
  $color: string;
}

const LoaderValue = styled.circle<LoaderValueProps>`
  animation: ${animationLoaderStroke} ${loaderDuration} linear infinite;
  fill: none;
  stroke-dasharray: ${loaderDash};
  stroke-dashoffset: ${loaderDash};
  stroke-linecap: round;
  stroke-width: ${({$weight}: LoaderValueProps) => $weight};
  stroke: ${({$color}: LoaderValueProps) => $color};
`;

import * as React from 'react';
import {action, makeObservable, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {MD_SIZE, PRIMARY_COLOR} from '@lib/Utils';
import {AngleLeftIcon} from '@components/Icons';
import {Text, TextSize, TextWeight} from './Typography';

const DEFAULT_SCROLLABLE_BODY_HEIGHT = 500;

interface AccordionItemProps {
  title: string | React.ReactNode;
  openByDefault?: boolean;
  className?: string;
  extraContentOnOpen?: React.ReactNode;
  scrollableBody?: boolean;
  scrollableBodyHeight?: number;
  bodyRef?: React.RefObject<HTMLDivElement>;
  onToggle?: (visible: boolean) => void;
  children?: React.ReactNode;
}

@observer
export class AccordionItem extends React.Component<AccordionItemProps, {}> {
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  @observable private visible = false;

  constructor(props: AccordionItemProps) {
    super(props);

    makeObservable(this);
  }

  componentDidMount() {
    const {openByDefault} = this.props;

    runInAction(() => {
      this.visible = !!openByDefault;
    });
  }

  @action
  private toggle = () => {
    const {onToggle} = this.props;
    this.visible = !this.visible;
    onToggle && onToggle(this.visible);
  };

  render() {
    const {title, children, extraContentOnOpen, className, bodyRef, scrollableBody = false, scrollableBodyHeight} = this.props;

    return (
      <Container ref={this.containerRef} $visible={this.visible} className={[this.visible ? 'accordion-open' : '', className].join(' ')}>
        <Header onClick={this.toggle} className='accordion-header'>
          <StyledAngleLeftIcon size={0.75} color='#bdbdbd' />
          {(typeof title === 'string' && (
            <Title size={TextSize.LARGE} weight={TextWeight.BOLD} paragraph>
              {title}
            </Title>
          )) ||
            title}
        </Header>
        <Body
          ref={bodyRef}
          className='accordion-body'
          $scrollable={scrollableBody}
          $scrollableHeight={scrollableBodyHeight || DEFAULT_SCROLLABLE_BODY_HEIGHT}
          $open={this.visible}
        >
          {children}
        </Body>
        {(this.visible && extraContentOnOpen) || null}
      </Container>
    );
  }
}

const StyledAngleLeftIcon = styled(AngleLeftIcon).attrs({
  className: 'icon accordion-arrow-icon',
})`
  && {
    transition:
      transform 0.2s ease-in,
      fill 0.2s ease-in;
    transform: rotate(-180deg);
    flex-shrink: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 12px;
  height: 44px;
  padding: 0 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  &:hover {
    ${StyledAngleLeftIcon} {
      path {
        transition: all 0.2s ease-in;
        stroke: ${PRIMARY_COLOR} !important;
      }
    }
  }
`;

const Title = styled(Text)``;

interface BodyProps {
  $scrollable: boolean;
  $scrollableHeight: number;
  $open: boolean;
}

const Body = styled.div<BodyProps>`
  ${({$open}: BodyProps) =>
    $open
      ? `
          padding: 12px;
  `
      : ''}
  ${({$scrollable, $scrollableHeight, $open}: BodyProps) =>
    $open && $scrollable
      ? `
    padding-left: 0;
    padding-right: 0;
    height: ${$scrollableHeight}px;
    overflow: auto;
    @media all and (min-width: ${MD_SIZE}px) {
      overflow: visible;
    }
  `
      : ''}
`;

interface ContainerProps {
  $visible: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  ${({$visible}: ContainerProps) =>
    $visible
      ? `
    ${Body} {
      display: block;
    }
    ${StyledAngleLeftIcon} {
      transform: rotate(-90deg);
    }
  `
      : `
    ${Body} {
      display: none;
    }
  `}
`;

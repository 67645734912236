import * as React from 'react';
import {observer} from 'mobx-react';
import {PickerBodyProps} from '..';
import {action, computed, makeObservable} from 'mobx';

interface RenderDaysProps extends PickerBodyProps {
  days: {label: string; date: Date}[];
}

@observer
export class RenderDays extends React.Component<RenderDaysProps, {}> {
  constructor(props: RenderDaysProps) {
    super(props);

    makeObservable(this);
  }

  @computed get today() {
    const {newDatePickerService} = this.props;
    return `${newDatePickerService.today.getFullYear()},${newDatePickerService.today.getMonth()},${newDatePickerService.today.getDate()}`;
  }

  @computed get selected() {
    const {newDatePickerService} = this.props;
    if (!newDatePickerService || !newDatePickerService.isSet) {
      return '#';
    }
    return `${newDatePickerService.newDate.getFullYear()},${newDatePickerService.newDate.getMonth()},${newDatePickerService.newDate.getDate()}`;
  }

  @action
  save = (date: Date) => {
    const {newDatePickerService} = this.props;
    newDatePickerService.isSet = true;
    newDatePickerService.newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      newDatePickerService.newDate.getHours(),
      newDatePickerService.newDate.getMinutes(),
    );
  };

  render() {
    const {days} = this.props;

    return days.map((i) => {
      const date = `${i.date.getFullYear()},${i.date.getMonth()},${i.date.getDate()}`;
      return (
        <div
          key={i.label}
          className={`bfdp-popup-body-days-days${[0, 6].indexOf(i.date.getDay()) > -1 ? ' bfdp-popup-body-days-weekend' : ''}${
            date === this.today ? ' bfdp-popup-body-days-today' : ''
          }${date === this.selected ? ' bfdp-popup-body-days-active' : ''}`}
        >
          <button
            type='button'
            onClick={() => {
              this.save(i.date);
            }}
          >
            {i.label}
          </button>
        </div>
      );
    });
  }
}

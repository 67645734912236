import * as React from 'react';
import {action, computed, makeObservable, observable} from 'mobx';

export interface ModalConfig {
  title?: string | React.ReactNode;
  overrideBody?: boolean;
  closeOnOutsideClick?: boolean;
}

const defaultModalConfig: ModalConfig = {
  overrideBody: false,
  closeOnOutsideClick: false,
};

export class ModalService {
  @observable private _visible = false;
  @observable private _content?: React.ReactNode;
  @observable private _config?: ModalConfig;

  constructor() {
    makeObservable(this);
  }

  @computed get modalVisibility(): boolean {
    return this._visible;
  }

  @action
  show = (content?: React.ReactNode, overrideBodyOrConfig?: ModalConfig | boolean) => {
    this._content = content;
    if (typeof overrideBodyOrConfig === 'boolean') {
      this._config = {
        ...defaultModalConfig,
        overrideBody: overrideBodyOrConfig,
      };
    } else {
      this._config = overrideBodyOrConfig;
    }

    this._visible = true;
  };

  @action
  hide = () => {
    this._visible = false;

    this._content = undefined;
    this._config = undefined;
  };

  @computed get content(): React.ReactNode | undefined {
    return this._content;
  }

  @computed get config(): ModalConfig {
    return (
      this._config ?? {
        ...defaultModalConfig,
      }
    );
  }
}

import * as React from 'react';
import styled from 'styled-components';
import {CloseIcon, IconProps} from '@components/Icons';
import {IconButton, TertiaryButton} from '@components/UI';

export const StyledCloseButton = styled(IconButton)`
  && {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

interface ModalCloseButtonProps {
  onClick: (e: React.MouseEvent) => void;
  iconProps?: IconProps;
  className?: string;
}

export const ModalCloseButton = ({onClick, iconProps, className}: ModalCloseButtonProps) => (
  <StyledCloseButton onClick={onClick} className={className}>
    <CloseIcon color='#A3A3A3' size={1.5} {...iconProps} />
  </StyledCloseButton>
);

export const DefaultModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding: 12px;
  ${TertiaryButton} {
    text-transform: uppercase;
    margin: 0 auto;
  }
`;

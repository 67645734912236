import * as React from 'react';
import {observer} from 'mobx-react';
import {ArrowIcon} from '@components/Icons';

interface PickerHeaderProps {
  togglePickerMode: () => void;
  label: string;
  next: () => void;
  prev: () => void;
}

@observer
export class PickerHeader extends React.Component<PickerHeaderProps, {}> {
  render() {
    const {togglePickerMode, label, prev, next} = this.props;

    return (
      <div className='bfdp-popup-header'>
        <ArrowIcon className='bfdp-popup-header-left' onClick={prev} />
        <button type='button' className='bfdp-popup-header-title' onClick={togglePickerMode}>
          {label}
        </button>
        <ArrowIcon className='bfdp-popup-header-right' onClick={next} />
      </div>
    );
  }
}

import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Translator} from '@lib/Translation';
import {OrderCodeStatusData} from '@lib/Api';
import {DateFormatString, MD_SIZE, parseDate, prettyDate} from '@lib/Utils';
import {Text, TextProps, TextSize, TextWeight} from '@components/UI';

export const statusValueTextProps: TextProps = {
  size: TextSize.BIGGER,
  paragraph: true,
};

export const statusTitleTextProps: TextProps = {
  ...statusValueTextProps,
  weight: TextWeight.LIGHT,
};

interface OrderCodeStatusModalProps {
  data: OrderCodeStatusData;
  translator: Translator;
}

export const OrderCodeStatusModal = observer(
  ({
    translator,
    data: {
      status,
      noticeOfProductWithdrawalDate,
      spareSensorsAvailableUntilDate,
      sparesAvailableUntilDate,
      pcOrderStopDate,
      calibrationAvailableUntilDate,
      repairPossibleUntilDate,
      successor,
      maintenanceAdvices,
    },
  }: OrderCodeStatusModalProps) => {
    return (
      <>
        <StatusRow>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('Phase out date')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <Text {...statusTitleTextProps}>
                {noticeOfProductWithdrawalDate
                  ? parseDate(DateFormatString.X_S, new Date(noticeOfProductWithdrawalDate))
                  : translator.translate('not applicable')}
              </Text>
            </StatusCol>
          </StatusRow>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('Order stop')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <Text {...statusTitleTextProps}>
                {pcOrderStopDate ? parseDate(DateFormatString.X_S, new Date(pcOrderStopDate)) : translator.translate('not applicable')}
              </Text>
            </StatusCol>
          </StatusRow>
        </StatusRow>
        <StatusRow>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('Spare sensor until')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <Text {...statusTitleTextProps}>
                {spareSensorsAvailableUntilDate
                  ? parseDate(DateFormatString.X_S, new Date(spareSensorsAvailableUntilDate))
                  : translator.translate('not applicable')}
              </Text>
            </StatusCol>
          </StatusRow>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('Calibration until')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <Text {...statusTitleTextProps}>
                {calibrationAvailableUntilDate
                  ? parseDate(DateFormatString.X_S, new Date(calibrationAvailableUntilDate))
                  : translator.translate('not applicable')}
              </Text>
            </StatusCol>
          </StatusRow>
        </StatusRow>
        <StatusRow>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('Spare parts until')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <Text {...statusTitleTextProps}>
                {sparesAvailableUntilDate
                  ? parseDate(DateFormatString.X_S, new Date(sparesAvailableUntilDate))
                  : translator.translate('not applicable')}
              </Text>
            </StatusCol>
          </StatusRow>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('Repair until')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <Text {...statusTitleTextProps}>
                {repairPossibleUntilDate
                  ? parseDate(DateFormatString.X_S, new Date(repairPossibleUntilDate))
                  : translator.translate('not applicable')}
              </Text>
            </StatusCol>
          </StatusRow>
        </StatusRow>
        <StatusRow>
          <StatusRow>
            <StatusCol>
              <Text {...statusValueTextProps}>{translator.translate('New alternative')}</Text>
            </StatusCol>
            <StatusCol $type={StatusColType.VALUE}>
              <Text {...statusTitleTextProps}>{successor ? successor.label : '-'}</Text>
            </StatusCol>
          </StatusRow>
          <StatusRow />
        </StatusRow>
        <StatusRow>
          <MaintenanceStatusCol>
            <Text {...statusValueTextProps}>{translator.translate('Maintenance advice')}</Text>
          </MaintenanceStatusCol>
          <MaintenanceStatusCol $type={StatusColType.VALUE}>
            <Text {...statusTitleTextProps}>
              {maintenanceAdvices?.length ? (
                <>
                  {maintenanceAdvices.map((text, index) => (
                    <React.Fragment key={index}>
                      {text}
                      <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                '-'
              )}
            </Text>
          </MaintenanceStatusCol>
        </StatusRow>
      </>
    );
  },
);

export const StatusRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 12px;
  padding: 4px 8px;
  width: 100%;
  @media all and (min-width: ${MD_SIZE}px) {
    flex-wrap: nowrap;
    min-height: 32px;
  }
  div {
    padding: 0;
  }
`;

export enum StatusColType {
  TITLE = 1,
  VALUE,
}

interface StatusColProps {
  $type?: StatusColType;
}

export const StatusCol = styled.div<StatusColProps>`
  display: flex;
  align-items: center;
  grid-gap: 6px;
  padding: 0 15px;
  width: 100%;
  @media all and (min-width: ${MD_SIZE}px) {
    width: ${({$type = StatusColType.TITLE}: StatusColProps) => ($type === StatusColType.TITLE ? 60 : 40)}%;
  }
`;

const MaintenanceStatusCol = styled(StatusCol)`
  && {
    @media all and (min-width: ${MD_SIZE}px) {
      width: ${({$type = StatusColType.TITLE}: StatusColProps) => ($type === StatusColType.TITLE ? 29 : 71)}%;
    }
  }
`;

import * as React from 'react';
import {IconGenerator, IconProps} from './IconGenerator';

export const CloseIcon = (props: IconProps) => (
  <IconGenerator
    viewBox='0 0 30 30'
    body={
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0002 16.202L20.3992 21.601L21.6013 20.3989L16.2023 15L21.6013 9.60101L20.3992 8.39893L15.0002 13.7979L9.60125 8.39893L8.39917 9.60101L13.7981 15L8.39917 20.3989L9.60125 21.601L15.0002 16.202Z'
      />
    }
    {...props}
  />
);
